import { useQuery } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';
import { queryKeys } from 'store/query-keys';

export interface ClientLogoInfo {
	id: string;
	clientId: string;
	active: boolean;
	companyName: string;
	companyOrg: string;
}

export const useGetClientLogos = () => {
	const result = useQuery({
		queryKey: [queryKeys.getActiveClientLogos],
		queryFn: async () => {
			const getClientLogos = (): Promise<ApiResponse<ClientLogoInfo[], ClientLogoInfo[]>> =>
				apiInstance.get<ClientLogoInfo[]>(apiRoutes.clientLogo.getClientLogos(), undefined, headers());
			const result = await getClientLogos();
			return result;
		},
		refetchOnWindowFocus: false,
	});

	return result;
};
