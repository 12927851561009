import React, { useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { useNewClientContext } from "view/subcomponents/providers/new-client-provider";

import { useForm } from "react-hook-form";
import { Form } from "view/subcomponents/form";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { newClientPersonSchema } from "utils/validation/schema/new-client/new-client-person-schema";

const NewClientPersonInfo = () => {
	const { onSubmit, wizardData, page, disablePagesAhead } = useNewClientContext();
	const hasDisabled = useRef(false);
	const methods = useForm({
		defaultValues: {
			page: 3,
			firstName: wizardData?.[page]?.data?.firstName,
			lastName: wizardData?.[page]?.data?.lastName,
			identityNumber: wizardData?.[page]?.data?.identityNumber,
			email: wizardData?.[page]?.data?.email,
			phoneNumber: wizardData?.[page]?.data?.phoneNumber,
		},
		resolver: yupResolver(newClientPersonSchema),
	});

	const { isDirty } = methods.formState;

	useEffect(() => {
		if (isDirty && hasDisabled.current === false) {
			disablePagesAhead();
			hasDisabled.current = true;
		}
	}, [isDirty, disablePagesAhead]);

	return (
		<Form
			methods={methods}
			onSubmit={onSubmit}
			sx={{
				marginBottom: "16px",
				marginTop: "16px",
			}}
			id={"new-client-form"}
		>
			<Grid container columnSpacing={4} rowSpacing={1}>
				<Grid item xs={6}>
					<KycFormInput name={"firstName"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"lastName"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"identityNumber"} inputName={"identityNumber.Person"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"email"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"phoneNumber"} />
				</Grid>
			</Grid>
		</Form>
	);
};

export default NewClientPersonInfo;
