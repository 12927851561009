import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { hasCookie, setCookie } from "utils/cookie";
import { useForm, Controller } from "react-hook-form";
import { Form } from "view/subcomponents/form";
import { yupResolver } from "@hookform/resolvers/yup";
import PasswordProtectSchema from "utils/validation/schema/password-protect-schema";
import PendingButton from "view/subcomponents/buttons/pending-button";
import api from "api/api";

import Tokens from "config/constants/tokens";

const PasswordProtect = ({ children }) => {
	const [pending, setPending] = useState(false);
	const { t } = useTranslation();

	const methods = useForm({
		resolver: yupResolver(PasswordProtectSchema),
		defaultValues: {
			password: "",
			username: "",
		},
	});

	if (hasCookie(Tokens.PasswordProtect) || window.config.site.password_protect === "false") {
		return children;
	}

	const onSubmit = async (values) => {
		setPending(true);
		await api.register(values).then((res) => {
			if (res.ok) {
				setCookie(Tokens.PasswordProtect, "13333333333338", 60 * 8);
				window.location.reload();
			} else {
				setPending(false);
			}
		});
	};

	return (
		<Box
			sx={{
				backgroundColor: "#ececec",
				width: "100%",
				height: "100%",
				color: "#000",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					width: "350px",
					backgroundColor: "#fff",
					borderRadius: "8px",
					textAlign: "center",
				}}
			>
				<Box
					sx={{
						fontWeight: "bold",
						fontSize: "24px",
						paddingBottom: "16px",
						paddingTop: "8px",
					}}
				>
					{t("Login")}
				</Box>
				<Form
					methods={methods}
					onSubmit={onSubmit}
					sx={{
						padding: "24px",
					}}
				>
					<Box mb={2}>
						<Controller
							name="username"
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<TextField
									onBlur={onBlur} // notify when input is touched
									onChange={onChange} // send value to hook form
									value={value}
									inputRef={ref}
									placeholder={t("Placeholders.Username")}
								/>
							)}
						/>
					</Box>
					<Box mb={2}>
						<Controller
							name="password"
							render={({ field: { onChange, onBlur, value, ref } }) => (
								<TextField
									onBlur={onBlur} // notify when input is touched
									onChange={onChange} // send value to hook form
									value={value}
									inputRef={ref}
									placeholder={t("Placeholders.Password")}
									type={"password"}
									autoComplete={"password"}
								/>
							)}
						/>
					</Box>
					<Box mb={2}>
						<PendingButton variant={"purple"} type={"submit"} pending={pending}>
							{t("Login")}
						</PendingButton>
					</Box>
				</Form>
			</Box>
		</Box>
	);
};

export default PasswordProtect;
