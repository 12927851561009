import { Box, Divider } from "@mui/material";
import React from "react";
import { Children } from "types/children";

interface Props {
	title?: string;
	sx?: any;
	children: Children;
	[x: string]: any;
}

const InfoMessagePanel = ({ title, sx, children, ...rest }: Props) => {
	return (
		<Box
			sx={{
				borderRadius: "16px",
				backgroundColor: "rgba(136, 84, 208, 0.2);",

				padding: "16px 32px 16px 32px",
				fontSize: "16px",

				color: "primary.main",

				...sx,
			}}
			{...rest}
		>
			{title && (
				<>
					<Box
						sx={{
							fontWeight: "bold",
							fontSize: "1.25rem",
						}}
					>
						{title}
					</Box>
					<Divider
						sx={{
							borderColor: "primary.main",
							marginTop: "8px",
							marginBottom: "8px",
						}}
					/>
				</>
			)}
			{children}
		</Box>
	);
};

export default InfoMessagePanel;
