import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import RightContainer from 'view/subcomponents/containers/right-container/right-container';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import ClientCard from 'view/components/clients/subcomponents/client-card/client-card';
import NewClient from 'view/components/clients/subcomponents/new-client/new-client';
import { NewClientProvider } from 'view/subcomponents/providers/new-client-provider';
import ClientAddonDialog from 'view/components/clients/subcomponents/client-addons/client-addon-dialog';
import ClientUsersDialog from 'view/components/clients/subcomponents/client-users/client-users-dialog';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import api from 'api/api';
import { SnackbarTypes } from 'types/snackbar';
import { ClientDialogGetMonthlyCSM } from './subcomponents/client-dialogs/client-dialog-get-monthly-csm';
import { ApiResponse } from 'apisauce';

const defaultTransactionData = {
	fileContent: [],
	fileName: '',
};

const ClientsRightContainer = () => {
	const { enqueue } = useSnackbar();
	const csvRef = useRef(null);
	const { data } = useQueryContext();
	const [page, setPage] = useState<number>(1);
	const [transactionData, setTransactionData] = useState(defaultTransactionData);
	const [openModal, setOpenModal] = useState<boolean>(false);

	const onGetCSVClick = async () => {
		await api.createClientCSVInfo({ clients: data?.map(data => data?.clientId) }).then((res: ApiResponse<any>) => {
			if (res.ok) {
				setTransactionData(res.data);
				csvRef.current.link.click();
			} else {
				enqueue(SnackbarTypes.Error, 'CSV.Error');

				setTransactionData(defaultTransactionData);
			}
		});
	};

	const toggleOpenModal = () => {
		setOpenModal(!openModal);
	};

	return (
		<RightContainer
			onClick={() => setPage(page === 2 ? 1 : 2)}
			onSecondClick={data ? onGetCSVClick : () => {}}
			onThirdClick={toggleOpenModal}
			buttonProps={{
				buttonText: page === 1 ? 'Skapa ny kund' : 'Tillbaka',
			}}
			secondButtonText="Hämta CSM"
			thirdButtonText="Hämta månads-basis CSM"
			disableScrollButtons={page === 2}
			displayTopInfo={true}
		>
			{page === 1 ? (
				data?.map((client, index) => <ClientCard key={index} client={client} />)
			) : (
				<NewClientProvider lastPage={5}>
					<NewClient setPage={setPage} />
				</NewClientProvider>
			)}
			<CSVLink
				data={transactionData?.fileContent ?? []}
				filename={transactionData?.fileName ?? ''}
				className="hidden"
				ref={csvRef}
				target="_blank"
			/>
			<ClientAddonDialog />
			<ClientUsersDialog />
			<ClientDialogGetMonthlyCSM open={openModal} onClose={() => setOpenModal(false)} />
		</RightContainer>
	);
};

export default ClientsRightContainer;
