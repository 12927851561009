import React from "react";
import InfoMessagePanel from "view/subcomponents/common/message-panel/info-message-panel";
import ErrorMessagePanel from "view/subcomponents/common/message-panel/error-message-panel";
import { Children } from "types/children";

interface Props {
	type?: string;
	sx?: any;
	children: Children;
	[x: string]: any;
}

export const MessagePanel = ({ type, sx, children, ...rest }: Props) => {
	switch (type) {
		case "info":
			return (
				<InfoMessagePanel sx={sx} {...rest}>
					{children}
				</InfoMessagePanel>
			);
		default:
			return (
				<ErrorMessagePanel sx={sx} {...rest}>
					{children}
				</ErrorMessagePanel>
			);
	}
};
