import React, { useState } from "react";
import { Box, Button, Collapse, Grid } from "@mui/material";
import { ShowMoreButton } from "view/subcomponents/buttons/show-more-button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Form } from "view/subcomponents/form";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { createNewFranchiseSchema } from "utils/validation/schema/create-new-franchise-schema";
import api from "api/api";
import PendingButton from "view/subcomponents/buttons/pending-button";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import useQuery from "view/subcomponents/hooks/use-query";
import { SnackbarTypes } from "types/snackbar";

let defaultValues = {
	name: "",
};

const FranchisesNewFranchise = ({ tab, updateTab }) => {
	const [open, setOpen] = useState(true);
	const { addItem } = useQueryContext();
	const { enqueue } = useSnackbar();
	const [pending, setPending] = useState(false);
	const { t } = useTranslation();
	const { data } = useQuery(api.getDashboardCount);

	const methods = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(createNewFranchiseSchema),
	});

	const onSubmit = async (values) => {
		setPending(true);
		updateTab(1);
		await api.createFranchise(values).then((res) => {
			if (res.ok) {
				addItem(res.data);
				enqueue(SnackbarTypes.Success, "Franchise.Create.Success");
				methods.reset(defaultValues);
			} else {
				const status = res?.data ?? "Error";
				enqueue(SnackbarTypes.Error, `Franchise.Create.${status}`);
			}
			setPending(false);
		});
	};

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				borderRadius: "24px",
				width: "100%",
				position: "relative",
				padding: "32px",
				boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
				marginBottom: "24px",
			}}
		>
			<Collapse in={open}>
				<Form onSubmit={onSubmit} methods={methods}>
					<Box
						sx={{
							color: "primary.main",
							fontWeight: "bold",
							fontSize: "20px",
							paddingBottom: "24px",
						}}
					>
						{t("AddFranchise")}
					</Box>
					<Grid container>
						<Grid item xs={6}>
							<KycFormInput name={"name"} inputName={"franchise.name"} />
						</Grid>
					</Grid>
					<Box display={"flex"} alignItems={"center"} mt={2}>
						<Button variant={"purple-outlined"} onClick={updateTab}>
							{tab === "2" ? (
								"Visa varumärken"
							) : (
								<>
									Visa varumärkesärenden
									<Box
										sx={{
											marginLeft: "16px",
											backgroundColor: "primary.main",
											color: "#fff",
											fontWeight: "bold",
											borderRadius: "12px",
											width: "30px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											height: "30px",
										}}
									>
										{data?.franchiseCount ?? 0}
									</Box>
								</>
							)}
						</Button>

						<Box
							sx={{
								flex: "1 1 0",
								display: "flex",
								paddingTop: "16px",
								justifyContent: "flex-end",
							}}
						>
							<PendingButton pending={pending} variant={"purple"} type={"submit"}>
								{t("Add")}
							</PendingButton>
						</Box>
					</Box>
				</Form>
			</Collapse>
			<ShowMoreButton
				open={open}
				setOpen={setOpen}
				sx={{
					position: "absolute",
					color: "primary.main",
					left: "calc(50% - 25px)",
					bottom: "-5%",
				}}
			/>
		</Box>
	);
};

export default FranchisesNewFranchise;
