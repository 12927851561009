import React, { createContext, useCallback, useState } from "react";

const Context = createContext(null);

export const useNewClientContext = () => React.useContext(Context);

interface IWizardData {
	[x: number]: {
		data: {
			[x: string]: any;
		};
		isValid: boolean;
		name: string;
	};
}

export const NewClientProvider = ({ children, lastPage }) => {
	const [disabled, setDisabled] = useState(false);
	const [page, setPage] = useState(1);
	const [minPage, setMinPage] = useState(1);
	const [wizardData, setWizardData] = useState<IWizardData>({
		1: {
			data: {},
			isValid: false,
			name: "company",
		},
		2: {
			data: {},
			isValid: false,
			name: "invoice",
		},
		3: {
			data: {},
			isValid: false,
			name: "person",
		},
		4: {
			data: {},
			isValid: false,
			name: "other",
		},
	});

	const getWizardData = useCallback(() => {
		let values = {};
		Object.keys(wizardData).forEach((key) => {
			let { data, name } = wizardData?.[key];
			values = {
				...values,
				[name]: data,
			};
		});
		return values;
	}, [wizardData]);

	const disablePagesAhead = useCallback(() => {
		setMinPage(page);
		setWizardData({
			...wizardData,
			[page]: {
				...wizardData[page],
				isValid: false,
			},
		});
	}, [setWizardData, wizardData, page, setMinPage]);

	const onSubmit = async (values) => {
		const { page, ...rest } = values;

		setWizardData({
			...wizardData,
			[page]: {
				...wizardData?.[page],
				data: rest,
				isValid: true,
			},
		});
		setPage(page + 1);
		setMinPage(page + 1);
	};

	const hasValidData = useCallback(() => {
		return !Object.values(wizardData).some((x) => x.isValid === false);
	}, [wizardData]);

	const moveBack = useCallback(() => {
		setPage(page - 1);
	}, [page]);

	return (
		<Context.Provider
			value={{
				getWizardData,
				disabled,
				setDisabled,
				page,
				setPage,
				wizardData,
				onSubmit,
				disablePagesAhead,
				minPage,
				isLastPage: page === lastPage,
				lastPage,
				hasValidData,
				moveBack,
			}}
		>
			{children}
		</Context.Provider>
	);
};
