import { useState } from 'react';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';

import { ClientCard } from 'types/clients';

import { Box, Button } from '@mui/material';

import { InternalPendingButton } from 'view/subcomponents/buttons/internal-pending-button';
import { useClients } from '../../../../subcomponents/providers/clients-provider';
import AddUserDialog from './add-user-dialog';

interface Props {
	onActivate: any;
	setDatePickerModalOpen: any;
	clientCard: ClientCard;
}

const ClientCardButtons = ({ onActivate, setDatePickerModalOpen, clientCard }: Props) => {
	const { data, updateData } = useQueryContext();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { setClientData, setUserData } = useClients();
	const { active, activeUntil, id } = clientCard;

	const onStatusToggleClick = () => {
		active ? setDatePickerModalOpen(true) : onActivate();
	};

	const setData = data => {
		updateData(data);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				gap: '8px',
				button: {
					minWidth: '160px',
				},
			}}
		>
			<InternalPendingButton onClick={onStatusToggleClick} variant={!active ? 'purple' : 'purple-outlined'}>
				{active ? 'Avaktivera' : 'Aktivera'}
			</InternalPendingButton>
			{activeUntil && active && (
				<InternalPendingButton onClick={onActivate} variant={'purple'}>
					Ta bort slutdatum
				</InternalPendingButton>
			)}

			<Button variant={'purple'} onClick={() => setClientData({ ...data, setData: setData })} disabled={!active}>
				Hantera tillägg
			</Button>
			<Button variant={'purple'} onClick={() => setUserData({ ...data, setData: setData })} disabled={!active}>
				Hantera användare
			</Button>
			<Button variant={'purple'} onClick={() => setOpenModal(true)} disabled={!active}>
				Lägg till användare
			</Button>

			<AddUserDialog id={id} open={openModal} onClose={() => setOpenModal(false)} />
		</Box>
	);
};

export default ClientCardButtons;
