import { useEffect, useState } from "react";
import { Box, Button, TextField, Grid, Divider } from "@mui/material";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import PageTitle from "view/subcomponents/common/page-title";
import api from "api/api";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { SnackbarTypes } from "types/snackbar";

interface RedirectRightContainerProps {
    routeId?: number | string;
}

const RedirectRightContainer = ({ routeId }: RedirectRightContainerProps) => {
    const { data } = useQueryContext();
    const { enqueue } = useSnackbar();
    const [routeName, setRouteName] = useState("");
    const [route, setRoute] = useState("");

    const updateRoute = async () => {
        await api.updateCustomResource({ id: routeId, newValue: route }).then((res) => {
            if (res.ok) {
                enqueue(SnackbarTypes.Success, "Routen har uppdaterats");
            } else {
                enqueue(SnackbarTypes.Error, "Routen kunde inte uppdateras");
            }
        });
    };

    useEffect(() => {
        if (data && routeId) {
            setRoute(data.find((d) => d.id === routeId).value);
            setRouteName(data.find((d) => d.id === routeId).name);
        }
    }, [routeId, data]);

    return (
        <RightContainer displayTopInfo={true}>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    padding: "32px",
                    borderRadius: "30px",
                }}
            >
                {data && (
                    <>
                        <PageTitle>Uppdatera "{routeName}" Route</PageTitle>
                        <TextField style={{ width: "100%" }} value={route} onChange={(e) => setRoute(e.target.value)} />
                        <Grid item xs={12} container justifyContent={"flex-end"}>
                            <Divider
                                sx={{
                                    width: "100%",
                                    marginTop: "24px",
                                    marginBottom: "24px",
                                }}
                            />
                            <Button variant={"purple"} type={"submit"} onClick={updateRoute}>
                                Spara
                            </Button>
                        </Grid>
                    </>
                )}
            </Box>
        </RightContainer>
    );
};
export default RedirectRightContainer;
