export const queryKeys = {
	addons: 'addons',
	customerInformation: 'customerInformation',
	userInfo: 'userInfo',
	backendRedirect: 'backendRedirect',
	systemStatus: 'systemStatus',
	servicePanelTypes: 'servicePanelTypes',
	sendCustomerInformation: 'sendCustomerInformation',
	getCustomerInformation: 'getCustomerInformation',
	getCustomerInformationForPdf: 'getCustomerInformationForPdf',
	submitCustomerInformation: 'submitCustomerInformation',
	getKycParticipant: 'getKycParticipant',
	getActiveClientLogo: 'getActiveClientLogo',
	getActiveClientLogos: 'getActiveClientLogos',
	getNewClientLogoErrands: 'getNewClientLogoErrands',
	getKycParticipantLog: 'getKycParticipantLog',
	getUpdatePaymentModel: 'getUpdatePaymentModel',
	getAgreement: 'getAgreement',
	getClientStatisticsById: 'getClientStatisticsById', 
	mail: 'mail'
};