import React from "react";
import { Grow } from "@mui/material";
import { SnackbarWrapper } from "view/subcomponents/snackbar/snackbar-wrapper";
import SnackbarItem from "view/subcomponents/snackbar/snackbar-item";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";

const CustomSnackbar = () => {
	const { notifications } = useSnackbar();

	return (
		<SnackbarWrapper>
			{Object.values(notifications).map((notification) => {
				return (
					<Grow key={notification.key} timeout={500}>
						<SnackbarItem notification={notification} />
					</Grow>
				);
			})}
		</SnackbarWrapper>
	);
};

export default CustomSnackbar;
