import { Box, Button, Fade } from "@mui/material";
import { Children } from "types/children";

interface Props {
	children: Children;
	sx?: any;
	count?: number;
	[x: string]: any;
}

export const LeftMenuButton = ({ children, sx, count, ...rest }: Props) => {
	return (
		<Button
			variant={"purple-outlined"}
			sx={{
				borderRadius: "32px",
				fontSize: "18px",
				...sx,
			}}
			{...rest}
		>
				<Fade in={count > 0} unmountOnExit timeout={500}>
					<Box
						sx={{
							position: "absolute",
							right: "-5%",
							backgroundColor: "#a870f5",
							width: "30px",
							height: "30px",
							borderRadius: "50%",
							color: "#fff",
							border: "1px solid",
							borderColor: "primary.main",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						{count}
					</Box>
				</Fade>			
			{children}
		</Button>
	);
};
