import * as yup from "yup";

export const setFranchiseSchema = yup.object({
	franchise: yup.lazy((a, b) => {
		if (b?.parent?.type === "REJECT") {
			return yup.object().notRequired().nullable();
		}
		return yup
			.object({
				label: yup.string().required(),
				value: yup.string().required(),
			})
			.required();
	}),
});
/*

 */
