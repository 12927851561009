import React from "react";
import { Box } from "@mui/material";
import { Children } from "types/children";

interface Props {
	sx?: any;
	children: Children;
}

export const ScrollableContent = ({ sx, children }: Props) => {
	return (
		<Box
			sx={{
				height: "100%",
				width: "calc(100% + 8px)",
				flex: "1 1 0",
				overflowY: "auto",
				overflowX: "hidden",
				paddingRight: "4px",
				"::-webkit-scrollbar": {
					paddingLeft: "16px",
					width: "8px",
					background: "rgba(136, 84, 208, 0.2); !important",
					borderRadius: "16px",
				},

				"::-webkit-scrollbar-thumb": {
					background: "#8854d0",
					borderRadius: "8px",
					height: "12px",
				},
				...sx,
			}}
		>
			{children}
		</Box>
	);
};
