import React from 'react';
import { Divider, Grid } from '@mui/material';
import PendingButton from 'view/subcomponents/buttons/pending-button';

const AgreementCardFooter = ({ pending, disabled = false }) => {
	return (
		<>
			<Grid item xs={12}>
				<Divider
					sx={{
						marginTop: '16px',
						marginBottom: '16px',
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				container
				justifyContent={'flex-end'}
				alignItems={'flex-end'}
			>
				<PendingButton
					variant={'purple'}
					type={'submit'}
					pending={pending}
					disabled={disabled}
				>
					Spara
				</PendingButton>
			</Grid>
		</>
	);
};

export default AgreementCardFooter;
