import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetClientMailLog } from 'store/mail/use-get-client-mail-log';
import { TextContainer } from 'view/subcomponents/common/text-container';
import { ScrollableContent } from 'view/subcomponents/scroll/scrollable-content';
import { ScrollableWrapper } from 'view/subcomponents/scroll/scrollable-wrapper';
import { ClientMailCard } from './client-mail-card';

interface ClientMailLogProps {
	clientId: string;
}
export const ClientMailLog = ({ clientId }: ClientMailLogProps) => {
	const { t } = useTranslation();
	const getClientMailLog = useGetClientMailLog(clientId);
	const clientMailLog = getClientMailLog.data;

	if (!clientMailLog || clientMailLog.length === 0) {
		return (
			<TextContainer fontSize={18} fontWeight={'bold'}>
				{t('NoMailToShow')}
			</TextContainer>
		);
	}

	return (
		<Grid container>
			<Grid item xs={4}>
				<Typography fontWeight="bold">{t('Placeholder.email')}</Typography>
			</Grid>
			<Grid item xs={4}>
				<Typography fontWeight="bold">{t('Placeholder.subject')}</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography fontWeight="bold">{t('Placeholder.status')}</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography fontWeight="bold">{t('Placeholder.date')}</Typography>
			</Grid>
			<Grid item xs={12} height="200px">
				<ScrollableWrapper>
					<ScrollableContent>
						{clientMailLog.map((mail, key) => (
							<ClientMailCard key={key} mail={mail} />
						))}
					</ScrollableContent>
				</ScrollableWrapper>
			</Grid>
		</Grid>
	);
};
