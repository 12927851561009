import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftMenuInputBase } from "view/subcomponents/containers/left-container/menu-items/left-menu-input-base";
import { SectionFieldKey, useSectionContext } from "view/subcomponents/providers/section-provider";
import { useDebounce } from "view/subcomponents/hooks/use-debounce";

interface Props {
	field: SectionFieldKey;
}

export const LeftMenuInput = ({ field }: Props) => {
	const [value, setValue] = useState("");
	const { t } = useTranslation();
	const { updateFilter, filter } = useSectionContext();
	const mountedRef = useRef(false);

	const onChange = (e) => {
		const newValue = e.target.value;
		setValue(newValue);
	};

	useDebounce(
		() => {
			updateFilter(field, value);
		},
		[field, value],
		200
	);

	useEffect(() => {
		if (filter[field] !== value && mountedRef.current === false) {
			setValue(filter[field]);
		}
		mountedRef.current = true;
	}, [filter, value, field, setValue]);

	return <LeftMenuInputBase value={value} onChange={onChange} placeholder={t(`Placeholder.${field}`)} />;
};
