import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Form } from "view/subcomponents/form";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import { KycAutocomplete } from "view/subcomponents/input/autocomplete/kyc-autocomplete";
import { Divider, Grid } from "@mui/material";
import { BusinessAreas } from "config/constants/business-areas";
import { useTranslation } from "react-i18next";
import { useNewClientContext } from "view/subcomponents/providers/new-client-provider";
import { newClientCompanySchema } from "utils/validation/schema/new-client/new-client-company-schema";
import { yupResolver } from "@hookform/resolvers/yup";

const NewClientCompanyInfo = () => {
	const { onSubmit, wizardData, page, disablePagesAhead } = useNewClientContext();
	const hasDisabled = useRef(false);
	const { t } = useTranslation();
	const methods = useForm({
		defaultValues: {
			page: 1,
			name: wizardData?.[page]?.data?.name,
			identityNumber: wizardData?.[page]?.data?.identityNumber,
			businessArea: wizardData?.[page]?.data?.businessArea,
		},
		resolver: yupResolver(newClientCompanySchema),
		mode: "all",
		shouldFocusError: true,
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	const { isDirty } = methods.formState;

	useEffect(() => {
		if (isDirty && hasDisabled.current === false) {
			disablePagesAhead();
			hasDisabled.current = true;
		}
	}, [isDirty, disablePagesAhead]);

	return (
		<Form
			methods={methods}
			onSubmit={onSubmit}
			sx={{
				marginBottom: "16px",
				marginTop: "16px",
			}}
			id={"new-client-form"}
		>
			<Grid container columnSpacing={4} rowSpacing={1}>
				<Grid item xs={6}>
					<KycFormInput name={"name"} inputName={"companyName"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"identityNumber"} inputName={"identityNumber.Company"} />
				</Grid>
				<Grid item xs={6}>
					<KycAutocomplete
						name={"businessArea"}
						options={Object.values(BusinessAreas).map((area) => ({
							value: area,
							label: t(`businessAreas.${area}`),
						}))}
						placeholder={"Verksamhetsområde"}
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
		</Form>
	);
};

export default NewClientCompanyInfo;
