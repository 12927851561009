import { Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const LinkButton = ({ to, children, ...rest }) => {
	return (
		<Link to={to} component={RouterLink} sx={{ textDecoration: "none" }}>
			<Button {...rest}>{children}</Button>
		</Link>
	);
};
