import React from "react";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import AgreementsLeftContainer from "view/components/agreements/agreements-left-container";
import AgreementsRightContainer from "view/components/agreements/agreements-right-container";
import api from "api/api";

const Agreements = () => {
	return (
		<SectionWrapper method={api.getAgreements}>
			<AgreementsLeftContainer />
			<AgreementsRightContainer />
		</SectionWrapper>
	);
};

export default Agreements;
