import { isAuthenticated } from "utils/user";
import { Redirect } from "react-router-dom";
import { RouterPaths } from "config/constants";
import SessionRoute from "view/subcomponents/router/session-route";

const ProtectedRoute = ({ ...rest }) => {
	if (isAuthenticated()) {
		return <SessionRoute {...rest} />;
	} else {
		return <Redirect to={RouterPaths.Home} />;
	}
};

export default ProtectedRoute;
