import { Box } from '@mui/material';
import { useMemo, useRef } from 'react';
import { AddonType } from 'types/common';
import ProfileClientAddonsMenu from 'view/components/clients/subcomponents/client-addons/profile-client-addons-menu';
import { isEqual } from 'lodash';
import api from 'api/api';
import { ApiResponse } from 'apisauce';
import { SnackbarTypes } from 'types/snackbar';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import useApiMethod from 'view/subcomponents/hooks/use-api-method';
import { useClients } from '../../../../subcomponents/providers/clients-provider';

export type ProfileTypes = 'Available' | 'Active';

const ProfileClientAddons = () => {
	const { clientData, setClientData } = useClients();
	const { addons, clientAddons, id, setData } = clientData;
	const { enqueue } = useSnackbar();
	const { pending, method } = useApiMethod({
		method: api.setClientAddonsFromAdmin,
	});
	const initialClientAddons = useRef(clientAddons);

	const availableAddons = useMemo(() => {
		let foundAddons = [];

		addons.forEach(addon => {
			const exists = clientAddons.some(x => x.type === addon.type && x.active);
			if (!exists) {
				foundAddons.push(addon);
			}
		});
		return foundAddons;
	}, [addons, clientAddons]);

	const onClick = (
		profileType: ProfileTypes,
		type: AddonType,
		index: number,
	) => {
		if (profileType === 'Active') {
			const addon = clientAddons.find(x => x.type === type);

			const newAddons = clientAddons.filter(x => x.type !== type);

			const isInInitial = initialClientAddons.current.some(
				x => x.type === type,
			);

			if (isInInitial) {
				setClientData({
					...clientData,
					clientAddons: [
						...newAddons,
						{
							...addon,
							active: false,
							existsInInitial: isInInitial,
						},
					],
				});
			} else {
				setClientData({
					...clientData,
					clientAddons: [...newAddons],
				});
			}
		}

		if (profileType === 'Available') {
			const clientAddon = clientAddons.find(x => x.type === type);
			const addon = addons.find(x => x.type === type);

			const newAddons = clientAddons.filter(x => x.type !== type);

			setClientData({
				...clientData,
				clientAddons: [
					...newAddons,
					{
						...clientAddon,
						...addon,
						active: true,
					},
				],
			});
		}
	};

	const onSubmit = async () => {
		await method({ addons: clientAddons, clientId: id }).then(
			(res: ApiResponse<any[]>) => {
				if (res.ok) {
					enqueue(SnackbarTypes.Success, 'ClientProfile.Update.Success');

					initialClientAddons.current = res.data;

					setData({
						...clientData,
						clientAddons: res.data,
					});
				} else {
					enqueue(SnackbarTypes.Error, 'ClientProfile.Update.Error');
				}
			},
		);
	};

	const isDisabled = () => {
		if (clientAddons?.length === 0) {
			return true;
		}

		const clientAddonTypes = clientAddons?.map(x => ({
			type: x.type,
			active: Boolean(x.active),
		}));

		const initialClientAddonTypes = initialClientAddons.current?.map(x => ({
			type: x.type,
			active: Boolean(x.active),
		}));

		return isEqual(clientAddonTypes, initialClientAddonTypes);
	};

	return (
		<Box
			sx={{
				paddingTop: '16px',
				marginBottom: '16px',
				borderTop: '1px solid',
				borderColor: 'divider',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					gap: '12px',
				}}
			>
				<ProfileClientAddonsMenu
					profileType={'Available'}
					addons={availableAddons}
					onClick={onClick}
					disabled={pending}
				/>
				<ProfileClientAddonsMenu
					profileType={'Active'}
					addons={clientAddons}
					onClick={onClick}
					disabled={pending}
				/>
			</Box>
			<Box
				sx={{
					width: '100%',
					justifyContent: 'flex-end',
					display: 'flex',
					marginTop: '16px',
					marginBottom: '16px',
					paddingTop: '16px',
					borderTop: '1px solid',
					borderColor: 'divider',
				}}
			>
				<PendingButton
					variant={'purple'}
					onClick={onSubmit}
					disabled={isDisabled()}
					pending={pending}
				>
					Spara
				</PendingButton>
			</Box>
		</Box>
	);
};
export default ProfileClientAddons;
