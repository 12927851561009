import React from 'react';

import { Box, Grid } from '@mui/material';
import RightContainerCardHeader from '../../../../subcomponents/card/right-container-card/right-container-card-header';
import RightContainerCardItem from '../../../../subcomponents/card/right-container-card/right-container-card-item';
import { ClientCard } from 'types/clients';
import { ClientStatus } from '../../../../../utils/client';
import { useTranslation } from 'react-i18next';

interface Props {
	clientCard: ClientCard;
	clientStatus: ClientStatus;
}

const ClientCardInfo = ({ clientCard, clientStatus }: Props) => {
	const { t } = useTranslation();

	const {
		identityNumber,
		name,
		franchiseName,
		userCount,
		activeUsers,
		inactiveUsers,
		activeUsersLastMonth,
		startedKyces,
		completedKyces,
		businessArea,
	} = clientCard;

	return (
		<Grid container item xs={12}>
			<Grid item xs={6}>
				<RightContainerCardHeader>Företagsinformation</RightContainerCardHeader>
				<RightContainerCardItem title={'Namn'}>{name}</RightContainerCardItem>
				<RightContainerCardItem title={'Varumärke'}>{franchiseName}</RightContainerCardItem>
				<RightContainerCardItem title={'Organisationsnummer'}>{identityNumber}</RightContainerCardItem>
				<RightContainerCardItem title={'Status'}>
					<Box
						sx={{
							fontWeight: 'bold',
							color: clientStatus.status ? 'success.main' : 'error.main',
						}}
						component={'span'}
					>
						{clientStatus.title}
					</Box>
				</RightContainerCardItem>
			</Grid>
			<Grid item xs={6}>
				<RightContainerCardHeader>Systeminformation</RightContainerCardHeader>
				<RightContainerCardItem title={'Användare'}>{userCount}</RightContainerCardItem>
				<RightContainerCardItem title={'Aktiva användare'}>{activeUsers}</RightContainerCardItem>
				<RightContainerCardItem title={'Borttagna/inaktiva användare'}>{inactiveUsers}</RightContainerCardItem>
				<RightContainerCardItem title={'Föregående månads aktiva användare*'}>
					{activeUsersLastMonth}
				</RightContainerCardItem>
				<RightContainerCardItem title={'Verksamhetsområde'}>
					{t(`businessAreas.${businessArea}`)}
				</RightContainerCardItem>
				<RightContainerCardItem title={'Avslutade KYC'}>{completedKyces}</RightContainerCardItem>
				<RightContainerCardItem title={'Påbörjade KYC'}>{startedKyces}</RightContainerCardItem>
			</Grid>
			;
		</Grid>
	);
};

export default ClientCardInfo;
