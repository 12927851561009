import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatOrgNumber } from 'utils/string';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import RightContainerCardHeader from 'view/subcomponents/card/right-container-card/right-container-card-header';
import RightContainerCardItem from 'view/subcomponents/card/right-container-card/right-container-card-item';
import TabPanel from 'view/subcomponents/tab-panels/tab-panel';
import { ErrandQueueStatuses } from 'config/constants/errand';
import { useForm } from 'react-hook-form';
import { Form } from 'view/subcomponents/form';
import { KycFormSwitch } from 'view/subcomponents/input/switch/kyc-form-switch';
import { CircularProgressWithText } from 'view/subcomponents/common/circular-progress-with-text';
import { ErrandCardFranchise } from 'view/components/errands/subcomponents/errand-card-franchise';

import PendingButton from 'view/subcomponents/buttons/pending-button';

interface ContentType {
	other: {
		estateAgentMSF: boolean;
		emailMarketing: boolean;
		implementationHelp: boolean;
		webinar: boolean;
	};
	person: {
		fullName: string;
		identityNumber: string;
		email: string;
		phoneNumber: string;
	};
	company: {
		name: string;
		identityNumber: string;
		franchise: string;
		businessArea: string;
		addons: string[];
	};
	invoice: {
		address: string;
		paymentMethod: string;
		paymentPeriod: string;
	};
	signInfo: {
		boardMember: {
			label: string;
			value: string;
		};
		boardMemberEmail: string;
		hasBeenSigned: boolean;
	};
	creditInfo: {
		score: string;
	};
}

const ErrandCardPanelOne = ({ onClick }) => {
	const { data, pending } = useQueryContext();
	const { t } = useTranslation();
	const [submitPending, setSubmitPending] = useState('');
	const mountedRef = useRef(true);

	useEffect(() => {
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const methods = useForm({
		defaultValues: {
			sendAgreementEmail: true,
		},
	});

	if (pending || data === null) {
		return (
			<Box p={4}>
				<CircularProgressWithText text={'Hämtar ärende...'} />
			</Box>
		);
	}

	const onSubmit = async (...data) => {
		setSubmitPending(data[0]);
		await onClick(...data);
		if (mountedRef.current) {
			setSubmitPending('');
		}
	};

	const { queueStatus, content } = data;
	const { other, person, company, invoice, signInfo, creditInfo } = content as ContentType;

	return (
		<TabPanel value={'1'}>
			<Grid container item xs={12}>
				<Grid item xs={6}>
					<RightContainerCardHeader>Information om ansökande</RightContainerCardHeader>
					<RightContainerCardItem title={'Namn'}>{person.fullName}</RightContainerCardItem>
					<RightContainerCardItem title={'Personnummer'}>{person.identityNumber}</RightContainerCardItem>
					<RightContainerCardItem title={'E-post'}>{person.email}</RightContainerCardItem>
					<RightContainerCardItem title={'Telefonnummer'}>{person.phoneNumber}</RightContainerCardItem>
				</Grid>
				<Grid item xs={6}>
					<RightContainerCardHeader>Företagsinformation</RightContainerCardHeader>
					<RightContainerCardItem title={'Namn'}>{company.name}</RightContainerCardItem>
					<RightContainerCardItem title={'Organisationsnummer'}>
						{formatOrgNumber(company.identityNumber)}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Önskat varumärke'}>{company?.franchise ?? '-'}</RightContainerCardItem>
					<RightContainerCardItem title={'Fakturaadress'}>{invoice?.address ?? '-'}</RightContainerCardItem>
					<RightContainerCardItem title={'Kreditscore'}>{creditInfo?.score ?? '-'}</RightContainerCardItem>
					<Divider
						sx={{
							marginTop: '8px',
							marginBottom: '8px',
						}}
					/>
					<RightContainerCardItem title={'Firmatecknare namn'}>
						{signInfo?.boardMember?.label ?? '-'}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Firmatecknare personnummer'}>
						{signInfo?.boardMember?.value ?? '-'}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Firmatecknare e-post'}>{signInfo?.boardMemberEmail}</RightContainerCardItem>
					<RightContainerCardItem title={'Signerad av firmatecknare'}>
						{signInfo?.hasBeenSigned ? 'Ja' : 'Nej'}
					</RightContainerCardItem>
				</Grid>
				<Grid item xs={12}>
					<RightContainerCardHeader>Orderinformation</RightContainerCardHeader>
					{company?.businessArea === 'EstateAgent' && (
						<RightContainerCardItem title={'Ansluta till MSF?'}>
							{other?.estateAgentMSF ? 'Ja' : 'Nej'}
						</RightContainerCardItem>
					)}

					<RightContainerCardItem title={'E-post för marknardsförning'}>
						{other?.emailMarketing ? 'Ja' : 'Nej'}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Betalningsmetod'}>
						{t(`paymentMethods.${invoice?.paymentMethod}`)}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Betalningsperiod'}>
						{t(`paymentPeriod.${invoice?.paymentPeriod}`)}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Implementationshjälp'}>
						{other?.implementationHelp ? 'Ja' : 'Nej'}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Webbutbildning'}>{other?.webinar ? 'Ja' : 'Nej'}</RightContainerCardItem>
					<RightContainerCardItem title={'Verksamhetsområde'}>
						{t(`businessAreas.${company?.businessArea}`)}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Tillägg'}>
						{company?.addons?.length > 0 ? (
							<Box>
								{company.addons.map(addon => (
									<Box key={addon}>{t(`AddonTypes.${addon}`)}</Box>
								))}
							</Box>
						) : (
							<Box>Inga valda tillägg</Box>
						)}
					</RightContainerCardItem>
				</Grid>
				{queueStatus === ErrandQueueStatuses.New && (
					<Grid item xs={12}>
						<Form methods={methods}>
							<Divider
								sx={{
									marginTop: '16px',
									marginBottom: '16px',
								}}
							/>

							<Box display={'flex'} flexDirection={'column'}>
								<ErrandCardFranchise
									sx={{
										marginBottom: '16px',
									}}
								/>

								<KycFormSwitch name={'sendAgreementEmail'} label={'Bifoga avtal i e-post?'} rightSide={true} />
							</Box>
							<Divider
								sx={{
									marginTop: '16px',
									marginBottom: '16px',
								}}
							/>
							<Box
								sx={{
									display: 'flex',
									width: '100%',

									justifyContent: 'flex-end',
									'.MuiButton-root': {
										minWidth: '120px',
									},
								}}
							>
								<PendingButton
									variant={'white-shadow'}
									sx={{
										color: 'primary.main',
										marginRight: '16px',
									}}
									onClick={() => onSubmit('REJECTED')}
									pending={submitPending === 'REJECTED'}
									disabled={submitPending?.length > 0}
									type={'button'}
								>
									Avslå
								</PendingButton>
								<PendingButton
									variant={'purple'}
									onClick={() => onSubmit('APPROVED', methods.getValues())}
									type={'button'}
									pending={submitPending === 'APPROVED'}
									disabled={submitPending?.length > 0}
								>
									Godkänn
								</PendingButton>
							</Box>
						</Form>
					</Grid>
				)}
			</Grid>
		</TabPanel>
	);
};

export default ErrandCardPanelOne;
