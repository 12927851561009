import * as yup from "yup";

export const newClientInvoiceSchema = yup.object({
	email: yup.string().email().required(),
	emailCopy: yup.string().email().notRequired(),
	county: yup.string().min(1).required(),
	address: yup.string().min(1).required(),
	coAddress: yup.string().notRequired(),
	postalCode: yup
		.string()
		.matches(/^[0-9\s]*$/)
		.notRequired(),
	paymentPeriod: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	paymentMethod: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
});
