import { useState } from 'react';
import SectionWrapper from 'view/subcomponents/wrappers/section-wrapper';
import { MailLeftContainer } from './mail-left-container';
import { MailRightContainer } from './mail-right-container';
import { useTranslation } from 'react-i18next';

export enum StatusOptions {
	success = 'success',
	failed = 'failed',
	scheduled = 'scheduled',
}

export const Mail = () => {
	const [t] = useTranslation();
	const [status, setStatus] = useState(StatusOptions.success.valueOf());
	const [tabValue, setTabValue] = useState('1');

	const statusOptionList = [
		{ value: 'success', label: t('Locations.mail.statusHasSend') },
		{ value: 'failed', label: t('Locations.mail.statusError') },
		{ value: 'scheduled', label: t('Locations.mail.statusScheduled') },
	];

	const updateStatus = (status: { value: string; label: string }) => {
		setStatus(status.value);
	};

	/* SectionWrapper still in use because of a provider.  */
	return (
		<SectionWrapper method={null}>
			<MailLeftContainer tabValue={tabValue} statusOptions={statusOptionList} setStatus={updateStatus} />
			{status && <MailRightContainer tabValue={tabValue} setTabValue={setTabValue} status={status} />}
		</SectionWrapper>
	);
};
