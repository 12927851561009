import React from 'react';
import { Box, Grid } from '@mui/material';

export const StatisticsItem = ({ title, content, ...rest }) => {
	return (
		<React.Fragment>
			<Grid item xs={6} {...rest}>
				<Box fontWeight={'bold'} color={'#000'}>
					{title}
				</Box>
			</Grid>
			<Grid
				item
				xs={6}
				{...rest}
				sx={{
					color: '#000',
				}}
			>
				{content} st
			</Grid>
		</React.Fragment>
	);
};
