import { Box, Grid, Paper } from '@mui/material';
import api from 'api/api';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTypes } from 'types/snackbar';
import { formatOrgNumber } from 'utils/string';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import { ShowMoreButton } from 'view/subcomponents/buttons/show-more-button';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { ClientLogoCardExpanded } from 'view/components/clientLogos/ClientLogoCardExpanded';

export const NewClientLogoErrand = ({ clientLogoErrand }) => {
	const { t } = useTranslation();
	const { enqueue } = useSnackbar();
	const [pending, setPending] = useState(false);
	const type = useRef('');
	const { id, clientId, companyName, companyOrg } = clientLogoErrand;
	const [open, setOpen] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const approveClientLogo = async () => {
		const result = await api.approveClientLogoErrand({
			errandId: id,
		});

		if (result.ok) {
			enqueue(SnackbarTypes.Success, `ClientLogo.Submit.Success.APPROVE`);
			setPending(false);
			setDisableButton(true);
		} else {
			enqueue(SnackbarTypes.Error, `ClientLogo.Submit.Error.APPROVE`);
			setPending(false);
		}
	};

	const rejectClientLogo = async () => {
		const result = await api.rejectClientLogoErrand({
			errandId: id,
		});

		if (result.ok) {
			enqueue(SnackbarTypes.Success, `ClientLogo.Submit.Success.REJECT`);
			setPending(false);
			setDisableButton(true);
		} else {
			enqueue(SnackbarTypes.Error, `ClientLogo.Submit.Error.REJECT`);
			setPending(false);
		}
	};
	return (
		<Paper sx={{ padding: '16px', marginBottom: '16px', borderRadius: '24px', width: '440px' }}>
			<Box display={'flex'} flexDirection={'column'} sx={{ paddingBottom: '12px' }}>
				<Box
					sx={{
						fontWeight: 'bold',
						fontSize: '20px',
						lineHeight: '1.8',
					}}
				>
					{companyName}
				</Box>
				<Box>{formatOrgNumber(companyOrg)}</Box>
			</Box>

			{open && (
				<ClientLogoCardExpanded clientId={clientId}>
					<Grid sx={{ paddingTop: '10px' }}>
						<PendingButton
							type={'submit'}
							variant={'purple-outlined'}
							onClick={rejectClientLogo}
							pending={pending && type.current === 'REJECT'}
							disabled={pending || disableButton}
							sx={{
								marginRight: '16px',
							}}
						>
							{t('Locations.clientLogos.reject')}
						</PendingButton>
						<PendingButton
							type={'submit'}
							variant={'purple'}
							onClick={approveClientLogo}
							disabled={pending || disableButton}
							pending={pending && type.current === 'APPROVE'}
						>
							{t('Locations.clientLogos.approve')}
						</PendingButton>
					</Grid>
				</ClientLogoCardExpanded>
			)}
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					padding: '24px',
				}}
			>
				<ShowMoreButton
					open={open}
					setOpen={() => setOpen(!open)}
					sx={{
						position: 'absolute',
						color: 'primary.main',
						bottom: '-16px',
						left: 'calc(50% - 25px)',
					}}
				/>
			</Box>
		</Paper>
	);
};
