import React from "react";
import { Box } from "@mui/material";
import { useNewClientContext } from "view/subcomponents/providers/new-client-provider";

const NewClientBreadcrumb = ({ index, children }) => {
	const { disabled, page, wizardData, setPage, minPage, lastPage, hasValidData } = useNewClientContext();

	const isValid = wizardData?.[index]?.isValid;

	const isDisabled = () => {
		if (disabled) {
			return true;
		}

		if (index === lastPage) {
			return !hasValidData();
		} else {
			return (!wizardData?.[index]?.isValid && index !== page) || index > minPage;
		}
	};

	const breadCrumbDisabled = isDisabled();
	const isSelected = index === page;

	const onClick = () => {
		if (!breadCrumbDisabled) {
			setPage(index);
		}
	};

	return (
		<Box
			onClick={onClick}
			sx={{
				userSelect: "none",
				marginLeft: "8px",
				marginRight: "8px",
				paddingLeft: "8px",
				paddingRight: "8px",
				borderColor: isValid ? "success.main" : "primary.main",
				color: isValid && !isSelected ? "success.main" : "primary.main",
				border: isSelected ? "2px solid" : "1px solid",
				width: "200px",
				textAlign: "center",
				marginBottom: "8px",
				borderRadius: "8px",
				":hover": {
					backgroundColor: !breadCrumbDisabled ? "rgba(136, 84, 208, 0.2);" : "",
					cursor: breadCrumbDisabled ? "no-drop" : "pointer",
				},
				opacity: breadCrumbDisabled ? "0.5" : "1",
			}}
		>
			{children}
		</Box>
	);
};

export default NewClientBreadcrumb;
