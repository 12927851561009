import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { Form } from 'view/subcomponents/form';
import { useForm } from 'react-hook-form';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import AgreementCardFooter from 'view/components/agreements/subcomponents/agreement-card-footer';
import { yupResolver } from '@hookform/resolvers/yup';
import { agreementContactPersonSchema } from 'utils/validation/schema/agreement-contact-person-schema';
import { useGetUpdateAgreement } from 'store/agreement/use-get-agreement';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import api from 'api/api';
import { queryKeys } from 'store/query-keys';
import { SnackbarTypes } from 'types/snackbar';

const AgreementCardContactPerson = ({ agreementId }) => {
	const { setValue, ...methods } = useForm({
		defaultValues: {
			onboardingName: '',
			onboardingIdentityNumber: '',
			onboardingEmail: '',
			onboardingPhoneNumber: '',
		},
		resolver: yupResolver(agreementContactPersonSchema),
	});
	const { enqueue } = useSnackbar();
	const [pending, setPending] = useState(false);

	const queryClient = useQueryClient();
	const getAgreement = useGetUpdateAgreement(agreementId);
	const onSubmit = async values => {
		await api.updateAgreement({ id: agreementId, data: values }).then(res => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, 'Agreement.Update.Success');
				queryClient.invalidateQueries({ queryKey: [queryKeys.getAgreement] });
			} else {
				enqueue(SnackbarTypes.Error, 'Agreement.Update.Error');
			}
			setPending(false);
		});
	};
	useEffect(() => {
		if (getAgreement.query.isLoading || getAgreement.query.isError) {
			return;
		}

		if (getAgreement.query.isSuccess) {
			const {
				onboardingName,
				onboardingIdentityNumber,
				onboardingEmail,
				onboardingPhoneNumber,
			} = getAgreement.agreement;
			setValue('onboardingName', onboardingName);
			setValue('onboardingIdentityNumber', onboardingIdentityNumber);
			setValue('onboardingEmail', onboardingEmail);
			setValue('onboardingPhoneNumber', onboardingPhoneNumber);
		}
	}, [getAgreement, setValue]);

	return (
		<TabPanel
			value={'2'}
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					fontSize: '20px',
				}}
			>
				Redigera uppgifter för kontaktperson i formuläret nedan.
			</Box>
			<Form methods={{ ...methods, setValue }} onSubmit={onSubmit}>
				<Grid container>
					<Grid item xs={6}>
						<KycFormInput
							name={'onboardingName'}
							placeholder={'Namn'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'onboardingIdentityNumber'}
							placeholder={'Personnummer'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'onboardingEmail'}
							placeholder={'E-post'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'onboardingPhoneNumber'}
							placeholder={'Telefon'}
							disabled={pending}
						/>
					</Grid>

					<AgreementCardFooter pending={pending} />
				</Grid>
			</Form>
		</TabPanel>
	);
};

export default AgreementCardContactPerson;
