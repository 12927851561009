import { createContext, useContext, useState } from 'react';
import { Children } from '../../../types/children';
import { ClientCard, Franchise } from '../../../types/clients';
import useQuery from '../hooks/use-query';
import api from '../../../api/api';

interface IClientsCard extends ClientCard {
	setData: (data: ClientCard) => void;
}

interface IClientsProvider {
	clientData: IClientsCard;
	userData: IClientsCard; // ClientUserData
	setClientData: (data: IClientsCard) => void;
	setUserData: (data: IClientsCard) => void;
	franchises: Franchise[];
}

interface Props {
	children: Children;
}

const Context = createContext<IClientsProvider>(null);

export const useClients = () => useContext<IClientsProvider>(Context);

export const ClientsProvider = ({ children }: Props) => {
	const [clientData, setClientData] = useState<IClientsCard>(null);
	const [userData, setUserData] = useState<IClientsCard>(null);

	const { data: franchises } = useQuery(api.getFranchises) as {
		data: Franchise[];
	};

	return (
		<Context.Provider
			value={{ clientData, setClientData, franchises, userData, setUserData }}
		>
			{children}
		</Context.Provider>
	);
};
