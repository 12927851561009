import React from 'react';

import DashboardLeftContainer from 'view/components/dashboard/dashboard-left-container';
import SectionWrapper from 'view/subcomponents/wrappers/section-wrapper';
import DashboardRightContainer from 'view/components/dashboard/dashboard-right-container';
import api from 'api/api';

const Dashboard = () => {
	return (
		<SectionWrapper method={api.getDashboardSummary}>
			<DashboardLeftContainer />
			<DashboardRightContainer />
		</SectionWrapper>
	);
};

export default Dashboard;
