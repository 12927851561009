import headers from "api/util/headers";
import apiInstance from "api/api-instance";

const CountriesAPI = {

	getHighRiskCountriesFATF: () =>
		apiInstance.get("/api/countries/getHighRiskCountriesFATF", null, headers()),
	getHighRiskCountriesEU: () =>
		apiInstance.get("/api/countries/getHighRiskCountriesEU", null, headers()),
	removeHighRiskCountryFATF: (data) =>
		apiInstance.post("/api/countries/removeHighRiskCountryFATF", data, headers()),
	removeHighRiskCountryEU: (data) =>
		apiInstance.post("/api/countries/removeHighRiskCountryEU", data, headers()),
	addHighRiskCountryFATF: (data) =>
		apiInstance.post("/api/countries/addHighRiskCountryFATF", data, headers()),
	addHighRiskCountryEU: (data) =>
		apiInstance.post("/api/countries/addHighRiskCountryEU", data, headers())						

};

export default CountriesAPI;
