import React from "react";
import LeftContainer from "view/subcomponents/containers/left-container/left-container";
import { LeftMenuInput } from "view/subcomponents/containers/left-container/menu-items/left-menu-input";
import { LeftDateMenu } from "view/subcomponents/containers/left-container/menu-items/left-menu-date";

const InvoicesLeftContainer = () => {
	return (
		<LeftContainer button={true}>
			<LeftMenuInput field={"name"} />
			<LeftMenuInput field={"identityNumber"} />
			<LeftDateMenu field={"date"} />
		</LeftContainer>
	);
};

export default InvoicesLeftContainer;
