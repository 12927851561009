import React from "react";
import { CircularProgressWithText } from "view/subcomponents/common/circular-progress-with-text";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RightContainerItem from "view/subcomponents/containers/right-container/right-container-item";
import { useQueryContext } from "view/subcomponents/providers/query-provider";

const RightContainerPending = () => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const { pending } = useQueryContext();

	return (
		<RightContainerItem show={pending}>
			<CircularProgressWithText
				text={t(`Fetching.${pathname.replace("/", "")}`)}
				sx={{
					color: "primary.main",
				}}
			/>
		</RightContainerItem>
	);
};

export default RightContainerPending;
