import TabPanel from '../../../../subcomponents/tab-panels/tab-panel';
import { AllUsersStatistics } from '../statistics/subcomponents/all-users-statistics/all-users-statistics';
import { ClientMailLog } from './client-mail-log/client-mail-log';

interface ClientCardContentPanelTwoProps {
	clientId: string;
}

export const ClientCardMailLog = ({ clientId }: ClientCardContentPanelTwoProps) => {
	return <TabPanel value={'3'}>{clientId && <ClientMailLog clientId={clientId} />}</TabPanel>;
};
