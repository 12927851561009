import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Divider, Grid} from "@mui/material";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import api from "api/api";
import LeftContainer from "view/subcomponents/containers/left-container/left-container";
import PageTitle from "view/subcomponents/common/page-title";
import { MessagePanel } from "view/subcomponents/common/message-panel/message-panel";
import CountriesTable from "./countries-table";
import { Country } from "types/common";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { SnackbarTypes } from "types/snackbar";
import { ApiResponse } from "apisauce";
import apiInstance from "api/api-instance";
import headers from "api/util/headers";
import { useEffect, useState } from "react";


const Countries = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [countriesFATF, setCountriesFATF] = useState([]);
    const [countriesEU, setCountriesEU] = useState([]);
    const getCountriesFATF = useQuery({queryKey: ['FATF'], queryFn: async () => {
        const getCountries = (): Promise<ApiResponse<Country[], Country[]>> =>
            apiInstance.get<Country[]>(
                'api/countries/getHighRiskCountriesFATF',
                { },
                headers(),
            );
        const result = await getCountries();
        return result;
    }});

    const getCountriesEU = useQuery({queryKey: ['EU'], queryFn: async () => {
        const getCountries = (): Promise<ApiResponse<Country[], Country[]>> =>
            apiInstance.get<Country[]>(
                'api/countries/getHighRiskCountriesEU',
                { },
                headers(),
            );
        const result = await getCountries();
        return result;
    }});


	useEffect(() => {
        
		if (getCountriesFATF.isLoading || getCountriesFATF.isError) {
			return;
		}

		if (getCountriesFATF.isSuccess) {
            setCountriesFATF(getCountriesFATF.data?.data);
        }
        
    }, [getCountriesFATF]);

    useEffect(() => {
        
		if (getCountriesEU.isLoading || getCountriesEU.isError) {
			return;
		}

		if (getCountriesEU.isSuccess) {
            setCountriesEU(getCountriesEU.data?.data);
        }
        
    }, [getCountriesEU]);

	const { enqueue } = useSnackbar();
	const onRemoveFATF = async (country: Country) => {

        await api
        .removeHighRiskCountryFATF({
            code: country.code,
        })
        .then((res: ApiResponse<any>) => {
            if (res.ok) {
                enqueue(SnackbarTypes.Success, 'Country.Success');
                queryClient.invalidateQueries({queryKey: ['FATF']});
            } else {
                enqueue(SnackbarTypes.Error, 'Country.Error');
            }
        });
	};

    const onSubmitFATF = async (code: string, name: string) => {
        await api
        .addHighRiskCountryFATF({
            code: code,
            name: name,
        })
        .then((res: ApiResponse<any>) => {
            if (res.ok) {
                enqueue(SnackbarTypes.Success, 'Country.Success');
                queryClient.invalidateQueries({queryKey: ['FATF']});
            } else {
                enqueue(SnackbarTypes.Error, 'Country.Error');
            }
        });

    }

    
    const onSubmitEU = async (code: string, name: string) => {
        await api
        .addHighRiskCountryEU({
            code: code,
            name: name,
        })
        .then((res: ApiResponse<any>) => {
            if (res.ok) {
                enqueue(SnackbarTypes.Success, 'Country.Success');
                queryClient.invalidateQueries({queryKey: ['EU']});
            } else {
                enqueue(SnackbarTypes.Error, 'Country.Error');
            }
        });

    }
    const onRemoveEU = async (country: Country) => {
        await api
        .removeHighRiskCountryEU({
            code: country.code,
        })
        .then((res: ApiResponse<any>) => {
            if (res.ok) {
                enqueue(SnackbarTypes.Success, 'Country.Success');
                queryClient.invalidateQueries({queryKey: ['EU']});
            } else {
                enqueue(SnackbarTypes.Error, 'Country.Error');
            }
        });
	};

    return (<SectionWrapper method={null}>
            <LeftContainer button={true}/>
            <Grid
			item
			xs={7}
			md={8}
			lg={9}
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>		<Box
        sx={{
            backgroundColor: "#fff",
            borderRadius: "24px",
            width: "100%",
            position: "relative",
            padding: "32px",
            boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
            marginBottom: "16px",
        }}
    >
					<PageTitle
						sx={{
							paddingBottom: "16px",
						}}
					>
						Information
					</PageTitle>
                    <Divider
						sx={{
							marginBottom: "16px",
						}}
					/>
					<MessagePanel type={"info"}>{parse(t(`Locations.countries.description`))}</MessagePanel>


            </Box>
            <Box
        sx={{
            backgroundColor: "#fff",
            borderRadius: "24px",
            width: "100%",
            position: "relative",
            padding: "32px",
            boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
            marginBottom: "16px",
        }}
    >                    <Grid container spacing={2}>
    <Grid item xs={6}>
        <CountriesTable countries={countriesFATF} title={'FATF'} onRemove={onRemoveFATF} onSubmit={onSubmitFATF}/>
        
    </Grid>
    <Grid item xs={6}>
        <CountriesTable countries={countriesEU}  title={'EU'} onRemove={onRemoveEU} onSubmit={onSubmitEU}/>
    </Grid>
</Grid></Box>
        </Grid>
        </SectionWrapper>
    );
};

export default Countries;
