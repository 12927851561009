import React from "react";
import { Box } from "@mui/material";
import { Children } from "types/children";

interface Props {
	show?: boolean;
	sx?: any;
	children: Children;
}

const RightContainerItem = ({ children, show, sx }: Props) => {
	if (!show) {
		return null;
	}

	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				borderRadius: "30px",
				padding: "30px",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

export default RightContainerItem;
