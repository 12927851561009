import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import React, { useRef } from "react";
import { isNullOrUndefined } from "utils/common";

interface Props {
	value: boolean;
	yesValue?: string;
	noValue?: string;
	transformation?: (value) => string;
}

const SwitchTrackText = ({ value, noValue, yesValue, transformation }: Props) => {
	const { t } = useTranslation();
	const yes = useRef(yesValue?.length > 0 ? yesValue : t("Yes"));
	const no = useRef(noValue?.length > 0 ? noValue : t("No"));

	const getTransform = () => {
		if (!isNullOrUndefined(transformation)) {
			return transformation(value);
		}

		return !value ? "translateX(25px)" : "translateX(-15px)";
	};

	const transform = getTransform();

	return (
		<Box
			sx={{
				color: "#fff",
				userSelect: "none",
				transform: transform,
				transition: "all 0.1s ease-in-out",
			}}
		>
			{value ? yes.current : no.current}
		</Box>
	);
};

export default SwitchTrackText;
