import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface IInputNames {
	label: string;
	placeholder: string;
	autoComplete: string;
}

interface Props {
	name: string;
	ignoreFormType?: boolean;
	customFormType?: string;
	customField?: string;
	inputName?: string;
}

const useInputNames = (options: Props): IInputNames => {
	const { t } = useTranslation();
	const [optionsState, setOptionsState] = useState<Props>(options);

	const mountedRef = useRef(true);

	useEffect(() => {
		return () => {
			mountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		if (options?.customFormType !== optionsState?.customFormType) {
			if (mountedRef.current) {
				setOptionsState(options);
			}
		}
	}, [options, optionsState?.customFormType]);

	return useMemo(() => {
		const { name, customField, inputName } = optionsState;

		let fieldName = name;

		if (name.includes(".")) {
			const split = name.split(".");
			fieldName = split[split.length - 1];
		}

		if (inputName) {
			return {
				label: t(`Placeholder.Input.${inputName}.label`),
				placeholder: t(`Placeholder.Input.${inputName}.placeholder`),
				autoComplete: t(`Placeholder.Input.${inputName}.autoComplete`),
			};
		}

		if (name.toLowerCase().includes("email") || name.toLowerCase().includes("mail")) {
			return {
				label: customField ? t(`Placeholder.Input.email.${customField}`) : t("Placeholder.Input.email.label"),
				placeholder: t("Placeholder.Input.email.placeholder"),
				autoComplete: t("Placeholder.Input.email.autoComplete"),
			};
		}

		return {
			label: t(`Placeholder.Input.${fieldName}.label`),
			placeholder: t(`Placeholder.Input.${fieldName}.placeholder`),
			autoComplete: t(`Placeholder.Input.${fieldName}.autoComplete`),
		};
	}, [optionsState, t]);
};

export default useInputNames;
