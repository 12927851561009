import { KycCaretIcon } from "view/subcomponents/icons/icons";

interface Props {
	sx?: any;
}

const AutocompletePopupIcon = ({ sx }: Props) => {
	return (
		<KycCaretIcon
			sx={{
				color: "inherit",
				transform: "rotate(90deg)",
				width: "16px",
				height: "16px",
				...sx,
			}}
		/>
	);
};

export default AutocompletePopupIcon;
