import React from "react";
import { Box } from "@mui/material";
import RightContainerItem from "view/subcomponents/containers/right-container/right-container-item";
import { useQueryContext } from "view/subcomponents/providers/query-provider";

interface Props {
	sx?: any;
	innerSx?: any;
}

const RightContainerEmpty = ({ sx, innerSx }: Props) => {
	const { isEmpty, pending } = useQueryContext();

	return (
		<RightContainerItem show={isEmpty && !pending} sx={sx}>
			<Box
				sx={{
					...innerSx,
					textAlign: "center",
				}}
			>
				<Box
					sx={{
						fontWeight: "bold",
						fontSize: "20px",
						color: "primary.main",
					}}
				>
					Inget resultat för denna sökning
				</Box>
			</Box>
		</RightContainerItem>
	);
};

export default RightContainerEmpty;
