import React, { memo } from "react";
import { Box, Grid } from "@mui/material";
import { SectionProvider } from "view/subcomponents/providers/section-provider";
import { Children } from "types/children";
import { QueryMethod } from "view/subcomponents/providers/query-provider";

interface Props {
	method: QueryMethod;
	children: Children;
	emptyKey?: string;
	disabled?: boolean;
	validation?: any;
}

const SectionWrapper = memo(({ method, children, emptyKey, ...rest }: Props) => {
	return (
		<Box sx={{ display: "flex", height: "100%", width: "100%", paddingBottom: "16px" }}>
			<SectionProvider method={method} emptyKey={emptyKey} {...rest}>
				<Grid
					container
					sx={{
						width: "100%",
					}}
				>
					{children}
				</Grid>
			</SectionProvider>
		</Box>
	);
});

export default SectionWrapper;
