import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatusBox } from 'view/subcomponents/common/status-box';
import { InternalPendingButton } from 'view/subcomponents/buttons/internal-pending-button';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { useState } from 'react';
import api from 'api/api';
import { SnackbarTypes } from 'types/snackbar';

const ClientUser = ({ user, index, updateData }) => {
	const [localUser, setLocalUser] = useState(user);
	const { t } = useTranslation();
	const { enqueue } = useSnackbar();
	const { fullName, email, archived, paused, role, id, creator, lastActivity } = localUser;

	const getStatus = () => {
		if (archived) {
			return 'Archived';
		}
		return paused ? 'Paused' : 'Active';
	};

	const onClick = async () => {
		let newValue = !paused;
		await api.changeStatusClientUser({ id, newStatusBoolValue: newValue }).then(res => {
			if (res.ok) {
				let temp = {
					...localUser,
					paused: newValue,
				};
				setLocalUser(temp);
				updateData(index, temp);
				enqueue(SnackbarTypes.Success, 'Client.UpdatedUser.Success');
			} else {
				enqueue(SnackbarTypes.Error, 'Client.UpdatedUser.Error');
			}
		});
	};

	const updateArchivedStatus = async () => {
		let newValue = !archived;
		await api.changeArchivedStatusClientUser({ id, newStatusBoolValue: newValue }).then(res => {
			if (res.ok) {
				let temp = {
					...localUser,
					archived: newValue,
				};
				setLocalUser(temp);
				updateData(index, temp);
				enqueue(SnackbarTypes.Success, 'Client.UpdatedUser.Success');
			} else {
				enqueue(SnackbarTypes.Error, 'Client.UpdatedUser.Error');
			}
		});
	};

	return (
		<Box
			sx={{
				display: 'flex',
				gap: '16px',
				alignItems: 'center',
				paddingBottom: '12px',
				borderBottom: '1px solid',
				borderColor: 'divider',
			}}
		>
			<Box
				sx={{
					minWidth: '100px',
				}}
			>
				<StatusBox
					sx={{
						color: getStatus() === 'Active' ? 'success.main' : 'error.main',
					}}
				>
					{t(`Statuses.${getStatus()}`)}
				</StatusBox>
			</Box>
			<Box>
				<Box
					sx={{
						fontWeight: 'bold',
					}}
				>
					{fullName}
				</Box>
				<Box>{email}</Box>
				<Box>
					{t('LastActivity')} : {lastActivity}
				</Box>

				<Box>{t(`roles.${role}`)}</Box>
				<Box>Skapad av: {creator ? `${creator?.name} - ${creator?.email}` : '-'}</Box>
			</Box>

			<Box
				sx={{
					display: 'flex',
					flex: '1 1 0',
					justifyContent: 'flex-end',
				}}
			>
				<InternalPendingButton
					onClick={updateArchivedStatus}
					variant={archived ? 'purple' : 'red'}
					sx={{
						height: '40px',
						marginRight: '8px',
					}}
				>
					{!archived ? 'Ta bort' : 'Återställ'}
				</InternalPendingButton>
				<InternalPendingButton
					onClick={onClick}
					variant={paused ? 'purple' : 'purple-outlined'}
					sx={{
						height: '40px',
					}}
				>
					{paused ? 'Aktivera' : 'Avaktivera'}
				</InternalPendingButton>
			</Box>
		</Box>
	);
};
export default ClientUser;
