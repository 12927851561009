import React from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import { Grid } from "@mui/material";
import DashboardColumn from "view/components/dashboard/subcomponents/dashboard-column";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { MessagePanel } from "view/subcomponents/common/message-panel/message-panel";

const DashboardRightContainer = () => {
	const { data } = useQueryContext();

	return (
		<RightContainer
			hasScroll={false}
			sx={{
				padding: "24px",
			}}
		>
			<MessagePanel
				type={"info"}
				title={"Översikt"}
				sx={{
					marginBottom: "16px",
				}}
			>
				Här visas den senaste statistiken för systemet.
			</MessagePanel>

			<Grid container>
				<DashboardColumn title={"Daily"} data={data?.daily} />
				<DashboardColumn title={"Weekly"} data={data?.weekly} />
				<DashboardColumn title={"Monthly"} data={data?.monthly} />
				<DashboardColumn title={"Total"} data={data?.total} />
			</Grid>
		</RightContainer>
	);
};

export default DashboardRightContainer;
