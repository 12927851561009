import { Box, MenuItem } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const HeaderMenuItem = ({ customOnClick, location, disabled }) => {
	const { t } = useTranslation();
	const { push } = useHistory();

	const onClick = () => {
		if (!customOnClick) {
			push(location);
		} else {
			customOnClick();
		}
	};

	return (
		<MenuItem
			onClick={onClick}
			sx={{
				":hover, :active": {
					borderRadius: "16px",
				},
			}}
			disabled={disabled}
		>
			<Box
				sx={{
					fontWeight: "bold",

					minWidth: "100px",
				}}
			>
				{t(`Locations.${location.replace("/", "")}.title`)}
			</Box>
		</MenuItem>
	);
};
