import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import { ShowMoreButton } from 'view/subcomponents/buttons/show-more-button';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Form } from 'view/subcomponents/form';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import { yupResolver } from '@hookform/resolvers/yup';
import { createNewAdminUserSchema } from 'utils/validation/schema/create-new-admin-user-schema';
import api from 'api/api';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { SnackbarTypes } from 'types/snackbar';
import { ApiResponse } from 'apisauce';
import { formatIdentityNumber } from 'utils/on-value-change';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';

let defaultValues = {
	lastName: '',
	firstName: '',
	email: '',
	identityNumber: '',
};

const UsersNewUser = () => {
	const [open, setOpen] = useState(true);
	const [pending, setPending] = useState(false);
	const { t } = useTranslation();
	const { enqueue } = useSnackbar();
	const { addItem } = useQueryContext();

	const methods = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(createNewAdminUserSchema),
	});

	const onSubmit = async values => {
		setPending(true);
		await api.addNewAdminUser(values).then((res: ApiResponse<any>) => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, 'User.Created.Success');
				methods.reset(defaultValues);
				addItem(res.data);
			} else {
				const errorCode = res?.data?.errorCode ?? 'Error';
				enqueue(SnackbarTypes.Error, `User.Created.${errorCode}`);
			}
			setPending(false);
		});
	};

	return (
		<Box
			sx={{
				backgroundColor: '#fff',
				borderRadius: '24px',
				width: '100%',
				position: 'relative',
				padding: '32px',
				boxShadow: '0 4px 18px 0 rgba(32, 73, 136, 0.2)',
				marginBottom: '32px',
			}}
		>
			<Collapse in={open}>
				<Form onSubmit={onSubmit} methods={methods}>
					<Box
						sx={{
							color: 'primary.main',
							fontWeight: 'bold',
							fontSize: '20px',
							paddingBottom: '24px',
						}}
					>
						{t('AddUser')}
					</Box>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<KycFormInput name={'firstName'} placeholder={t('Placeholders.FirstName')} disabled={pending} />
						</Grid>
						<Grid item xs={6}>
							<KycFormInput name={'lastName'} placeholder={t('Placeholders.LastName')} disabled={pending} />
						</Grid>
						<Grid item xs={6}>
							<KycFormInput name={'email'} placeholder={t('Placeholders.Email')} disabled={pending} />
						</Grid>
						<Grid item xs={6}>
							<KycFormInput
								name={'identityNumber'}
								placeholder={t('Placeholders.IdentityNumber')}
								disabled={pending}
								formatValue={v => formatIdentityNumber(v)}
							/>
						</Grid>
					</Grid>
					<Box
						sx={{
							display: 'flex',
							paddingTop: '16px',
							justifyContent: 'flex-end',
						}}
					>
						<PendingButton pending={pending} variant={'purple'} type={'submit'}>
							{t('Add')}
						</PendingButton>
					</Box>
				</Form>
			</Collapse>
			<ShowMoreButton
				open={open}
				setOpen={setOpen}
				sx={{
					position: 'absolute',
					color: 'primary.main',
					left: 'calc(50% - 25px)',
					bottom: '-5%',
				}}
			/>
		</Box>
	);
};

export default UsersNewUser;
