import React from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import { Box, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabContext, TabList } from "@mui/lab";
import NotificationsWriteNotification from "view/components/notifications/subcomponents/notifications-write-notification";
import NotificationsHandleNotifications from "view/components/notifications/subcomponents/notifications-handle-notifications";
import NotificationsList from "view/components/notifications/subcomponents/notifications-list";
import PageTitle from "view/subcomponents/common/page-title";

const NotificationsRightContainer = ({ tabValue, setTabValue }) => {
	const { t } = useTranslation();

	return (
		<RightContainer
			disableActions={true}
			fullHeight={true}
			disableBackground={true}
			hasScroll={false}
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					backgroundColor: "#fff",
					padding: "32px",
					borderRadius: "30px",
				}}
			>
				<PageTitle>{"Notiser"}</PageTitle>
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<TabList
							onChange={(_, value) => setTabValue(value)}
							sx={{
								".MuiTab-root": { textTransform: "none", fontWeight: "bold", fontSize: "18px" },
							}}
						>
							<Tab label={t("SendNotification")} value={"1"} />
							<Tab label={t("HandleNotification")} value={"2"} />
						</TabList>
					</Box>
					<NotificationsWriteNotification />
					<NotificationsHandleNotifications />
				</TabContext>
			</Box>
			{tabValue === "2" && <NotificationsList />}
		</RightContainer>
	);
};

export default NotificationsRightContainer;
