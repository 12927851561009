import { KycCloseIcon } from "view/subcomponents/icons/icons";

const AutocompleteClearIcon = () => {
	return (
		<KycCloseIcon
			sx={{
				width: "12px",
				height: "12px",
				marginRight: "8px",
			}}
		/>
	);
};

export default AutocompleteClearIcon;
