import { ListItem } from "@mui/material";

const AutocompleteListItem = (props, option, { selected }) => {
	return (
		<ListItem
			{...props}
			sx={{
				fontWeight: selected ? "bold" : "normal",
			}}
			key={option?.key ?? option?.value}
		>
			{option.label}
		</ListItem>
	);
};

export default AutocompleteListItem;
