export const preloadImage = (src: string) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = function () {
			resolve(img);
		};
		img.onerror = img.onabort = function () {
			reject(src);
		};
		img.src = src;
	});
};

export const getImagePath = (imageName) => {
	return `/src/assets/images/${imageName}`;
};

export const getImage = (imageName: string) => {
	const image = require(`/src/assets/images/${imageName}`);
	if (image) {
		return image;
	} else {
		// eslint-disable-next-line no-console
		console.info(`${imageName} does not exist!`);
		return null;
	}
};
