import { createContext, useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LeftMenuInitialState from 'config/initial-values/left-menu-initial-state';
import { QueryProvider, QueryMethod } from 'view/subcomponents/providers/query-provider';
import { Children } from 'types/children';
import { AutocompleteType } from 'types/autocomplete-type';
import customerInfo from '../../components/customer-info/customer-info';

interface Props {
	children: Children;
	method: QueryMethod;
	emptyKey?: string;
	validation?: any;
	disabled?: boolean;
}

type AgreementField = 'name' | 'identityNumber' | 'businessArea' | 'paymentModel' | 'formType' | 'dateRange';
type UserField = 'name' | 'SSN';
type NotificationsField = 'message' | 'title';
type KycField = 'id';
type ErrandsField = 'queueStatus' | 'identityNumber' | 'email' | 'signee';
type ClientsField = 'name' | 'clientNumber' | 'identityNumber' | 'emailUser' | 'contactPersonEmail' | 'businessArea';
type CustomerInfoField = 'role';

type MailField = 'status';

export type SectionFieldKey =
	| AgreementField
	| UserField
	| NotificationsField
	| KycField
	| ErrandsField
	| ClientsField
	| CustomerInfoField
	| MailField;

export interface ISection {
	filter: {
		[x: string]: boolean | string | null | AutocompleteType | any;
	};
	updateFilter: (field: SectionFieldKey, value: boolean | string | null | AutocompleteType | any) => void;
	updateFilters: (newFilter: { [x: string]: any }) => void;
	getDefaultValue: (field: SectionFieldKey) => any;
}

const Context = createContext<ISection>(null);

export const useSectionContext = () => useContext(Context) as ISection;

export const SectionProvider = ({ children, method, emptyKey, disabled, validation }: Props) => {
	const { pathname } = useLocation();
	const [filter, setFilter] = useState(LeftMenuInitialState?.[pathname] ?? {});
	const getDefaultValue = (field: SectionFieldKey) => LeftMenuInitialState?.[pathname]?.[field] ?? null;

	const updateFilter = useCallback(
		(field: SectionFieldKey, value) => {
			setFilter({
				...filter,
				[field]: value,
			});
		},
		[setFilter, filter],
	);

	const updateFilters = useCallback(
		newFilter => {
			setFilter({
				...filter,
				...newFilter,
			});
		},
		[filter, setFilter],
	);

	return (
		<Context.Provider value={{ filter, updateFilter, updateFilters, getDefaultValue }}>
			<QueryProvider
				validation={validation}
				disabled={disabled}
				method={method}
				externalOptions={{
					inputData: filter,
					delay: 800,
				}}
				emptyKey={emptyKey}
			>
				{children}
			</QueryProvider>
		</Context.Provider>
	);
};
