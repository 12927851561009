import * as yup from "yup";

export const paymentModelSchema = yup.object({
	basePrice: yup.string().required("Required").min(1),
	includedUsers: yup.string().required("Required").min(1),
	kycPerClient: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	kycPerUser: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	kycPrice: yup.string().required().min(1),
	kycPricePerson: yup.string().required().min(1),
	kycPriceCompany: yup.string().required().min(1),
	name: yup.string().required().min(1),
});
