import React from 'react';
import LeftContainer from 'view/subcomponents/containers/left-container/left-container';
import LeftContainerAutocompleteBusinessarea from 'view/subcomponents/input/autocomplete/templates/left-container-autocmplete-businessarea';
import { LeftMenuInput } from 'view/subcomponents/containers/left-container/menu-items/left-menu-input';

const ClientsLeftContainer = () => {
	return (
		<LeftContainer button={true}>
			<LeftMenuInput field={'name'} />
			<LeftMenuInput field={'clientNumber'} />
			<LeftMenuInput field={'identityNumber'} />
			<LeftContainerAutocompleteBusinessarea />
			<LeftMenuInput field={'emailUser'} />
			<LeftMenuInput field={'contactPersonEmail'} />
		</LeftContainer>
	);
};

export default ClientsLeftContainer;
