import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@mui/material';
import RightContainerCard from 'view/subcomponents/card/right-container-card/right-container-card';
import api from 'api/api';
import { QueryProvider } from 'view/subcomponents/providers/query-provider';
import { Client } from 'types/clients';
import ClientCardContentWrapper from 'view/components/clients/subcomponents/client-card/client-card-content-wrapper';

interface Props {
	client: Client;
}

const ClientCard = ({ client }: Props) => {
	const [open, setOpen] = useState(false);

	const { clientId, name, clientNumber } = client;

	return (
		<RightContainerCard
			open={open}
			setOpen={setOpen}
			sx={{
				paddingLeft: '32px',
				paddingRight: '32px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box pb={2}>
				<Box
					sx={{
						transition: '0.5s padding',
						paddingBottom: open ? '24px' : '',
					}}
				>
					<Box
						sx={{
							fontWeight: 'bold',
							fontSize: '20px',
							lineHeight: '1.8',
						}}
					>
						{name}
					</Box>
					<Box>{clientNumber}</Box>
				</Box>
				<Collapse in={open} unmountOnExit>
					<Grid
						container
						sx={{
							color: '#000',
							paddingBottom: '16px',
						}}
					>
						<QueryProvider
							method={api.getClient}
							externalOptions={{
								inputData: {
									clientId: clientId,
								},
							}}
						>
							<ClientCardContentWrapper clientId={clientId} />
						</QueryProvider>
					</Grid>
				</Collapse>
			</Box>
		</RightContainerCard>
	);
};

export default ClientCard;
