import React from 'react';
import LeftContainer from 'view/subcomponents/containers/left-container/left-container';
import { RouterPaths } from 'config/constants';
import useQuery from 'view/subcomponents/hooks/use-query';
import api from 'api/api';
import LeftMenuLocation from 'view/subcomponents/containers/left-container/menu-items/left-menu-location';

const DashboardLeftContainer = () => {
	const { data } = useQuery(api.getDashboardCount);

	return (
		<LeftContainer button={false}>
			<LeftMenuLocation location={RouterPaths.Clients} />
			<LeftMenuLocation location={RouterPaths.Invoices} />
			<LeftMenuLocation location={RouterPaths.Users} />
			<LeftMenuLocation location={RouterPaths.Notifications} />
			<LeftMenuLocation location={RouterPaths.Mail} />
			<LeftMenuLocation location={RouterPaths.Payments} />
			<LeftMenuLocation location={RouterPaths.Kyc} />
			<LeftMenuLocation location={RouterPaths.Info} />
			<LeftMenuLocation location={RouterPaths.CustomerInfo} />
			<LeftMenuLocation location={RouterPaths.Franchises} count={data?.franchiseCount ?? 0} />
			<LeftMenuLocation location={RouterPaths.Errands} count={data?.registerCount ?? 0} />
			<LeftMenuLocation location={RouterPaths.Agreements} count={data?.orderCount ?? 0} />
			<LeftMenuLocation location={RouterPaths.Redirects} />
			<LeftMenuLocation location={RouterPaths.Countries} />
			<LeftMenuLocation location={RouterPaths.ClientLogos} count={data?.clientLogoCount ?? 0} />
		</LeftContainer>
	);
};

export default DashboardLeftContainer;
