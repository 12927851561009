import React, { useState } from "react";
import ErrandsCard from "view/components/errands/subcomponents/errands-card";
import api from "api/api";
import { QueryProvider } from "view/subcomponents/providers/query-provider";
import FranchiseErrand from "view/components/franchises/subcomponents/franchise-errand";

const FranchiseErrandCard = ({ franchiseErrand, index, removeItem }) => {
	const [open, setOpen] = useState(false);
	const { id } = franchiseErrand;

	return (
		<QueryProvider
			method={api.getFranchiseErrand}
			externalOptions={{
				inputData: { id: id },
			}}
			disabled={!open}
		>
			<ErrandsCard errand={franchiseErrand} showStatus={false} open={open} setOpen={setOpen} showEdit={false} useTab={false}>
				<FranchiseErrand id={id} index={index} removeItem={removeItem} />
			</ErrandsCard>
		</QueryProvider>
	);
};

export default FranchiseErrandCard;
