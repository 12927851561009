import { Kyc } from "types/kyc";
import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import { InternalPendingButton } from "view/subcomponents/buttons/internal-pending-button";
import api from "api/api";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { SnackbarTypes } from "types/snackbar";

interface Props {
	kyc: Kyc;
}

const KycCardFooter = ({ kyc }: Props) => {
	const { enqueue } = useSnackbar();
	const { data, updateData } = useQueryContext();

	const onClick = async () => {
		const result = await api.updateArchived({ id: kyc.id });
		if (result.ok) {
			updateData({
				archived: !data?.archived,
			});
			enqueue(SnackbarTypes.Success, "Kyc.UpdateArchived.Success");
		} else {
			enqueue(SnackbarTypes.Error, "Kyc.UpdateArchived.Error");
		}
	};

	return (
		<>
			<Grid
				item
				xs={12}
				sx={{
					paddingTop: "16px",
					paddingBottom: "16px",
				}}
			>
				<Divider />
			</Grid>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					justifyContent: "flex-end",
				}}
			>
				<InternalPendingButton
					onClick={onClick}
					variant={!kyc?.archived ? "purple" : "white-shadow"}
					sx={{
						color: !kyc?.archived ? "#fff" : "primary.main",
						minWidth: "160px",
					}}
				>
					{!kyc?.archived ? "Arkivera KYC" : "Aktivera KYC"}
				</InternalPendingButton>
			</Box>
		</>
	);
};

export default KycCardFooter;
