import React from "react";
import { Box } from "@mui/material";
import { getInitials } from "utils/name";

export const UserImageContainer = ({ name, sx }) => {
	return (
		<Box
			sx={{
				border: "3px solid",
				borderColor: "#000",
				color: "#000",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				...sx,
			}}
		>
			<Box
				fontWeight={800}
				sx={{
					userSelect: "none",
				}}
			>
				{getInitials(name)}
			</Box>
		</Box>
	);
};
