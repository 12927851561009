import React, { createRef, memo, useEffect, useMemo, useState } from "react";
import { useOnScreen } from "view/subcomponents/hooks/use-on-screen";
import { isInViewport } from "utils/viewport";
import { ScrollButton } from "view/subcomponents/scroll/scroll-button";
import { ScrollButtonsProvider } from "view/subcomponents/providers/scroll-buttons-provider";
import { Children } from "types/children";

interface Props {
	children: Children;
	items: any[];
	pending?: boolean;
	addShadow?: boolean;
	disabled?: boolean;
	scrollToBottomOnNewItem?: boolean;
	bottom?: any;
	top?: any;
}

export const ScrollButtons = memo(({ children, items, pending, addShadow, disabled, scrollToBottomOnNewItem, bottom, top }: Props) => {
	const [displayShadow, setDisplayShadow] = useState(false);
	const [refs, setRefs] = useState([]);
	const [hasInitiated, setHasInitiated] = useState(false);
	const topIsOnScreen = useOnScreen(refs[0], true);
	const bottomIsOnScreen = useOnScreen(refs[refs.length - 1], false);

	useEffect(() => {
		if (scrollToBottomOnNewItem && refs.length > 0) {
			refs[refs.length - 1].current.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "end",
			});
		}
	}, [refs, scrollToBottomOnNewItem]);

	useMemo(() => {
		if (items && items.length > 0) {
			let temp = [];

			for (let i = 0; i < items.length; i++) {
				temp.push(createRef());
			}

			setRefs(temp);
		} else {
			setRefs([]);
		}
	}, [items, setRefs]);

	useEffect(() => {
		if (refs.length > 0) {
			setHasInitiated(true);
		}
	}, [refs, setHasInitiated]);

	const onClick = (direction) => {
		if (refs.length > 0) {
			if (direction === "up") {
				let smallestIndexInView = refs.length - 1;
				for (let i = 0; i < refs.length; i++) {
					let ref = refs[i];
					if (isInViewport(ref.current)) {
						if (i < smallestIndexInView) {
							smallestIndexInView = i;
						}
					}
				}

				refs[smallestIndexInView].current.scrollIntoView({
					behavior: "smooth",
					block: "end",
					inline: "end",
				});
			} else {
				let largestIndexInView = 0;

				for (let i = 0; i < refs.length; i++) {
					let ref = refs[i];
					if (isInViewport(ref.current)) {
						largestIndexInView = i;
					}
				}

				refs[largestIndexInView].current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		}
	};

	useEffect(() => {
		if (addShadow) {
			setDisplayShadow(!bottomIsOnScreen && addShadow && !pending);
		}
	}, [setDisplayShadow, bottomIsOnScreen, addShadow, pending]);

	return (
		<ScrollButtonsProvider refs={refs} displayShadow={displayShadow}>
			{children}
			<ScrollButton
				show={!topIsOnScreen && hasInitiated && !pending && !disabled}
				onClick={() => onClick("up")}
				sx={{
					top: top ?? 0,
				}}
			/>

			<ScrollButton
				show={!bottomIsOnScreen && hasInitiated && !pending && !disabled}
				onClick={() => onClick("down")}
				sx={{
					bottom: bottom ? bottom : "0",
				}}
				iconProps={{
					sx: {
						transform: "rotate(180deg)",
					},
				}}
			/>
		</ScrollButtonsProvider>
	);
});
