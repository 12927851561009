import * as yup from "yup";
import { isValidPhoneNumber, isValidStrictPnr } from "utils/validation/validation";

export const newClientPersonSchema = yup.object({
	firstName: yup.string().min(1).required(),
	lastName: yup.string().min(1).required(),
	identityNumber: yup.string().test("ssn", "Required", isValidStrictPnr),
	email: yup.string().email().required(),
	phoneNumber: yup.string().test("phone", "Required", (v) => {
		if (v === undefined || v === "") {
			return true;
		} else {
			return isValidPhoneNumber(v);
		}
	}),
});
