import React, { useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { MessagePanel } from 'view/subcomponents/common/message-panel/message-panel';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import RichTextEditor from 'view/subcomponents/rich-text-editor/rich-text-editor';
import { KycAutocomplete } from 'view/subcomponents/input/autocomplete/kyc-autocomplete';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import { Form } from 'view/subcomponents/form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { notificationSchema } from 'utils/validation/schema/notification-schema';
import { quillIsEmpty } from 'utils/string';
import api from 'api/api';
import useQuery from 'view/subcomponents/hooks/use-query';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { useTranslation } from 'react-i18next';
import { NotificationImages, NotificationTypes } from 'config/constants/notifications';
import ChangeNotificationDialog from 'view/subcomponents/dialog/preview-notification-dialog';
import { SnackbarTypes } from 'types/snackbar';

let defaultValues = {
	title: '',
	clientType: null,
	customer: null,
	notificationType: {
		value: 'GLOBAL_NOTIFICATION',
		label: 'Global',
	},
};

export enum RoleTypes {
	BusinessAdmin = 'BusinessAdmin',
	Approver = 'Approver',
	User = 'User',
}

const NotificationsWriteNotification = () => {
	const { t } = useTranslation();
	const { enqueue } = useSnackbar();
	const [submitPending, setPending] = useState(false);
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState('');
	const [quillError, setQuillError] = useState(false);
	const [selected, setSelected] = useState('');
	const methods = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(notificationSchema),
	});

	const onSubmit = async values => {
		setQuillError(quillIsEmpty(value));
		if (!quillError) {
			setPending(true);
			let data = {
				title: values.title,
				identityNumber: values?.customer?.value ?? '',
				clientType: values?.clientType?.value ?? '',
				imageType: values?.imageType?.value ?? '',
				role: values?.role?.value ?? '',
				message: value,
			};

			await api.sendGlobalNotification(data).then(res => {
				if (res.ok) {
					setValue('');
					methods.reset(defaultValues);
					enqueue(SnackbarTypes.Success, 'Notification.Success');
				} else {
					enqueue(SnackbarTypes.Error, 'Notification.Success');
				}
				setPending(false);
			});
		}
	};

	const { data } = useQuery(api.getClients);

	const onChange = (value, name) => {
		if (value !== null) {
			setSelected(name);
		} else {
			setSelected('');
		}
	};

	// @ts-ignore
	// @ts-ignore
	return (
		<TabPanel value={'1'}>
			<Form methods={methods} onSubmit={onSubmit}>
				<MessagePanel
					title={'Information'}
					type={'info'}
					sx={{
						textAlign: 'left',
						fontWeight: 'normal',
						marginBottom: '32px',
					}}
				>
					<ul>
						<li>Om kundnummer lämnas tomt kommer det att skickas till alla kunder för den specifierade kundtypen</li>
						<li>Om kundtyp lämnas tomt kommer det skickas till alla kundtyper</li>
					</ul>
				</MessagePanel>
				<KycFormInput
					name={'title'}
					placeholder={'Titel'}
					sx={{
						marginBottom: '16px',
					}}
				/>
				<RichTextEditor value={value} setValue={setValue} error={quillError} />
				<Divider
					sx={{
						marginTop: '24px',
						marginBottom: '24px',
					}}
				/>
				<Grid
					container
					sx={{
						'.MuiGrid-root:nth-of-type(even)': {
							paddingLeft: '24px',
							paddingBottom: '8px',
						},
						'.MuiGrid-root:nth-of-type(odd):not(:last-child)': {
							paddingRight: '24px',
							paddingBottom: '8px',
						},
					}}
				>
					<Grid item xs={6}>
						<KycAutocomplete
							name={'notificationType'}
							options={Object.values(NotificationTypes).map(notificationType => ({
								value: notificationType,
								label: t(`NotificationTypes.${notificationType}`),
							}))}
							disabled={true}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycAutocomplete
							name={'imageType'}
							options={Object.values(NotificationImages).map(image => ({
								value: image,
								label: t(`ImageTypes.${image}`),
							}))}
						/>
					</Grid>

					<Grid item xs={6}>
						<KycAutocomplete
							disabled={selected?.length > 0 && selected !== 'customer'}
							externalOnChange={value => onChange(value, 'customer')}
							name={'customer'}
							options={
								data?.map(({ identityNumber, name }) => ({
									value: identityNumber,
									label: name,
								})) ?? []
							}
						/>
					</Grid>

					<Grid item xs={6}>
						<KycAutocomplete
							disabled={selected?.length > 0 && selected !== 'role'}
							externalOnChange={value => onChange(value, 'role')}
							name={'role'}
							options={
								Object.values(RoleTypes)?.map(role => ({
									value: role,
									label: t(`RoleTypes.${role}`),
								})) ?? []
							}
						/>
					</Grid>

					<Grid item xs={12} container justifyContent={'flex-end'}>
						<Divider
							sx={{
								width: '100%',
								marginTop: '24px',
								marginBottom: '24px',
							}}
						/>
						<Button variant={'purple-outlined'} onClick={() => setOpen(true)}>
							Förhandsvisa
						</Button>
						<PendingButton
							pending={submitPending}
							variant={'purple'}
							type={'submit'}
							sx={{
								marginLeft: '16px',
								minWidth: '125px',
							}}
						>
							Skicka notis
						</PendingButton>
					</Grid>
				</Grid>
				<ChangeNotificationDialog open={open} setOpen={setOpen} getValues={methods.getValues} message={value} />
			</Form>
		</TabPanel>
	);
};

export default NotificationsWriteNotification;
