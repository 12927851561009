import { useMemo, useState } from "react";
import { isLoginSuccessful } from "utils/login";

export const useLoginStatus = (status) => {
	const [loginStatus, setLoginStatus] = useState({
		pending: true,
		success: false,
		failure: false,
	});

	useMemo(() => {
		const newLoginStatus = isLoginSuccessful(status);
		switch (newLoginStatus) {
			case true:
				setLoginStatus({
					pending: false,
					success: true,
					failure: false,
				});
				break;
			case false:
				setLoginStatus({
					pending: false,
					success: false,
					failure: true,
				});
				break;
			case null:
				setLoginStatus({
					pending: true,
					success: false,
					failure: false,
				});
				break;
			default:
				setLoginStatus({
					pending: true,
					success: false,
					failure: false,
				});
				break;
		}
	}, [status, setLoginStatus]);

	return loginStatus;
};
