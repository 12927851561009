import apiInstance from "api/api-instance";
import headers from "api/util/headers";

const AuthAPI = {
	poll: (data) => apiInstance.post("/api/auth/poll", data),
	initializeAuthentication: (data) => apiInstance.post("/api/auth/initializeAuthentication", data),
	logout: (data) => apiInstance.post("/api/auth/logout", data, headers()),
};

export default AuthAPI;
