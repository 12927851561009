import React from "react";
import { IconButton } from "@mui/material";
import { KycNavigationIcon } from "view/subcomponents/icons/icons";

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	sx?: any;
}

export const ShowMoreButton = ({ setOpen, open, sx, ...rest }: Props) => {
	return (
		<IconButton
			onClick={() => setOpen(!open)}
			sx={{
				textTransform: "none",
				color: "#000",
				backgroundColor: "#fff",
				borderRadius: "32px",
				fontWeight: "bold",
				height: "50px",
				width: "50px",
				fontSize: "16px",
				boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",

				":hover": {
					backgroundColor: "#d3d3d3",
				},
				...sx,
			}}
			{...rest}
		>
			<KycNavigationIcon
				variant={"medium"}
				sx={{ transition: "all 0.1s ease-in-out", transform: open ? "rotate(0deg)" : "rotate(180deg)" }}
			/>
		</IconButton>
	);
};
