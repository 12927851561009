import * as yup from "yup";
import { isValidOrgOrPnr } from "utils/validation/validation";

export const newClientCompanySchema = yup.object({
	name: yup.string().min(1).required(),
	identityNumber: yup.string().test("ssn", "Required", isValidOrgOrPnr),
	businessArea: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
});
