import { Box } from '@mui/material';
import { ClientLogoErrand } from 'store/client-logo/use-get-new-client-logo-errands';
import { NoData } from 'view/components/clientLogos/clientLogos-no-data';
import { NewClientLogoErrand } from 'view/components/clientLogos/new-client-logo-errand';

interface ClientLogosRightErrandContainerProps {
	clientLogoErrands: ClientLogoErrand[];
}

export const ClientLogosRightErrandContainer = ({ clientLogoErrands }: ClientLogosRightErrandContainerProps) => {
	if (clientLogoErrands.length === 0) {
		return <NoData />;
	}

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{clientLogoErrands.map((logo, index) => (
				<NewClientLogoErrand clientLogoErrand={logo} key={index} />
			))}
		</Box>
	);
};
