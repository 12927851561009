export const formatOrgNumber = (org) => {
	if (org) {
		return org.slice(0, 6) + "-" + org.slice(6);
	} else {
		return "";
	}
};

export const formatCardString = (item) => {
	if (!item || item?.length === 0) {
		return "-";
	} else {
		return item;
	}
};

export const quillIsEmpty = (value) => {
	return value.replace(/<(.|\n)*?>/g, "").trim().length === 0 && !value.includes("<img");
};
