import * as yup from "yup";

export const notificationSchema = yup.object({
	title: yup.string().required("Required").min(1),
	clientType: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.nullable(),
	notificationType: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	imageType: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	customer: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.nullable(),
});
