import { useNewClientContext } from "view/subcomponents/providers/new-client-provider";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Form } from "view/subcomponents/form";
import { Grid } from "@mui/material";
import { KycCheckbox } from "view/subcomponents/input/checkbox/kyc-checkbox";

const NewClientOtherInfo = () => {
	const { onSubmit, wizardData, page, disablePagesAhead } = useNewClientContext();
	const hasDisabled = useRef(false);
	const methods = useForm({
		defaultValues: {
			page: 4,
			implementationHelp: wizardData?.[page]?.data?.implementationHelp ?? false,
			webinar: wizardData?.[page]?.data?.webinar ?? false,
		},
	});

	const { isDirty } = methods.formState;

	useEffect(() => {
		if (isDirty && hasDisabled.current === false) {
			disablePagesAhead();
			hasDisabled.current = true;
		}
	}, [isDirty, disablePagesAhead]);

	return (
		<Form
			methods={methods}
			onSubmit={onSubmit}
			sx={{
				marginBottom: "16px",
				marginTop: "16px",
			}}
			id={"new-client-form"}
		>
			<Grid container columnSpacing={4} rowSpacing={1}>
				<Grid item xs={6}>
					<KycCheckbox name={"implementationHelp"} label={"Implementationshjälp?"} />
				</Grid>
				<Grid item xs={6}>
					<KycCheckbox name={"webinar"} label={"Webbutbildning?"} />
				</Grid>
			</Grid>
		</Form>
	);
};

export default NewClientOtherInfo;
