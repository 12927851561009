import * as personnummer from "personnummer.js";
import * as orgNrValidator from "se-orgnr-validator";

export const isValidOrg = (org: string): boolean => {
	if (org) {
		return orgNrValidator(org);
	} else {
		return false;
	}
};

export const isValidEmail = (email: string): boolean => {
	// eslint-disable-next-line

	const regExp =
		// eslint-disable-next-line
		/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
	return regExp.test(email);
};

export function isValidPnr(pnr: string): boolean {
	if (pnr && pnr.length === 12) {
		if (/^[0-9]{8}[0]{4}/.test(pnr)) {
			// hantera pnr som slutar på 0000 som vi får från api
			return true;
		}
	} else if (pnr && pnr.length === 10) {
		if (/^[0-9]{10}/.test(pnr)) {
			return true;
		}
	} else if (pnr && pnr.length === 8) {
		if (/^[0-9]{8}/.test(pnr)) {
			return true;
		}
	}

	return personnummer.validate(pnr);
}

export const isValidStrictPnr = (pnr) => {
	return personnummer.validate(pnr);
};

export const isValidOrgOrStrictPnr = (value) => {
	return isValidStrictPnr(value) || isValidOrg(value);
};

export function isValidPhoneNumber(number) {
	let replaced = number.replaceAll("-", "");

	let regex = new RegExp("^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$");
	return regex.test(replaced);
}

export function isUUID(uuid: string): boolean {
	let s = "" + uuid;
	const match = s.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
	return match !== null;
}

const defaultCountryValidation = (value: string): boolean => {
	return value && value.length > 0;
};

export const getCompanyValidationByCountryCode = (code) => {
	switch (code) {
		case "SE":
			return isValidOrgOrStrictPnr;
		default:
			return defaultCountryValidation;
	}
};

export const getPersonValidationByCountryCode = (code, isStrict) => {
	switch (code) {
		case "SE":
			return isStrict ? isValidStrictPnr : isValidPnr;
		default:
			return defaultCountryValidation;
	}
};
