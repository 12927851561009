import { createContext, useCallback, useContext, useRef, useState } from "react";
import { SnackbarType } from "types/snackbar";
import { v4 as uuidv4 } from "uuid";
import Snackbar from "view/subcomponents/snackbar/snackbar";

export type SnackbarItemType = {
	variant: SnackbarType;
	translateKey: string;
	key: string;
	additionalText?: string;
};

type SnackbarState = {
	[x: string]: SnackbarItemType;
};

interface ISnackbarProvider {
	enqueue: (variant: SnackbarType, translateKey: string, additionalText?: string) => void;
	dequeue: (key: string) => void;
	notifications: SnackbarState;
}

const Context = createContext<ISnackbarProvider>(null);

export const useSnackbar = () => useContext(Context) as ISnackbarProvider;

const SnackbarProvider = ({ children }) => {
	const [notifications, setNotifications] = useState<SnackbarState>({});
	const notificationRef = useRef<SnackbarState>({});

	const enqueue = useCallback(
		(variant: SnackbarType, translateKey: string, additionalText?: string, translate = true) => {
			const key = uuidv4();

			const snackbar = {
				...notifications,
				[key]: {
					variant: variant,
					translateKey: translateKey,
					additionalText: additionalText,
					translate: translate,
					key: key,
				},
			};

			notificationRef.current = snackbar;

			setNotifications(snackbar);
		},
		[setNotifications, notifications]
	);

	const dequeue = useCallback(
		(key: string) => {
			const { [key]: ignore, ...rest } = notificationRef.current;
			notificationRef.current = rest;
			setNotifications(rest);
		},
		[setNotifications]
	);

	return (
		<Context.Provider value={{ enqueue, notifications, dequeue }}>
			<Snackbar />
			{children}
		</Context.Provider>
	);
};

export default SnackbarProvider;
