import React from "react";
import { Box } from "@mui/material";

const Rectangle = ({ sx }) => {
	return (
		<Box
			sx={{
				top: "0.2%",
				left: "-0.5%",
				width: "8px",
				height: "55px",
				borderRadius: "5px",
				border: "solid 1px #8854d0",
				backgroundColor: "#8854d0",
				marginTop: "16px",
				position: "absolute",
				zIndex: 300,
				...sx,
			}}
		/>
	);
};

export default Rectangle;
