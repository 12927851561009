import TabPanel from '../../../../subcomponents/tab-panels/tab-panel';
import { AllUsersStatistics } from '../statistics/subcomponents/all-users-statistics/all-users-statistics';

interface ClientCardContentPanelTwoProps {
	clientId: string;
}

const ClientCardContentPanelTwo = ({ clientId }: ClientCardContentPanelTwoProps) => {
	return <TabPanel value={'2'}>{clientId && <AllUsersStatistics clientId={clientId} />}</TabPanel>;
};

export default ClientCardContentPanelTwo;
