import React from "react";
import LeftContainer from "view/subcomponents/containers/left-container/left-container";
import { Box } from "@mui/material";
import { LeftMenuInput } from "view/subcomponents/containers/left-container/menu-items/left-menu-input";

const NotificationsLeftContainer = ({ tabValue }) => {
	return (
		<LeftContainer button={true}>
			{tabValue === "1" ? (
				<Box>
					Byt till fliken
					<Box component={"span"} fontWeight={"bold"} pl={"4px"} pr={"4px"}>
						''Hantera notiser''
					</Box>
					för att söka bland notiser.
				</Box>
			) : (
				<>
					<LeftMenuInput field={"title"} />
					<LeftMenuInput field={"message"} />
				</>
			)}
		</LeftContainer>
	);
};

export default NotificationsLeftContainer;
