import { isAuthenticated } from "utils/user";
import { Redirect, Route } from "react-router-dom";
import { RouterPaths } from "config/constants";

const HomeRoute = ({ ...rest }) => {
	if (isAuthenticated()) {
		return <Redirect to={RouterPaths.Dashboard} />;
	} else {
		return <Route {...rest} />;
	}
};

export default HomeRoute;
