import { StatusBox } from "view/subcomponents/common/status-box";
import { Box } from "@mui/material";
import { Kyc } from "types/kyc";

interface Props {
	kyc: Kyc;
}

const KycCardHeader = ({ kyc }: Props) => {
	const { archived } = kyc;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				width: "100%",
			}}
		>
			<StatusBox>Kundkännedom</StatusBox>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flex: "1 1 0",
					justifyContent: "flex-end",
				}}
			>
				<StatusBox
					sx={{
						color: archived ? "error.main" : "success.main",
					}}
				>
					{archived ? "Arkiverad" : "Ej arkiverad"}
				</StatusBox>
			</Box>
		</Box>
	);
};

export default KycCardHeader;
