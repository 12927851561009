export enum BusinessAreas {
	Fund = "Fund",
	Lawyer = "Lawyer",
	Jurist = "Jurist",
	AccountingConsultant = "AccountingConsultant",
	Accountant = "Accountant",
	EstateAgent = "EstateAgent",
	PostboxCompany = "PostboxCompany",
	OfficeHotel = "OfficeHotel",
	LoanIntermediaries = "LoanIntermediaries",
	Finance = "Finance",
	JewelryRetailer = "JewelryRetailer",
	ClockSales = "ClockSales",
	ExchangeAgent = "ExchangeAgent",
	GoldAndSilverRetailer = "GoldAndSilverRetailer",
	CarSales = "CarSales",
	CompanyIntermediaries = "CompanyIntermediaries",
	BoatSales = "BoatSales",
	AuctionHouse = "AuctionHouse",
	GamesAndCasino = "GamesAndCasino",
	CarBroker = "CarBroker",
	Forensic = "Forensic",
	PrivateEquity = "PrivateEquity",
	Other = "Other",
}
