import { useQuery } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';
import { queryKeys } from 'store/query-keys';
import { ClientStatistics } from 'types/clients';

export const useGetClientStatistics = clientId => {
	const result = useQuery({
		queryKey: [queryKeys.getClientStatisticsById, clientId],
		queryFn: async () => {
			const getClientStatistics = (): Promise<ApiResponse<ClientStatistics, ClientStatistics>> =>
				apiInstance.get<ClientStatistics>(apiRoutes.statistics.getClientStatisticsById(clientId), {}, headers());
			const result = await getClientStatistics();
			return result;
		},
		refetchOnWindowFocus: false,
		staleTime: 3600000 
	});

	return result;
};