import React from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import AgreementCard from "view/components/agreements/subcomponents/agreement-card";

const AgreementsRightContainer = () => {
	const { data } = useQueryContext();
	return (
		<RightContainer displayTopInfo={true}>
			{data != null && data?.map((agreement, index) => <AgreementCard agreement={agreement} key={index} index={index} />)}
		</RightContainer>
	);
};

export default AgreementsRightContainer;
