import React from "react";
import { Box } from "@mui/material";
import RightContainerItem from "view/subcomponents/containers/right-container/right-container-item";
import { useQueryContext } from "view/subcomponents/providers/query-provider";

const RightContainerError = () => {
	const { error } = useQueryContext();

	return (
		<RightContainerItem
			show={error}
			sx={{
				borderRadius: "24px",
				padding: "30px",
				marginBottom: "30px",
				position: "relative",
				backgroundColor: "#fff",
				color: "primary.main",
				fontSize: "24px",
				fontWeight: "bold",
				textAlign: "center",
			}}
		>
			<Box>Det uppstod ett fel</Box>
		</RightContainerItem>
	);
};

export default RightContainerError;
