import React, { useRef, useState } from "react";
import api from "api/api";
import { useSectionContext } from "view/subcomponents/providers/section-provider";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { CSVLink } from "react-csv";
import RightContainerInfo from "view/subcomponents/containers/right-container/right-container-info";
import { SnackbarTypes } from "types/snackbar";
import { ApiResponse } from "apisauce";

const defaultTransactionData = {
	fileContent: [],
	fileName: "",
};

const InvoicesInfo = ({ clients }) => {
	const { filter } = useSectionContext();
	const { enqueue } = useSnackbar();
	const csvRef = useRef(null);
	const [transactionData, setTransactionData] = useState(defaultTransactionData);

	const onClick = async () => {
		await api
			.getCSVFiles({
				date: filter.date,
			})
			.then((res: ApiResponse<any>) => {
				if (res.ok) {
					setTransactionData(res.data);
					csvRef.current.link.click();
				} else {
					enqueue(SnackbarTypes.Error, "CSV.Error");

					setTransactionData(defaultTransactionData);
				}
			});
	};

	return (
		<RightContainerInfo
			onClick={onClick}
			buttonProps={{
				buttonText: clients.length === 0 ? "Ladda ned CSV för alla klienter" : "Ladda ned CSV för valda klienter",
			}}
		>
			<CSVLink
				data={transactionData?.fileContent ?? []}
				filename={transactionData?.fileName ?? ""}
				className="hidden"
				ref={csvRef}
				target="_blank"
			/>
		</RightContainerInfo>
	);
};

export default InvoicesInfo;
