import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import api from "api/api";
import RedirectRightContainer from "./subcomponents/RedirectRightContainer";
import RedirectLeftContainer from "./subcomponents/RedirectLeftContainer";
import { useState } from "react";

const Redirects = () => {
    const [routeId, setRouteId] = useState();
    return (
        <SectionWrapper method={api.getCustomResources}>
            <RedirectLeftContainer setRoute={setRouteId} />
            <RedirectRightContainer routeId={routeId} />
        </SectionWrapper>
    );
};

export default Redirects;
