import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { Box, Divider } from "@mui/material";
import { CircularProgressWithText } from "view/subcomponents/common/circular-progress-with-text";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "view/subcomponents/form";
import { ErrandCardFranchise } from "view/components/errands/subcomponents/errand-card-franchise";
import RightContainerCardHeader from "view/subcomponents/card/right-container-card/right-container-card-header";
import RightContainerCardItem from "view/subcomponents/card/right-container-card/right-container-card-item";
import { yupResolver } from "@hookform/resolvers/yup";
import { setFranchiseSchema } from "utils/validation/schema/set-franchise-schema";
import api from "api/api";
import PendingButton from "view/subcomponents/buttons/pending-button";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { SnackbarTypes } from "types/snackbar";

const FranchiseErrand = ({ id, index, removeItem }) => {
	const { data, pending: queryPending } = useQueryContext();
	const { enqueue } = useSnackbar();
	const [pending, setPending] = useState(false);
	const type = useRef("");

	const { setValue, ...methods } = useForm({
		defaultValues: {
			franchise: null,
			type: "APPROVE",
		},
		resolver: yupResolver(setFranchiseSchema),
	});

	const onSubmit = async ({ franchise }) => {
		const status = type.current;
		setPending(true);

		let result;
		if (status === "REJECT") {
			result = await api.rejectFranchiseErrandById({
				errandId: id,
			});
		} else {
			result = await api.approveFranchiseErrand({
				franchiseId: franchise.value,
				clientId: data?.clientId,
				errandId: id,
			});
		}

		if (result.ok) {
			enqueue(SnackbarTypes.Success, `Franchise.Handle.Success.${status}`);
			removeItem(index);
		} else {
			enqueue(SnackbarTypes.Error, `Franchise.Handle.Error.${status}`);
			setPending(false);
		}
	};

	if (queryPending || data === null) {
		return (
			<Box p={4}>
				<CircularProgressWithText text={"Hämtar ärende..."} />
			</Box>
		);
	}

	const onClick = (newType) => {
		type.current = newType;
		setValue("type", newType);
	};

	return (
		<Form
			methods={{ ...methods, setValue }}
			onSubmit={onSubmit}
			sx={{
				marginBottom: "16px",
				marginTop: "16px",
			}}
		>
			<Divider
				sx={{
					marginTop: "16px",
					marginBottom: "24px",
				}}
			/>
			<RightContainerCardHeader>Ansökning om att tillhöra ett varumärke</RightContainerCardHeader>
			<RightContainerCardItem title={"Önskat varumärke"}>
				<b>{data?.content?.name ?? "-"}</b>
			</RightContainerCardItem>
			<Divider
				sx={{
					marginTop: "24px",
					marginBottom: "16px",
				}}
			/>
			<ErrandCardFranchise />
			<Divider
				sx={{
					marginTop: "24px",
					marginBottom: "16px",
				}}
			/>
			<Box display={"flex"} justifyContent={"flex-end"}>
				<PendingButton
					type={"submit"}
					variant={"purple-outlined"}
					onClick={() => onClick("REJECT")}
					pending={pending && type.current === "REJECT"}
					disabled={pending}
					sx={{
						marginRight: "16px",
					}}
				>
					Avslå
				</PendingButton>
				<PendingButton
					type={"submit"}
					variant={"purple"}
					onClick={() => onClick("APPROVE")}
					disabled={pending}
					pending={pending && type.current === "APPROVE"}
				>
					Godkänn
				</PendingButton>
			</Box>
		</Form>
	);
};

export default FranchiseErrand;
