import { Dispatch, SetStateAction, useEffect } from "react";
import LeftContainer from "view/subcomponents/containers/left-container/left-container";
import { LeftMenuButton } from "view/subcomponents/containers/left-container/menu-items/left-menu-button";
import { LeftMenuInput } from "view/subcomponents/containers/left-container/menu-items/left-menu-input";
import { useQueryContext } from "view/subcomponents/providers/query-provider";

interface RedirectLeftContainerProps {
    setRoute: Dispatch<SetStateAction<number | string>>;
}

const RedirectLeftContainer = ({ setRoute }: RedirectLeftContainerProps) => {
    const { data } = useQueryContext();

    useEffect(() => {
        if (data) {
            setRoute(data[0].id);
        }
    }, [data]);
    return (
        <LeftContainer button={true}>
            {data &&
                data.map((d) => (
                    <LeftMenuButton key={d.id} onClick={() => setRoute(d.id)}>
                        {d.name}
                    </LeftMenuButton>
                ))}
        </LeftContainer>
    );
};
export default RedirectLeftContainer;
