import headers from "api/util/headers";
import apiInstance from "api/api-instance";

const ClientAPI = {
	updateClientStatus: (data) =>
		apiInstance.post("/api/client/updateStatus", data, headers()),
	updateClientFranchise: (data) =>
		apiInstance.post("/api/client/updateFranchise", data, headers()),
	updateClientQualifiedDecisionMaker: (data) =>
		apiInstance.post(
			"/api/client/updateQualifiedDecisionMaker",
			data,
			headers()
		),
	getClients: (data) =>
		apiInstance.get("/api/client/getClients", data, headers()),
	getClient: (data) =>
		apiInstance.get("/api/client/getClient", data, headers()),
	createNewClient: (data) =>
		apiInstance.post("/api/client/createNewClient", data, headers()),
	setClientAddonsFromAdmin: (data) =>
		apiInstance.post(
			"/api/client/setClientAddonsFromAdmin",
			data,
			headers()
		),
};

export default ClientAPI;
