import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
	spacing: 8,
	palette: {
		primary: {
			main: "#8854d0",
			background: "#ececec",
			home: "#131315",
		},

		secondary: {
			main: "#8854d0",
		},
		success: {
			main: "#38ada9",
		},

		error: {
			main: "#f76461",
		},
		orange: {
			main: "#f4b086",
		},
		errorPanel: {
			main: "rgba(244, 176, 134, 0.2)",
		},
	},
	tooltip: {
		footer: {
			color: "#8854d0",
			backgroundColor: "#fff",
		},
	},
	custom: {
		shadow: {
			error: "0 2px 11px 0 #f76461",
			success: "0 2px 11px 0 #38ada9",
			whiteShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
			primary: "0 2px 11px 0 #8854d0",
			info: "0 2px 11px 0 #8854d0",
		},
		footer: {
			backgroundColor: "#fff",
			color: "#8854d0",
			separator: "rgba(136, 84, 208, 0.3)",
		},
		snackbar: {
			error: "#fff",
			success: "#fff",
			primary: "rgba(244, 176, 134, 0.2)",
			info: "#fff",
		},
	},

	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					color: "#8854d0",

					label: {
						color: "rgba(136, 84, 208, 0.5)",
						":hover": {
							color: "#8854d0 !important",
						},
					},
				},
			},
		},
		MuiDivider: {
			variants: [
				{
					props: { variant: "default" },
					style: {
						marginTop: "32px",
						marginBottom: "32px",
						width: "100%",
					},
				},
			],
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: "32px",
					fontWeight: "bold",
					fontSize: "16px",
					textTransform: "none",
					height: "50px",
					padding: "0 16px",
					transition: "0.5s opacity",
					":disabled": {
						opacity: 0.5,
					},
				},
			},
			variants: [
				{
					props: { variant: "purple" },
					style: {
						color: "#fff",
						backgroundColor: "#8854d0",
						fontWeight: "bold",
						minWidth: "125px",
						":hover": {
							backgroundColor: "#a06ee6",
						},
						":disabled": {
							color: "#fff",
						},
					},
				},
				{
					props: { variant: "red" },
					style: {
						color: "#fff",
						backgroundColor: "#f76461",
						fontWeight: "bold",
						minWidth: "125px",
						":hover": {
							backgroundColor: "#f76461",
							opacity: 0.6,
						},
						":disabled": {
							color: "#fff",
						},
					},
				},
				{
					props: { variant: "green" },
					style: {
						color: "#fff",
						backgroundColor: "#38ada9",
						fontWeight: "bold",
						minWidth: "125px",
						":hover": {
							backgroundColor: "#38ada9",
							opacity: 0.6,
						},
						":disabled": {
							color: "#fff",
						},
					},
				},
				{
					props: { variant: "purple-outlined" },
					style: {
						color: "#8854d0",
						minWidth: "125px",

						border: "solid 2px",
						":hover": {
							backgroundColor: "#8854d0",
							color: "#fff",
						},
					},
				},
				{
					props: { variant: "black" },
					style: {
						color: "#fff",
						backgroundColor: "#000",
						fontWeight: "bold",
						height: "50px",
						minWidth: "125px",
						fontSize: "16px",
						":hover": {
							backgroundColor: "rgba(0,0,0,0.38)",
						},
					},
				},
				{
					props: { variant: "icon" },
					style: {
						width: "50px",
						textTransform: "none",
						color: "#fff",
						backgroundColor: "#000",
						padding: "32px",
						":hover": {
							backgroundColor: "rgba(0,0,0,0.38)",
						},
					},
				},
				{
					props: { variant: "white-shadow" },
					style: {
						boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
						color: "#000",
						backgroundColor: "#fff",
						":hover": {
							backgroundColor: "#d3d3d3",
						},
					},
				},
				{
					props: { variant: "purple-shadow" },
					style: {
						boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
						color: "#8854d0",
						backgroundColor: "#fff",

						":hover": {
							backgroundColor: "#d3d3d3",
						},
					},
				},
			],
		},
	},

	typography: {
		fontFamily: "Causten",
		button: {
			"textTransform:": "none",
			height: "50px",
		},
	},

	breakpoints: {
		values: {
			xs: 0,
			xsm: 450,
			sm: 600,
			smm: 800,
			md: 1000,
			lgg: 1200,
			lg: 1440,
			dashboardMobile: 630,
			dashboardSmall: 650,
			dashboardMedium: 930,
			oneColumn: 999.5,
		},
	},
	overrides: {
		MuiRadio: {
			root: {
				color: "#8854d0",
			},
			colorSecondary: {
				"&$checked": {
					color: "#8854d0",
				},
			},
		},
	},
});

export default defaultTheme;
