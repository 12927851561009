import React from "react";
import { Box, Grid } from "@mui/material";
import NavigateBackButton from "view/subcomponents/buttons/navigate-back-button";
import { useTranslation } from "react-i18next";
import Rectangle from "view/subcomponents/common/rectangle";
import { useLocation } from "react-router-dom";
import { TextContainer } from "view/subcomponents/common/text-container";
import { Children } from "types/children";
import { getImage } from "utils/image";
interface Props {
	button?: boolean;
	children?: Children;
}

const LeftContainer = ({ button, children }: Props) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	let filteredPathname = pathname.replace("/", "");

	return (
		<Grid
			item
			xs={5}
			md={4}
			lg={3}
			sx={{
				height: "100%",
				paddingRight: "32px",
				flexDirection: "column",
				color: "#fff",
				display: "flex",
			}}
		>
			{button && <NavigateBackButton />}
			<Box
				sx={{
					position: "relative",
					flex: "1 1 0",
					marginTop: button ? "32px" : "",
					backgroundColor: "#000",
					borderRadius: "30px",
					"::before": {
						content: '""',
						backgroundImage: `url(${getImage("kyc-guy.png")})`,
						filter: " saturate(1%)",
						height: "100%",
						width: "100%",
						borderRadius: "30px",
						backgroundSize: "cover",
						backgroundPosition: "75% top",
						color: "white",
						position: "absolute",
						opacity: "22%",
					},
				}}
			>
				<Rectangle
					sx={{
						left: "-1%",
					}}
				/>
				<Box
					sx={{
						padding: "20px 24px 20px 24px",
					}}
				>
					<TextContainer
						sx={{
							fontWeight: "bold",
							fontSize: "32px",
							lineHeight: "1.3",
						}}
					>
						{t(`Locations.${filteredPathname}.title`)}
					</TextContainer>
					<Box
						sx={{
							fontWeight: "bold",
							paddingTop: "24px",
							paddingBottom: "24px",
							fontSize: "18px",
							lineHeight: "1.17",
						}}
					>
						{t(`Locations.${filteredPathname}.subTitle`)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							".MuiButton-root": {
								marginBottom: "16px",
							},
						}}
					>
						{children}
					</Box>
				</Box>
			</Box>
		</Grid>
	);
};

export default LeftContainer;
