import { Paper } from "@mui/material";
import React from "react";
import { AutocompleteTheme } from "types/input";

export enum AutocompleteThemes {
	White = "white",
	Black = "black",
}

interface Props {
	theme: AutocompleteTheme;
	sx?: any;
}

export const AutocompletePaper = ({ theme, sx, ...rest }: Props) => {
	return (
		<Paper
			{...rest}
			sx={{
				".MuiAutocomplete-loading": {
					color: theme === AutocompleteThemes.White ? "#000" : "primary.main",
					textAlign: "center",
				},
				backgroundColor: theme === AutocompleteThemes.White ? "#fff" : "#000",
				color: theme === AutocompleteThemes.White ? "#000" : "primary.main",
				borderRadius: "16px",

				ul: {
					padding: " 0 4px 0 4px",
					color: "#fff",
					maxHeight: "30vh",
					overflow: "overlay",
					margin: "8px 4px 8px 0",
					"::-webkit-scrollbar": {
						backgroundClip: "padding-box",

						width: "5px !important",
						background: "rgba(136, 84, 208, 0.2); !important",
						borderRadius: "16px",
					},

					"::-webkit-scrollbar-thumb": {
						background: "#8854d0",
						borderRadius: "8px",
						height: "15px",
					},
				},
				li: {
					userSelect: "none",
					color: theme === AutocompleteThemes.White ? "#000" : "primary.main",
					"&:hover": {
						fontWeight: "bold",
						color: theme === AutocompleteThemes.White ? "#000" : "#fff",
						backgroundColor: theme === AutocompleteThemes.White ? "#fff" : "#8854d0 !important",
						borderRadius: "12px",
					},
				},
				...sx,
			}}
		/>
	);
};
