import * as personnummer from "personnummer.js";
import * as orgNrValidator from "se-orgnr-validator";

export const isValidOrgTrueFalse = (org) => {
	return orgNrValidator(org);
};

export const isOnlyDigits = (value) => /^\d+$/.test(value);

export const isValidOrg = (org) => {
	if (org) {
		return orgNrValidator(org);
	} else {
		return false;
	}
};

export const isValidEmail = (email) => {
	// eslint-disable-next-line
	let regExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
	return regExp.test(email);
};

export function isValidPnr(pnr) {
	if (pnr && pnr.length === 12) {
		if (/^[0-9]{8}[0]{4}/.test(pnr)) {
			// hantera pnr som slutar på 0000 som vi får från api
			return true;
		}
	} else if (pnr && pnr.length === 10) {
		if (/^[0-9]{10}/.test(pnr)) {
			return true;
		}
	} else if (pnr && pnr.length === 8) {
		if (/^[0-9]{8}/.test(pnr)) {
			return true;
		}
	}

	return personnummer.validate(pnr);
}

export const isValidStrictPnr = (pnr) => {
	return personnummer.validate(pnr);
};

export const isValidOrgOrPnr = (value) => {
	return isValidPnr(value) || isValidOrg(value);
};

export const isValidOrgOrStrictPnr = (value) => {
	return isValidStrictPnr(value) || isValidOrg(value);
};

export const isValidAutocomplete = (value) => {
	if (value === null || value === undefined || value === "") {
		return "Required";
	}
};

export function isValidPhoneNumber(number) {
	let replaced = number.replaceAll("-", "");

	let regex = new RegExp("^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$");
	return regex.test(replaced);
}

export function isUUID(uuid) {
	let s = "" + uuid;
	s = s.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
	return s !== null;
}

const defaultCountryValidation = (value) => {
	return value && value.length > 0;
};

export const getCompanyValidationByCountryCode = (code) => {
	switch (code) {
		case "SE":
			return isValidOrgOrStrictPnr;
		default:
			return defaultCountryValidation;
	}
};

export const getPersonValidationByCountryCode = (code) => {
	switch (code) {
		case "SE":
			return isValidOrgOrPnr;
		default:
			return defaultCountryValidation;
	}
};
