import React from "react";
import { Box } from "@mui/material";
import { TabPanel as MuiTabPanel } from "@mui/lab";

const TabPanel = ({ children, value }) => {
	return (
		<MuiTabPanel value={value}>
			<Box
				sx={{
					paddingTop: "16px",
					color: "#000",
					position: "relative",
				}}
			>
				{children}
			</Box>
		</MuiTabPanel>
	);
};

export default TabPanel;
