import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";

const RichTextEditor = ({ value, setValue, error }) => {
	const onChange = (value) => {
		setValue(value);
	};

	return (
		<Box
			sx={{
				".ql-toolbar": {
					border: "1px solid #f00",
					borderColor: error ? "error.main" : "primary.main",
					borderRadius: "8px 8px 0 0",
				},
				".ql-container": {
					border: "1px solid #f00",
					borderColor: error ? "error.main" : "primary.main",
					borderTop: 0,
					borderRadius: "0 0 8px 8px",
					height: "250px",
					whiteSpace: "normal",
					wordWrap: "anywhere",
				},
			}}
		>
			<ReactQuill value={value} onChange={onChange} placeholder={"Skriv notis..."} />
		</Box>
	);
};

export default RichTextEditor;
