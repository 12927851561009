import { useNewClientContext } from "view/subcomponents/providers/new-client-provider";
import { useForm } from "react-hook-form";
import { Form } from "view/subcomponents/form";
import { Divider, Grid } from "@mui/material";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import React, { useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { newClientInvoiceSchema } from "utils/validation/schema/new-client/new-client-invoice-schema";
import { KycAutocomplete } from "view/subcomponents/input/autocomplete/kyc-autocomplete";
import { useTranslation } from "react-i18next";
import { PaymentMethods, PaymentPeriods } from "config/constants/common";

const NewClientInvoiceInfo = () => {
	const { t } = useTranslation();
	const { onSubmit, wizardData, page, disablePagesAhead } = useNewClientContext();
	const hasDisabled = useRef(false);
	const methods = useForm({
		defaultValues: {
			page: 2,
			email: wizardData?.[page]?.data?.email,
			emailCopy: wizardData?.[page]?.data?.emailCopy,
			county: wizardData?.[page]?.data?.county,
			address: wizardData?.[page]?.data?.address,
			coAddress: wizardData?.[page]?.data?.coAddress,
			postalCode: wizardData?.[page]?.data?.postalCode,
			paymentPeriod: {
				value: PaymentPeriods.Monthly,
				label: t(`paymentPeriods.${PaymentPeriods.Monthly}`),
			},
			paymentMethod: {
				value: PaymentMethods.Invoice,
				label: t(`paymentMethods.${PaymentMethods.Invoice}`),
			},
		},
		resolver: yupResolver(newClientInvoiceSchema),
		mode: "all",
		shouldFocusError: true,
		reValidateMode: "onChange",
		criteriaMode: "all",
	});

	const { isDirty } = methods.formState;

	useEffect(() => {
		if (isDirty && hasDisabled.current === false) {
			disablePagesAhead();
			hasDisabled.current = true;
		}
	}, [isDirty, disablePagesAhead]);

	return (
		<Form
			methods={methods}
			onSubmit={onSubmit}
			sx={{
				marginBottom: "16px",
				marginTop: "16px",
			}}
			id={"new-client-form"}
		>
			<Grid container columnSpacing={4} rowSpacing={1}>
				<Grid item xs={6}>
					<KycFormInput name={"email"} inputName={"emailInvoice"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"emailCopy"} inputName={"emailCopy"} />
				</Grid>

				<Grid item xs={6}>
					<KycFormInput name={"coAddress"} placeholder={"C/O"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"address"} placeholder={"Adress"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"county"} placeholder={"Ort"} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={"postalCode"} placeholder={"Postnummer"} />
				</Grid>
				<Grid item xs={6}>
					<KycAutocomplete
						name={"paymentMethod"}
						options={Object.values(PaymentMethods).map((value) => ({
							value: value,
							label: t(`paymentMethods.${value}`),
						}))}
						placeholder={"Betalningsmetod"}
						disabled={true}
					/>
				</Grid>
				<Grid item xs={6}>
					<KycAutocomplete
						name={"paymentPeriod"}
						options={Object.values(PaymentPeriods).map((value) => ({
							value: value,
							label: t(`paymentPeriods.${value}`),
						}))}
						disabled={true}
					/>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>
			</Grid>
		</Form>
	);
};

export default NewClientInvoiceInfo;
