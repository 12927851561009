import { Form } from 'view/subcomponents/form';
import { Box, Grid } from '@mui/material';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import { KycAutocomplete } from 'view/subcomponents/input/autocomplete/kyc-autocomplete';
import { Addons } from 'config/constants';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import React, { useState } from 'react';
import api from 'api/api';
import { SnackbarTypes } from 'types/snackbar';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { paymentModelSchema } from 'utils/validation/schema/payment-model-schema';
import { useTranslation } from 'react-i18next';

type FormData = any;

interface Props {
	index: number;
	onClose: () => void;
	getData: () => FormData;
}

// @ts-ignore
const options = ['Unlimited', ...Array(100).keys()];
const ChangePaymentForm = ({ index, onClose, getData }: Props) => {
	const [pending, setPending] = useState(false);
	const { updateByIndex, addItem } = useQueryContext();
	const { enqueue } = useSnackbar();
	const { t } = useTranslation();

	const getDefaultValue = (key: string, defaultValue: any) => {
		const data = getData();
		if (data) {
			if (key === 'kycPerClient') {
				if (!isNaN(data[key])) {
					return {
						value: data[key],
						label: data[key],
					};
				} else {
					return {
						label: t(`KycPerClient.${data[key]}`),
						value: data[key],
					};
				}
			} else if (key === 'kycPerUser') {
				if (!isNaN(data[key])) {
					return {
						value: data[key],
						label: data[key],
					};
				} else {
					return {
						label: t(`KycPerUser.${data[key]}`),
						value: data[key],
					};
				}
			} else if (key === 'addons') {
				return data?.addons?.map(type => {
					return {
						value: type,
						label: `${t(`AddonTypes.${type}`)}`,
					};
				});
			} else {
				const value = data[key] ?? defaultValue;
				if (!isNaN(value)) {
					return parseInt(value);
				} else {
					return value;
				}
			}
		} else {
			return defaultValue;
		}
	};

	const { setValue, ...methods } = useForm({
		defaultValues: {
			basePrice: getDefaultValue('basePrice', ''),
			includedUsers: getDefaultValue('includedUsers', ''),
			kycPricePerson: getDefaultValue('kycPricePerson', 0),
			kycPriceCompany: getDefaultValue('kycPriceCompany', 0),
			userPrice: getDefaultValue('userPrice', 0),
			kycPerClient: getDefaultValue('kycPerClient', null),
			kycPerUser: getDefaultValue('kycPerUser', null),
			kycPrice: getDefaultValue('kycPrice', ''),
			name: getDefaultValue('name', ''),
			addons: getDefaultValue('addons', null),
			id: getDefaultValue('id', null),
		},
		resolver: yupResolver(paymentModelSchema),
	});

	const onSubmit = async values => {
		setPending(true);

		const data = getData();

		let submitValues = {
			...values,
			kycPerClient: values?.kycPerClient?.value,
			kycPerUser: values?.kycPerUser?.value,
			addons: values?.addons?.map(x => x.value) ?? [],
		};

		if (index >= 0) {
			await api
				.updatePaymentModel({
					...submitValues,
					id: data?.id,
				})
				.then(res => {
					setPending(false);
					if (res.ok) {
						enqueue(SnackbarTypes.Success, 'Payments.Update.Success');

						updateByIndex(index, submitValues);
					} else {
						enqueue(SnackbarTypes.Error, 'Payments.Update.Error');
					}
				});
		} else {
			await api.createPaymentModel(submitValues).then(res => {
				setPending(false);
				if (res.ok) {
					enqueue(SnackbarTypes.Success, 'Payments.Create.Success');

					addItem(res.data);
					onClose();
				} else {
					enqueue(SnackbarTypes.Error, 'Payments.Update.Error');
				}
			});
		}
	};

	const getOptions = () => {
		return options.map(option => {
			if (option === 'Unlimited') {
				return {
					value: option,
					label: 'Obegränsat',
				};
			} else {
				return {
					value: option,
					label: option.toString(),
				};
			}
		});
	};

	const autoCompleteOptions = getOptions();

	return (
		<Form
			onSubmit={onSubmit}
			methods={{ ...methods, setValue }}
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
			}}
		>
			<Grid
				container
				spacing={2}
				sx={{
					marginTop: '16px',
				}}
			>
				<Grid item xs={12}>
					<KycFormInput name={'name'} inputName={'paymentModelName'} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={'basePrice'} type={'number'} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={'kycPrice'} type={'number'} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={'kycPricePerson'} type={'number'} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={'kycPriceCompany'} type={'number'} />
				</Grid>
				<Grid item xs={6}>
					<KycFormInput name={'userPrice'} type={'number'} />
				</Grid>

				<Grid item xs={6}>
					<KycFormInput name={'includedUsers'} type={'number'} />
				</Grid>
				<Grid item xs={6}>
					<KycAutocomplete
						name={'kycPerClient'}
						options={autoCompleteOptions}
					/>
				</Grid>
				<Grid item xs={6}>
					<KycAutocomplete name={'kycPerUser'} options={autoCompleteOptions} />
				</Grid>
				<Grid item xs={6}>
					<KycAutocomplete
						multiple
						name={'addons'}
						options={Object.values(Addons).map(type => {
							return {
								value: type,
								label: `${t(`AddonTypes.${type}`)}`,
							};
						})}
					/>
				</Grid>
			</Grid>

			<Box
				sx={{
					marginTop: '16px',
					borderTop: '1px solid',
					borderColor: 'divider',
					paddingTop: '16px',
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end',
				}}
			>
				<PendingButton pending={pending} variant={'purple'} type={'submit'}>
					Spara
				</PendingButton>
			</Box>
		</Form>
	);
};

export default ChangePaymentForm;
