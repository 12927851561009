import React from 'react';
import { Box } from '@mui/material';
import { SingleUserStatistics } from '../../../../../../../types/clients';

interface Props {
	user: SingleUserStatistics;
}
export const AllUsersTableRow = ({ user }: Props) => {
	return (
		<Box
			component={'tr'}
			sx={{
				marginTop: '8px',
				cursor: 'pointer',
				userSelect: 'none',
				':hover': {
					backgroundColor: '#D3D3D3FF',
				},
			}}
		>
			<td>{user.name}</td>
			<td>{user.notCompletedKyc} st</td>
			<td>{user.completedKyc} st</td>
			<td>{user.approved} st</td>
			<td>{user.rejected} st</td>
			<td>{user.businessNotAllowed} st</td>
			<td>{user.lastActivity}</td>
		</Box>
	);
};
