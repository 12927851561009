import { Grid } from "@mui/material";
import React from "react";

const InvoicesInfoHeader = () => {
	return (
		<Grid
			container
			sx={{
				paddingTop: "8px",
				paddingBottom: "8px",
				paddingLeft: "32px",
				paddingRight: "32px",
				backgroundColor: "#fff",
				boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
				borderRadius: "16px",
				display: "flex",
				alignItems: "center",
				fontWeight: "bold",
				color: "primary.main",
				marginBottom: "16px",
			}}
		>
			<Grid item xs={3}>
				Kund
			</Grid>
			<Grid item xs={2}>
				Organisationsnummer
			</Grid>
			<Grid item xs={3}>
				Antal slutförda KYC för gällande år
			</Grid>
			<Grid item xs={4}>
				Aktiva användare föregående månad
			</Grid>
		</Grid>
	);
};

export default InvoicesInfoHeader;
