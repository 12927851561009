import React from 'react';
import LeftContainer from 'view/subcomponents/containers/left-container/left-container';
import LeftContainerAutocompleteBusinessarea from 'view/subcomponents/input/autocomplete/templates/left-container-autocmplete-businessarea';
import { LeftMenuInput } from 'view/subcomponents/containers/left-container/menu-items/left-menu-input';
import { LeftDateMenuRange } from 'view/subcomponents/containers/left-container/menu-items/left-menu-date-range';
import LeftContainerAutocompletePaymentModel from '../../subcomponents/input/autocomplete/templates/left-container-autocmplete-paymentModel';

const AgreementsLeftContainer = () => {
	return (
		<LeftContainer button={true}>
			<LeftMenuInput field={'name'} />
			<LeftMenuInput field={'identityNumber'} />
			<LeftMenuInput field={'clientNumber'} />
			<LeftContainerAutocompleteBusinessarea />
			<LeftContainerAutocompletePaymentModel />
			<LeftDateMenuRange field={'dateRange'} />
		</LeftContainer>
	);
};

export default AgreementsLeftContainer;
