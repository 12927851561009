import React, { useState } from "react";
import { InputBase } from "@mui/material";

interface Props {
	[x: string]: any;
}

export const LeftMenuInputBase = ({ padding, InputProps, animateHeight, inputProps, sx, elRef, ...rest }: Props) => {
	const [active, setActive] = useState(false);

	const onBlur = () => {
		if (inputProps && inputProps.onBlur) {
			inputProps.onBlur();
		}
		setActive(false);
	};

	const onFocus = () => {
		if (inputProps && inputProps.onFocus) {
			inputProps.onFocus();
		}
		setActive(true);
	};

	return (
		<InputBase
			inputRef={InputProps?.ref}
			ref={elRef}
			autoComplete={"off"}
			sx={{
				fontSize: "18px",
				marginBottom: "8px",
				height: active && animateHeight ? "65px" : "50px",
				transition: "height 0.3s ease",
				width: "100%",
				backgroundColor: active ? "primary.main" : "transparent",
				color: active ? "#fff !important" : "primary.main",
				textAlign: "center",
				fontWeight: "bold",
				paddingTop: "4px",

				border: "2px solid",
				borderColor: "primary.main",
				borderRadius: "24px",

				"input::placeholder": {
					opacity: 1,

					textAlign: padding?.textAlign ?? "center",
				},

				"input.Mui-disabled": {
					color: active ? "#fff !important" : "primary.main",
					WebkitTextFillColor: active ? "#fff !important" : "primary.main",
				},

				input: {
					textOverflow: "ellipsis",

					textAlign: "center",
					cursor: active ? "text" : "pointer",
					color: active ? "#fff !important" : "primary.main",
					height: "50px",
				},

				":hover": {
					cursor: active ? "text" : "pointer",
					backgroundColor: "primary.main",
					color: "#fff",
					opacity: active ? 0.9 : 1,

					input: {
						color: "#fff",
					},
				},
				...sx,
			}}
			inputProps={{
				...inputProps,
				onFocus: onFocus,
				onBlur: onBlur,
			}}
			endAdornment={InputProps?.endAdornment}
			{...rest}
		/>
	);
};

LeftMenuInputBase.defaultProps = {
	animateHeight: true,
};
