import RightContainer from 'view/subcomponents/containers/right-container/right-container';
import React, { useState } from 'react';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import PaymentsCard from 'view/components/payments/subcomponents/payments-card';
import api from 'api/api';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import ChangePaymentModelDialog from 'view/components/payments/subcomponents/dialog/change-payment-model-dialog';
import { SnackbarTypes } from 'types/snackbar';
import { HttpCodes } from 'api/util/http-codes';

const PaymentsRightContainer = () => {
	const { data, setData } = useQueryContext();
	const { enqueue } = useSnackbar();
	const [index, setIndex] = useState(-1);

	const onDelete = async (id, index) => {
		await api.deletePaymentModel({ id: id }).then(res => {
			if (res.ok) {
				const filteredData = data.filter((data, i) => {
					return index !== i;
				});
				enqueue(SnackbarTypes.Success, 'Payments.Delete.Success');

				setData(filteredData);
			} else {
				if (res.status === HttpCodes.Conflict) {
					enqueue(SnackbarTypes.Error, 'Payments.Delete.Error');
				}
			}
		});
	};

	const getData = () => {
		if (index === -1) {
			return null;
		} else {
			return data[index];
		}
	};

	return (
		<RightContainer displayTopInfo={true} onClick={() => setIndex(-2)}>
			{data != null &&
				data?.map((data, index) => (
					<PaymentsCard
						onDelete={onDelete}
						payment={data}
						key={index}
						index={index}
						onChange={() => setIndex(index)}
					/>
				))}

			<ChangePaymentModelDialog
				open={index !== -1}
				onClose={() => setIndex(-1)}
				getData={getData}
				index={index}
			/>
		</RightContainer>
	);
};
export default PaymentsRightContainer;
