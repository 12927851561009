import React, { memo, useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "view/subcomponents/input/checkbox/checkbox";

interface Props {
	name: string;
	label?: any;
	customLabel?: any;
	customOnChange?: any;
	labelProps?: any;
	labelSx?: any;
	[x: string]: any;
}

export const KycCheckbox = memo(({ labelProps, labelSx, name, label, customLabel, customOnChange, ...rest }: Props) => {
	return (
		<Controller
			defaultValue={false}
			name={name}
			render={({ field, fieldState, formState }) => {
				const { invalid } = fieldState;

				return (
					<Checkbox
						formState={formState}
						name={name}
						invalid={invalid ?? false}
						field={field}
						label={label}
						labelSx={labelSx}
						labelProps={labelProps}
						customOnChange={customOnChange}
						customLabel={customLabel}
						{...rest}
					/>
				);
			}}
		/>
	);
});
