import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import AgreementCardFooter from 'view/components/agreements/subcomponents/agreement-card-footer';
import { Form } from 'view/subcomponents/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { agreementInvoiceSchema } from 'utils/validation/schema/agreement-invoice-schema';
import { useGetUpdateAgreement } from 'store/agreement/use-get-agreement';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { SnackbarTypes } from 'types/snackbar';
import api from 'api/api';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'store/query-keys';

const AgreementCardInvoice = ({ agreementId }) => {
	const { setValue, ...methods } = useForm({
		defaultValues: {
			address: '',
			postalCode: '',
			county: '',
			emailCopy: '',
			email: '',
			coAddress: '',
			referenceName: '',
		},
		resolver: yupResolver(agreementInvoiceSchema),
	});

	const getAgreement = useGetUpdateAgreement(agreementId);
	const [pending, setPending] = useState(false);
	const { enqueue } = useSnackbar();
	const queryClient = useQueryClient();
	const onSubmit = async values => {
		setPending(true);

		await api.updateAgreement({ id: agreementId, data: values }).then(res => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, 'Agreement.Update.Success');
				queryClient.invalidateQueries({ queryKey: [queryKeys.getAgreement] });
			} else {
				enqueue(SnackbarTypes.Error, 'Agreement.Update.Error');
			}
			setPending(false);
		});
	};
	useEffect(() => {
		if (getAgreement.query.isLoading || getAgreement.query.isError) {
			return;
		}

		if (getAgreement.query.isSuccess) {
			const { paymentInfo } = getAgreement.agreement;

			const {
				email,
				address,
				county,
				coAddress,
				postalCode,
				emailCopy,
				referenceName,
			} = paymentInfo;
			setValue('address', address ?? '');
			setValue('county', county ?? '');
			setValue('coAddress', coAddress ?? '');
			setValue('postalCode', postalCode ?? '');
			setValue('email', email);
			setValue('emailCopy', emailCopy ?? '');
			setValue('referenceName', referenceName ?? '');
		}
	}, [getAgreement, setValue]);

	return (
		<TabPanel
			value={'3'}
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					fontSize: '20px',
				}}
			>
				Redigera fakturauppgifter i formuläret nedan.
			</Box>
			<Form methods={{ ...methods, setValue }} onSubmit={onSubmit}>
				<Grid container>
					<Grid item xs={6}>
						<KycFormInput
							name={'address'}
							placeholder={'Fakturadress'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'coAddress'}
							placeholder={'C/O'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'postalCode'}
							placeholder={'Postnummer'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'county'}
							placeholder={'Ort'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'email'}
							placeholder={'E-post för faktura'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'emailCopy'}
							placeholder={'E-post kopia för faktura'}
							disabled={pending}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycFormInput
							name={'referenceName'}
							placeholder={'Referensperson på faktura'}
							disabled={pending}
						/>
					</Grid>

					<AgreementCardFooter pending={pending} />
				</Grid>
			</Form>
		</TabPanel>
	);
};

export default AgreementCardInvoice;
