import React from "react";
import { Box } from "@mui/material";
import { useScreenType } from "view/subcomponents/hooks/use-screen-type";
import { Children } from "types/children";

interface Props {
	children: Children;
	sx?: any;
	url?: any;
}

export const ScrollableWrapper = ({ children, sx, url }: Props) => {
	const { oneColumn } = useScreenType();

	const image = () => {
		if (oneColumn && url) {
			return {
				backgroundColor: "#000",
				borderRadius: "30px",
				"::before": {
					content: '""',
					borderRadius: "30px",
					backgroundImage: `url(${url})`,
					backgroundSize: "cover",
					height: "100%",
					width: "100%",
					opacity: "22%",
					position: "absolute",
					filter: "saturate(1%)",
					backgroundPosition: "75% top",
					color: "white",
				},
			};
		} else {
			return {};
		}
	};

	return (
		<Box
			sx={{
				height: "100%",
				flexDirection: "column",
				display: "flex",
				position: "relative",
				...sx,
				...image(),
			}}
		>
			{children}
		</Box>
	);
};
