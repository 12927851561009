import React, { useState } from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import InvoicesInfo from "view/components/invoices/subcomponents/invoices-info";
import { ScrollableContent } from "view/subcomponents/scroll/scrollable-content";
import { ScrollableWrapper } from "view/subcomponents/scroll/scrollable-wrapper";
import { ScrollButtons } from "view/subcomponents/scroll/scroll-buttons";
import InvoicesCard from "view/components/invoices/subcomponents/invoices-card";
import InvoicesInfoHeader from "view/components/invoices/subcomponents/invoices-info-header";

const InvoicesRightContainer = () => {
	const [clients, setClients] = useState([]);
	const { data } = useQueryContext();

	const onChange = (value, checked) => {
		if (checked) {
			if (clients.length === 0) {
				setClients([value]);
			} else {
				let values = [...clients, value];
				// @ts-ignore
				const unique = [...new Map(values.map((x) => [x, x])).values()];
				setClients(unique);
			}
		} else {
			let filter = clients.filter((x) => x !== value);
			setClients(filter);
		}
	};

	return (
		<RightContainer
			fullHeight={true}
			disableBackground={true}
			hasScroll={false}
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
			topContent={
				<>
					<InvoicesInfo clients={clients} />
					<InvoicesInfoHeader />
				</>
			}
		>
			<ScrollableWrapper
				sx={{
					marginTop: "8px",
					flex: "1 1 0",
				}}
			>
				<ScrollButtons items={data ?? []}>
					<ScrollableContent>
						{data?.map((invoice, index) => (
							<InvoicesCard key={index} invoice={invoice} index={index} onClientsChange={onChange} />
						))}
					</ScrollableContent>
				</ScrollButtons>
			</ScrollableWrapper>
		</RightContainer>
	);
};

export default InvoicesRightContainer;
