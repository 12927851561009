import headers from "api/util/headers";
import apiInstance from "api/api-instance";

const AgreementAPI = {
	updateAgreement: (data) => apiInstance.post("/api/agreement/updateAgreement", data, headers()),
	updateClientPaymentModel: (data) => apiInstance.post("/api/agreement/updateClientPaymentModel", data, headers()),
	getAgreement: (data) => apiInstance.get("/api/agreement/getAgreement", data, headers()),
	getAgreements: (data) => apiInstance.get("/api/agreement/getAgreements", data, headers()),
	getAgreementPDF: (data) => apiInstance.get("/api/agreement/getAgreementPDF", data, headers(true)),
};

export default AgreementAPI;
