import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Header } from "view/components/main/header/header";
import { isAuthenticated } from "utils/user";
import { useLocation } from "react-router-dom";

const RouterWrapper = ({ children }) => {
	const location = useLocation();
	const [authenticated, setAuthenticated] = useState(false);

	useEffect(() => {
		setAuthenticated(isAuthenticated());
	}, [location]);

	if (!authenticated) {
		return children;
	}

	return (
		<Box
			sx={{
				height: "100%",
				width: "100%",
				backgroundColor: "primary.background",
				display: "flex",
				justifyContent: "center",
				overflow: "scroll",
				msOverflowStyle: "none",
				"::-webkit-scrollbar": {
					display: "none",
				},
			}}
		>
			<Box
				sx={{
					maxWidth: "1600px",
					minWidth: "350px",
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					padding: {
						xs: "8px",
						md: "8px 40px 8px 40px",
						lg: "24px 88px 12px 88px",
					},
				}}
			>
				<Header />

				<Box height={"100%"} width={"100%"}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default RouterWrapper;
