import apiInstance from "api/api-instance";
import headers from "api/util/headers";

const NotificationAPI = {
	sendGlobalNotification: (data) => apiInstance.post("/api/notification/sendGlobalNotification", data, headers()),
	getAdminNotifications: (data) => apiInstance.get("/api/notification/getAdminNotifications", data, headers()),
	updateNotificationStatus: (data) => apiInstance.post("/api/notification/updateNotificationStatus", data, headers()),
	updateGlobalNotification: (data) => apiInstance.post("/api/notification/updateGlobalNotification", data, headers()),
};

export default NotificationAPI;
