import React from 'react';
import { Box } from '@mui/material';
import { ShowMoreButton } from 'view/subcomponents/buttons/show-more-button';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { Children } from 'types/children';
import { Reference } from 'types/reference';

interface Props {
	sx?: any;
	elRef?: Reference;
	onClick?: any;
	children: Children;
	open?: boolean;
	setOpen?: (open: boolean) => void;
}

const RightContainerCard = ({ sx, elRef, onClick, children, open, setOpen }: Props) => {
	const { pending } = useQueryContext();

	return (
		<Box
			ref={elRef}
			sx={{
				marginBottom: '24px',
				position: 'relative',
				display: 'flex',
				backgroundColor: '#fff',
				borderRadius: '24px',
				color: 'primary.main',
				padding: '24px',
				...sx,
			}}
			onClick={onClick}
		>
			{children}
			{!pending && open !== undefined && (
				<ShowMoreButton
					open={open}
					setOpen={setOpen}
					sx={{
						position: 'absolute',
						color: 'primary.main',
						bottom: '-16px',
						left: 'calc(50% - 25px)',
					}}
				/>
			)}
		</Box>
	);
};

export default RightContainerCard;
