import { Box } from "@mui/material";
import React from "react";

interface Props {
	value: boolean;
}

const SwitchThumb = ({ value }: Props) => {
	return (
		<Box
			sx={{
				backgroundColor: "#fff",
				width: "38px",
				height: "38px",
				borderRadius: "20px",
				transform: !value ? "translateX(0px)" : "translateX(73px)",
				transition: "all 0.1s ease-in-out",

				":hover": {
					backgroundColor: "#d8d8d8",
				},
			}}
		/>
	);
};

export default SwitchThumb;
