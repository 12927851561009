import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { TextContainer } from "view/subcomponents/common/text-container";

interface Props {
	outerSx?: any;
	sx?: any;
	fontSize?: any;
	text: string;
	[x: string]: any;
}

export const CircularProgressWithText = ({ outerSx, sx, fontSize, text, ...rest }: Props) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			width={"100%"}
			flexDirection={"column"}
			alignItems={"center"}
			sx={{
				...outerSx,
			}}
		>
			<TextContainer
				mb={2}
				fontSize={fontSize ?? 20}
				sx={{
					marginBottom: "16px",
					fontSize: fontSize ?? 20,
					fontWeight: "bold",
					textAlign: "center",
					...sx,
				}}
			>
				{text}
			</TextContainer>
			<Box mb={1}>
				<CircularProgress {...rest} />
			</Box>
		</Box>
	);
};
