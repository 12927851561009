import React from "react";
import { Box } from "@mui/material";
import { Children } from "types/children";

interface Props {
	children: Children;
	sx?: any;
	[x: string]: any;
}

export const StatusBox = ({ children, sx, ...rest }: Props) => {
	return (
		<Box
			sx={{
				borderRadius: "4px",
				border: "solid 2px",
				fontSize: "14px",
				textAlign: "center",
				padding: "2px 8px 2px 8px",
				fontWeight: "bold",
				...sx,
			}}
			{...rest}
		>
			{children}
		</Box>
	);
};
