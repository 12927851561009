import React, { useState } from "react";
import KycLeftContainer from "./kyc-left-container";
import KycRightContainer from "./kyc-right-container";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import api from "api/api";
import { isUUID } from "utils/validation/validation";

const Kyc = () => {
	//this is to prevent initial fetch, somewhat lazy solution but it works
	const [disabled, setDisabled] = useState<boolean>(true);

	const validation = (data) => {
		return isUUID(data?.id);
	};

	return (
		<SectionWrapper method={api.getKycById} disabled={disabled} validation={validation}>
			<KycLeftContainer setDisabled={setDisabled} />
			<KycRightContainer />
		</SectionWrapper>
	);
};

export default Kyc;
