import React, { useEffect, useRef, useState } from "react";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateRangeOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { InputEndAdornment } from "view/subcomponents/input/end-adornment/input-end-adornment";
import { LeftMenuInputBase } from "view/subcomponents/containers/left-container/menu-items/left-menu-input-base";
import { SectionFieldKey, ISection, useSectionContext } from "view/subcomponents/providers/section-provider";
import { DateRange } from "config/initial-values/left-menu-initial-state";
import { Box } from "@mui/material";
import moment from "moment";

interface LeftDateMenuRangeProps {
	field: SectionFieldKey;
}

enum OpenState {
	None = 0,
	Left,
	Right,
}

export const LeftDateMenuRange = ({ field }: LeftDateMenuRangeProps) => {
	const { updateFilter, getDefaultValue } = useSectionContext() as ISection;

	const [open, setOpen] = useState(OpenState.None);
	const [value, setValue] = useState(getDefaultValue(field) as DateRange);

	const { t } = useTranslation();
	const initialRef = useRef(false);

	const onChangeFrom = (date) => {
		const dat = new Date(date);
		const from = {
			day: dat.getDate(),
			month: dat.getMonth(),
			year: dat.getFullYear(),
		};
		const newRange = { ...value, from: from };
		updateFilter(field, newRange);
		setValue(newRange);
		setOpen(OpenState.None);
	};

	const onChangeTo = (date) => {
		const dat = new Date(date);
		const to = {
			day: dat.getDate(),
			month: dat.getMonth(),
			year: dat.getFullYear(),
		};
		const newRange = { ...value, to: to };
		updateFilter(field, newRange);
		setValue(newRange);
		setOpen(OpenState.None);
	};

	useEffect(() => {
		if (initialRef.current === false) {
			initialRef.current = true;
		}
	}, [field, updateFilter, value]);

	const padZeros = (num: number, stringLength: number) => {
		var str = num.toString();
		while (str.length < stringLength) {
			str = "0" + str;
		}
		return str;
	};

	const toValue = value.to ? `${value.to.year}-${padZeros(value.to.month + 1, 2)}-${padZeros(value.to.day, 2)}` : "";
	const fromValue = value.from ? `${value.from.year}-${padZeros(value.from.month + 1, 2)}-${padZeros(value.from.day, 2)}` : "";

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				width: "100%",
				height: "100%",
				boxSizing: "border-box",
				padding: "0",
			}}
		>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<DesktopDatePicker
					open={open === OpenState.Left}
					onOpen={() => setOpen(OpenState.Left)}
					onClose={() => setOpen(OpenState.None)}
					maxDate={toValue ? moment(toValue).toDate() : undefined}
					mask={"____-__-__"}
					inputFormat={"YYYY-MM-DD"}
					views={["month", "day"]}
					openTo={"day"}
					value={fromValue}
					onChange={onChangeFrom}
					renderInput={({ inputRef, inputProps: { value } }) => {
						return (
							<LeftMenuInputBase
								value={value}
								placeholder={t(`Placeholder.${field}.from`)}
								endAdornment={
									<InputEndAdornment
										icon={DateRangeOutlined}
										open={true}
										onClick={() => setOpen(OpenState.Left)}
										sx={{
											width: "24px",
											height: "24px",
										}}
									/>
								}
								elRef={inputRef}
							/>
						);
					}}
				/>
				<DesktopDatePicker
					open={open === OpenState.Right}
					onOpen={() => setOpen(OpenState.Right)}
					onClose={() => setOpen(OpenState.None)}
					minDate={fromValue ? moment(fromValue).toDate() : undefined}
					mask={"____-__-__"}
					inputFormat={"YYYY-MM-DD"}
					views={["month", "day"]}
					openTo={"day"}
					value={toValue}
					onChange={onChangeTo}
					renderInput={({ inputRef, inputProps: { value } }) => {
						return (
							<LeftMenuInputBase
								value={value}
								placeholder={t(`Placeholder.${field}.to`)}
								endAdornment={
									<InputEndAdornment
										icon={DateRangeOutlined}
										open={true}
										onClick={() => setOpen(OpenState.Right)}
										sx={{
											width: "24px",
											height: "24px",
										}}
									/>
								}
								elRef={inputRef}
							/>
						);
					}}
				/>
			</LocalizationProvider>
		</Box>
	);
};
