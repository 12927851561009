import { SessionManager } from "view/subcomponents/session";
import { Route } from "react-router-dom";

const SessionRoute = ({ ...rest }) => {
	return (
		<SessionManager>
			<Route {...rest} />
		</SessionManager>
	);
};

export default SessionRoute;
