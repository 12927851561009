import { Box, Button, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { endOfDay, format, lastDayOfMonth } from 'date-fns';
import { DateFormats } from 'config/constants/date';
import { ProfileTypes } from './profile-client-addons';

interface ProfileClientAddonsMenuProps {
	profileType: ProfileTypes;
	addons: any;
	onClick: (profileType: ProfileTypes, type: string, index: number) => void;
	disabled: boolean;
}

const ProfileClientAddonsMenu = ({
	profileType,
	addons,
	onClick,
	disabled,
}: ProfileClientAddonsMenuProps) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				flex: '1 1 50%',
			}}
		>
			<Box
				sx={{
					boxShadow: '0 4px 18px 0 rgba(32, 73, 136, 0.2)',
					borderRadius: '16px',
					padding: '16px 16px',
					minHeight: '200px',
				}}
			>
				<Box
					sx={{
						fontWeight: 'bold',
						fontSize: '22px',
						borderBottom: '1px solid',
						borderColor: 'divider',
						marginBottom: '8px',
						paddingBottom: '8px',
					}}
				>
					{t(`CompanyProfile.${profileType}.Title`)}
				</Box>
				<Box>
					{addons?.length === 0 ? (
						<Box
							sx={{
								textAlign: 'center',
								fontWeight: 'bold',
								fontSize: '22px',
							}}
						>
							{t(`CompanyProfile.${profileType}.EmptyText`)}
						</Box>
					) : (
						<TransitionGroup>
							{addons.map(
								(
									{
										type,
										price,
										active,
										existsInInitial,
										active_until,
										...rest
									},
									i,
								) => {
									const isActive = active || profileType === 'Available';

									return (
										<Collapse key={i} in={true} timeout={250} unmountOnExit>
											<Box
												key={i}
												sx={{
													width: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														minHeight: '40px',
														display: 'flex',
														flexDirection: 'row',
														width: '100%',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												>
													<Box
														sx={{
															flex: '1 1 0',
															display: 'flex',
															alignItems: 'center',
														}}
													>
														<Box
															sx={{
																fontWeight: 'bold',
																fontSize: '18px',
																paddingRight: '8px',
																marginRight: '8px',
																borderRight: '1px solid',
																borderColor: 'primary.main',
															}}
															component={'span'}
														>
															{t(`AddonTypes.${type}`)}
														</Box>
														<Box
															component={'span'}
															sx={{
																fontStyle: 'oblique',
															}}
														>
															{price}kr / månad
														</Box>
													</Box>

													{!isActive && (
														<Box
															sx={{
																backgroundColor: 'rgba(244, 176, 134, 0.4)',
																borderRadius: '8px',
																padding: '0 8px',
																color: 'primary.main',
																opacity: 1,
															}}
														>
															{active_until
																? `Avslutas: ${format(
																		endOfDay(
																			lastDayOfMonth(new Date(active_until)),
																		),
																		DateFormats.YYYYMMDDHHMM,
																  )}`
																: 'Avslutas vid månadens slut'}
														</Box>
													)}
												</Box>

												<Box
													sx={{
														width: '100%',
														flex: '1 1 0',
														display: 'flex',
														justifyContent: 'flex-end',
													}}
												>
													{isActive && (
														<Button
															disabled={disabled}
															variant={
																profileType === 'Active' ? 'red' : 'green'
															}
															sx={{
																height: '30px',
															}}
															onClick={() => onClick(profileType, type, i)}
														>
															{t(`CompanyProfile.${profileType}.Button`)}
														</Button>
													)}
												</Box>
											</Box>
										</Collapse>
									);
								},
							)}
						</TransitionGroup>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default ProfileClientAddonsMenu;
