import React, { createContext, useState } from "react";
import defaultTheme from "themes/default-theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Children } from "types/children";
import { Theme } from "types/common";

const ThemeContext = createContext<IThemeProvider>(null);

export const useKycThemeContext = () => React.useContext(ThemeContext) as IThemeProvider;

interface Props {
	children: Children;
}

interface IThemeProvider {
	setTheme: (theme) => void;
	theme: any;
	name: string;
}

export const KycThemeProvider = ({ children }: Props) => {
	const [theme, setTheme] = useState(defaultTheme);
	const [name, setName] = useState<Theme>("dark");

	const customSetTheme = (newTheme) => {
		setTheme(newTheme);

		if (newTheme === defaultTheme) {
			setName("default");
		} else {
			setName("dark");
		}
	};

	return (
		<ThemeContext.Provider value={{ setTheme: customSetTheme, theme, name }}>
			<ThemeProvider theme={theme}>
				<CssBaseline>{children}</CssBaseline>
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};
