import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const DashboardColumnItem = ({ title, data }) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				fontWeight: "500",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box width={"100%"} fontWeight={"bold"}>
				{t(`Dashboard.Title.${title}`)}
			</Box>
			<Box
				sx={{
					paddingLeft: "8px",
				}}
			>
				{Object.keys(data).map((key, index) => (
					<Item title={key} key={index} newCount={data?.[key]} />
				))}
			</Box>
		</Box>
	);
};

const Item = ({ title, newCount }) => {
	const { t } = useTranslation();

	return (
		<Box sx={{ display: "flex" }}>
			<Box flex={"1 1 0"}>{t(`Dashboard.Keys.${title}`)}</Box>
			<Box>
				{newCount !== 0 ? (
					<Box
						component={"span"}
						sx={{
							paddingLeft: "4px",
							fontWeight: "bold",
							color: newCount > 0 ? "success.main" : "error.main",
						}}
					>
						{newCount > 0 ? "+" : "-"}({newCount})
					</Box>
				) : (
					newCount
				)}
			</Box>
		</Box>
	);
};

export default DashboardColumnItem;

/*
{oldData}
				{newData !== 0 && (
					<Box
						component={"span"}
						sx={{
							paddingLeft: "4px",
							fontWeight: "bold",
							color: newData > 0 ? "success.main" : "error.main",
						}}
					>
						{newData > 0 ? "+" : "-"}({newData})
					</Box>
				)}
 */
