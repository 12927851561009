import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Form } from 'view/subcomponents/form';
import { useForm } from 'react-hook-form';
import { RoleTypes } from 'view/components/notifications/subcomponents/notifications-write-notification';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import { KycAutocomplete } from 'view/subcomponents/input/autocomplete/kyc-autocomplete';
import { useTranslation } from 'react-i18next';
import api from 'api/api';
import { SnackbarTypes } from 'types/snackbar';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import { yupResolver } from '@hookform/resolvers/yup';
import { createNewUserSchema } from 'utils/validation/schema/create-new-user-schema';

const AddUserDialog = ({ id, open, onClose }) => {
	const close = () => {
		onClose();
	};

	const [pending, setPending] = useState(false);
	const { enqueue } = useSnackbar();
	const { t } = useTranslation();

	const methods = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			identityNumber: '',
			email: '',
			role: { value: RoleTypes.User, label: t(`RoleTypes.User`) },
		},
		mode: 'all',
		shouldFocusError: true,
		reValidateMode: 'onChange',
		criteriaMode: 'all',
		resolver: yupResolver(createNewUserSchema),
	});

	const onSubmit = async values => {
		setPending(true);
		const formatted = {
			firstName: values?.firstName,
			lastName: values?.lastName,
			SSN: values?.identityNumber,
			email: values?.email,
			role: values?.role.value,
			clientId: id,
		};

		await api.addNewUser(formatted).then(res => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, 'Agreement.Update.Success');
			} else {
				enqueue(SnackbarTypes.Error, 'Agreement.Update.Error');
			}

			setPending(false);
		});
	};

	return (
		<Dialog
			open={open}
			onClose={close}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: '16px',
					padding: '8px',
					boxSizing: 'border-box',
					maxWidth: '700px',
				},
			}}
		>
			<DialogTitle
				sx={{
					color: 'primary.main',
					textAlign: 'center',
					fontWeight: 'bold',
				}}
			>
				Lägg till användare
			</DialogTitle>
			<DialogContent>
				<Form
					methods={methods}
					onSubmit={onSubmit}
					sx={{
						marginBottom: '16px',
						marginTop: '16px',
					}}
					id={'new-client-form'}
				>
					<Grid container columnSpacing={4} rowSpacing={1}>
						<Grid item xs={6}>
							<KycFormInput name={'firstName'} inputName={'firstName'} />
						</Grid>
						<Grid item xs={6}>
							<KycFormInput name={'lastName'} inputName={'lastName'} />
						</Grid>
						<Grid item xs={6}>
							<KycFormInput name={'identityNumber'} inputName={'identityNumber.Person'} />
						</Grid>
						<Grid item xs={6}>
							<KycFormInput name={'email'} inputName={'email'} />
						</Grid>

						<Grid item xs={6}>
							<KycAutocomplete
								name={'role'}
								options={Object.values(RoleTypes).map(role => ({
									value: role,
									label: t(`RoleTypes.${role}`),
								}))}
								placeholder={'Roll'}
							/>
						</Grid>
						<Grid item xs={6}>
							<PendingButton variant={'purple'} type={'submit'} pending={pending}>
								Spara
							</PendingButton>
						</Grid>
					</Grid>
				</Form>
			</DialogContent>
		</Dialog>
	);
};
export default AddUserDialog;
