import DialogWrapper from 'view/subcomponents/dialog/dialog-wrapper';
import ProfileClientAddons from 'view/components/clients/subcomponents/client-addons/profile-client-addons';
import { useClients } from '../../../../subcomponents/providers/clients-provider';

const ClientAddonDialog = () => {
	const { clientData, setClientData } = useClients();
	const setOpen = () => {
		setClientData(null);
	};

	if (clientData === null) {
		return null;
	}

	return (
		<DialogWrapper
			open={true}
			setOpen={setOpen}
			title={'Hantera tillägg'}
			sx={{
				maxWidth: '1000px',
				padding: '32px',
			}}
		>
			<ProfileClientAddons />
		</DialogWrapper>
	);
};

export default ClientAddonDialog;
