import SectionWrapper from 'view/subcomponents/wrappers/section-wrapper';
import CustomerInfoLeftContainer from './customer-info-left-container';
import RightContainer from 'view/subcomponents/containers/right-container/right-container';
import CustomerInfoTopContent from './subcomponents/customer-info-top-content';

const CustomerInfo = () => {
	return (
		<SectionWrapper method={null}>
			<CustomerInfoLeftContainer />
			<RightContainer disableActions={true} topContent={<CustomerInfoTopContent />} />
		</SectionWrapper>
	);
};

export default CustomerInfo;
