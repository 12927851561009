import { createContext, useCallback, useContext } from "react";

interface Props {
	refs: any[];
	children: any;
	displayShadow?: boolean;
}

interface IScrollButtons {
	displayShadow: boolean;
	getReference: (index: number) => any | null;
}

const Context = createContext<IScrollButtons>(null);

export const useScrollButtonsContext = () => useContext(Context) as IScrollButtons;

export const ScrollButtonsProvider = ({ refs, children, displayShadow }: Props) => {
	const getReference = useCallback(
		(index) => {
			return refs[index] ?? null;
		},
		[refs]
	);

	return <Context.Provider value={{ getReference, displayShadow: displayShadow ?? false }}>{children}</Context.Provider>;
};
