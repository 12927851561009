import { Box } from "@mui/material";
import React from "react";
import { Children } from "types/children";

interface Props {
	rightSide?: boolean;
	children: Children;
	onClick: any;
}

const SwitchLabel = ({ rightSide, children, onClick }: Props) => {
	return (
		<Box
			sx={{
				whiteSpace: "normal",
				wordBreak: "break-word",
				marginLeft: rightSide ? "16px" : "0",
				marginBottom: rightSide ? "0" : "8px",
				color: "primary.main",
				fontWeight: "bold",
				cursor: "pointer",
				userSelect: "none",
				":hover": {
					opacity: 0.8,
				},
			}}
			onClick={onClick}
		>
			{children}
		</Box>
	);
};

export default SwitchLabel;
