import { isAuthenticated } from "utils/user";
import { getCookie } from "utils/cookie";
import Tokens from "config/constants/tokens";

export const defaultHeaders = {};

const headers = (isBlob = false) => {
	let headers;

	if (isAuthenticated()) {
		headers = {
			headers: {
				Authorization: "Bearer " + getCookie(Tokens.Admin),
			},
		};

		if (isBlob) {
			headers = {
				...headers,
				responseType: "blob",
			};
		}
	}

	return headers;
};

export default headers;
