import { Box } from "@mui/material";
import React from "react";

interface Props {
	children: string;
}

const KycCheckboxLabel = ({ children }: Props) => {
	return (
		<Box
			component={"span"}
			sx={{
				width: "100%",
				maxWidth: "100%",
				fontSize: "1rem",
				fontWeight: "400",
				lineHeight: "1.5",
			}}
		>
			{children}
		</Box>
	);
};

export default KycCheckboxLabel;
