import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import RichTextEditor from "view/subcomponents/rich-text-editor/rich-text-editor";
import React, { useEffect, useState } from "react";
import { Form } from "view/subcomponents/form";
import { quillIsEmpty } from "utils/string";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import api from "api/api";
import PendingButton from "view/subcomponents/buttons/pending-button";
import { SnackbarTypes } from "types/snackbar";

const ChangeNotificationDialog = ({ changeData, setChangeData, updateByIndex }) => {
	const [pending, setPending] = useState(false);
	const [quillValue, setQuillValue] = useState("");
	const [quillError, setQuillError] = useState(false);
	const { enqueue } = useSnackbar();

	const { setValue, ...methods } = useForm({
		defaultValues: {
			title: "",
		},
	});

	useEffect(() => {
		if (changeData != null) {
			setQuillValue(changeData?.message ?? "");
			setValue("title", changeData?.title ?? "");
		}
	}, [setValue, changeData, setQuillValue]);

	const onClose = () => {
		setChangeData(null);
		setPending(false);
	};

	const onSubmit = async ({ title }) => {
		setQuillError(quillIsEmpty(quillValue));
		if (!quillError) {
			setPending(true);
			let data = {
				title: title,
				message: quillValue,
				id: changeData?.id,
			};
			await api.updateGlobalNotification(data).then((res) => {
				if (res.ok) {
					enqueue(SnackbarTypes.Success, "Notification.Update.Success");

					updateByIndex(changeData?.index, {
						...changeData,
						title: title,
						message: quillValue,
					});
				}
				setPending(false);
			});
		}
	};

	return (
		<Dialog
			open={changeData !== null}
			onClose={onClose}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: "16px",
					padding: "8px",
					boxSizing: "border-box",
					maxWidth: "650px",
				},
			}}
		>
			<DialogTitle
				sx={{
					color: "primary.main",
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				Uppdatera notifikation
			</DialogTitle>
			<DialogContent>
				<Form methods={{ ...methods, setValue }} onSubmit={onSubmit}>
					<KycFormInput
						name={"title"}
						placeholder={"Titel"}
						sx={{
							marginBottom: "16px",
						}}
					/>
					<RichTextEditor value={quillValue} setValue={setQuillValue} error={quillError} />
					<Box
						sx={{
							paddingTop: "8px",
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<PendingButton pending={pending} variant={"purple"} type={"submit"}>
							Spara
						</PendingButton>
					</Box>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeNotificationDialog;
