import { useQuery } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';
import { queryKeys } from 'store/query-keys';
import { MailData } from './use-get-mail';


export interface ClientMailLog {
	email: string;
	data: MailData;
	hasSend: boolean;
	success: boolean;
	updated_at: string;
}

export const useGetClientMailLog = clientId => {
	const result = useQuery({
		queryKey: [queryKeys.mail, clientId],
		queryFn: async () => {
			const getClientMailLog = (): Promise<ApiResponse<ClientMailLog[], ClientMailLog[]>> =>
				apiInstance.get<ClientMailLog[]>(apiRoutes.mail.getClientMailLog(clientId), {}, headers());
			const result = await getClientMailLog();
			return result.data;
		},
		refetchOnWindowFocus: false,
		staleTime: 3600000 
	});

	return result;
};