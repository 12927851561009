import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScrollableContent } from 'view/subcomponents/scroll/scrollable-content';
import { ScrollableWrapper } from 'view/subcomponents/scroll/scrollable-wrapper';
import { AllUsersTable } from './all-users-table';
import { StatisticsItem } from '../statistics-item';
import { useGetClientStatistics } from 'store/client/use-get-clients-statistics';
import { useEffect, useState } from 'react';
import { ClientStatistics } from 'types/clients';
import { CircularProgressWithText } from 'view/subcomponents/common/circular-progress-with-text';

interface AllUsersStatisticsProps {
	clientId: string;
}
export const AllUsersStatistics = ({ clientId }: AllUsersStatisticsProps) => {
	const { t } = useTranslation();
	const getClientStatistics = useGetClientStatistics(clientId);
	const [clientStatistics, setClientStatistics] = useState<ClientStatistics | undefined>();

	useEffect(() => {
		if (!getClientStatistics.isLoading && !getClientStatistics.error) {
			setClientStatistics(getClientStatistics.data.data);
		}
	}, [getClientStatistics]);

	if (getClientStatistics.isLoading) {
		return (
			<Box p={4}>
				<CircularProgressWithText text={'Hämtar statistik...'} />
			</Box>
		);
	}

	if (getClientStatistics.error || !clientStatistics) {
		return (
			<Box display={'flex'} flexDirection={'column'} sx={{ display: 'flex', textAlign: 'center' }}>
				<Box
					sx={{
						position: 'relative',

						backgroundColor: '#fff',
						borderRadius: '24px',
						color: 'primary.error.main',
						padding: '24px',
						fontWeight: 'bold',
						fontSize: '20px',
					}}
				>
					{t('Locations.clientLogos.error')}
				</Box>
			</Box>
		);
	}

	return (
		clientStatistics && (
			<Box
				sx={{
					marginBottom: '32px',
					backgroundColor: '#fff',
					width: '100%',
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Grid container>
					<Grid item xs={4} container alignItems={'center'}>
						<StatisticsItem title={t('Started')} content={clientStatistics.notCompletedKyc} />
						<StatisticsItem title={t('Finished')} content={clientStatistics.completedKyc} />
						<StatisticsItem title={t('Approved')} content={clientStatistics.approved} />
						<StatisticsItem title={t('Rejected')} content={clientStatistics.rejected} />
						<StatisticsItem
							title={t('NeedAdditionalInfo')}
							content={clientStatistics.needAdditionalInfo}
							sx={{ marginBottom: '8px' }}
						/>

						<StatisticsItem title={t('HighRisk')} content={clientStatistics.highRisk} />
						<StatisticsItem title={t('MediumRisk')} content={clientStatistics.mediumRisk} />
						<StatisticsItem title={t('LowRisk')} content={clientStatistics.lowRisk} />
						<StatisticsItem title={t('RiskOther')} content={clientStatistics.other} sx={{ marginBottom: '8px' }} />
						<StatisticsItem title={t('CustomerCount')} content={clientStatistics.nrOfEndCustomers} />
						<StatisticsItem title={t('CustomersWithoutKyc')} content={clientStatistics.endCustomersWithoutKyc} />
					</Grid>
					<Grid item xs={8}>
						<ScrollableWrapper>
							<ScrollableContent>
								<AllUsersTable clientStatistics={clientStatistics} />
							</ScrollableContent>
						</ScrollableWrapper>
					</Grid>
				</Grid>
			</Box>
		)
	);
};
