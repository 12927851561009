import React from "react";
import LeftContainer from "view/subcomponents/containers/left-container/left-container";
import { LeftMenuInput } from "view/subcomponents/containers/left-container/menu-items/left-menu-input";

const FranchisesLeftContainer = () => {
	return (
		<LeftContainer button={true}>
			<LeftMenuInput field={"name"} />
		</LeftContainer>
	);
};

export default FranchisesLeftContainer;
