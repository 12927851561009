import { useState } from 'react';
import { Box, Button, TextField, Checkbox, Typography, Grid, Collapse, Autocomplete, Chip, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { useTranslation } from 'react-i18next';
import { BusinessAreas } from 'config/constants/business-areas';
import { SnackbarTypes } from 'types/snackbar';
import { useSendMailFromAdmin } from 'store/mail/use-send-mail-from-admin';
import ReactQuill from 'react-quill';

export enum RoleTypes {
	BusinessAdmin = 'BusinessAdmin',
	Approver = 'Approver',
	User = 'User',
}

const imageOptions = [
	{ value: 'desk', label: 'Arbetarbord med hand' },
	{ value: 'laptop', label: 'Person med laptop' },
	{ value: 'education-changes-the-world', label: 'Education changes the world' },
	{ value: 'money', label: 'Räknar pengar' },
	{ value: 'pumpkins', label: 'Pumpor' },
];

export const WriteMail = () => {
	const { t } = useTranslation();
	const sendMailFromAdmin = useSendMailFromAdmin();
	const [subject, setSubject] = useState('');
	const [title, setTitle] = useState('');
	const [image, setImage] = useState(null);
	const [infoText, setInfoText] = useState('');
	const [tabValue, setTabValue] = useState('1');
	const [email, setEmail] = useState([]);
	const [role, setRole] = useState('');
	const [businessArea, setBusinessArea] = useState('');

	const [addButton, setAddButton] = useState(false);
	const [buttonText, setButtonText] = useState('');
	const [buttonUrl, setButtonUrl] = useState('');

	const [subjectError, setSubjectError] = useState(false);
	const [titleError, setTitleError] = useState(false);
	const [imageError, setImageError] = useState(false);
	const [infoError, setInfoError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [buttonTextError, setButtonTextError] = useState(false);
	const [buttonUrlError, setButtonUrlError] = useState(false);

	const { enqueue } = useSnackbar();
	const [submitPending, setPending] = useState(false);

	const sendMail = async () => {
		let error = false;

		if (!subject) {
			setSubjectError(true);
			error = true;
		}
		if (!title) {
			setTitleError(true);
			error = true;
		}
		if (!image) {
			setImageError(true);
			error = true;
		}
		if (!infoText) {
			setInfoError(true);
			error = true;
		}
		if (tabValue === '1' && email.length === 0) {
			setEmailError(true);
			error = true;
		}
		if (addButton && !buttonText) {
			setButtonTextError(true);
			error = true;
		}
		if (addButton && !buttonUrl) {
			setButtonUrlError(true);
			error = true;
		}

		if (!error) {
			setPending(true);
			await sendMailFromAdmin.mutate(
				{
					subject,
					title,
					infoText,
					image,
					email: tabValue === '1' ? email : null,
					role: tabValue === '2' ? role : null,
					businessArea: tabValue === '2' ? businessArea : null,
					url: addButton ? buttonUrl : null,
					urlText: addButton ? buttonText : null,
				},
				{
					onError: error => {
						setPending(false);
						enqueue(SnackbarTypes.Error, t('Snackbar.Mail.Error'));
					},
					onSuccess: response => {
						setPending(false);
						if (response.ok) {
							if (tabValue === '1' && email.length === 1) {
								enqueue(SnackbarTypes.Success, t('Snackbar.Mail.SuccessSend'));
							} else {
								enqueue(SnackbarTypes.Success, t('Snackbar.Mail.SuccessStored'));
							}
						} else {
							enqueue(SnackbarTypes.Error, t(`Snackbar.Mail.Error`));
						}
					},
				},
			);
		}
	};

	return (
		<TabPanel value={'1'}>
			<Grid container sx={{ paddingBottom: '10px' }}>
				<Grid item sm={4} sx={{ paddingRight: '5px' }}>
					<TextField
						style={{
							width: '100%',
							border: '1px',
							borderRadius: '8px',
							borderColor: 'primary.main',
						}}
						label={t('Placeholder.subject')}
						value={subject}
						onChange={event => {
							setSubject(event.target.value);
							if (subjectError) {
								setSubjectError(false);
							}
						}}
						error={subjectError}
					/>
				</Grid>

				<Grid item sm={4} sx={{ paddingRight: '5px' }}>
					<TextField
						style={{
							width: '100%',
							color: 'primary.main',
							border: '1px',
							borderRadius: '8px',
							borderColor: 'primary.main',
						}}
						label={t('Placeholder.title')}
						value={title}
						onChange={event => {
							setTitle(event.target.value);
							if (titleError) {
								setTitleError(false);
							}
						}}
						error={titleError}
					/>
				</Grid>

				<Grid item sm={4}>
					<Autocomplete
						key="image"
						sx={{
							border: imageError ? '1px solid' : 'transparent',
							borderRadius: '4px',
							borderColor: imageError ? 'error.main' : 'primary.main',
						}}
						onChange={(_, option: { value: string; label: string }) => {
							if (option) {
								setImage(option.value);
								if (imageError) {
									setImageError(false);
								}
							} else {
								setImage(null);
							}
						}}
						options={imageOptions}
						renderInput={params => <TextField label={t('Placeholder.Input.imageType.label')} {...params} />}
					/>
				</Grid>
			</Grid>
			<Box
				sx={{
					paddingBottom: '10px',
					'.ql-toolbar': {
						border: '1px solid',
						borderRadius: '8px 8px 0 0',
						borderColor: infoError ? 'error.main' : 'primary.main',
					},
					'.ql-container.ql-snow': {
						border: '1px solid',
						borderRadius: '0 0 8px 8px',
						borderColor: infoError ? 'error.main' : 'primary.main',
						height: '250px',
						whiteSpace: 'normal',
						wordWrap: 'anywhere',
					},
				}}
			>
				<ReactQuill
					value={infoText}
					defaultValue={''}
					onChange={value => {
						setInfoText(value);
						if (infoError) {
							setInfoError(false);
						}
					}}
					placeholder={t('Locations.mail.writeBody')}
				/>
			</Box>

			<TabContext value={tabValue}>
				<Box sx={{ paddingBottom: '10px', borderColor: 'divider' }}>
					<TabList
						onChange={(_, value) => setTabValue(value)}
						sx={{
							'.MuiTab-root': { textTransform: 'none', fontWeight: 'bold', fontSize: '18px' },
						}}
					>
						<Tab label={t('Locations.mail.chooseEmails')} value={'1'} />
						<Tab label={t('Locations.mail.chooseCustomers')} value={'2'} />
					</TabList>
				</Box>
			</TabContext>

			{tabValue === '1' ? (
				<Box sx={{ paddingBottom: '10px' }}>
					<Autocomplete
						sx={{
							border: emailError ? '1px solid' : 'transparent',
							borderRadius: '4px',
							borderColor: emailError ? 'error.main' : 'primary.main',
						}}
						key="email"
						clearIcon={false}
						options={[]}
						freeSolo
						multiple
						renderTags={(value, props) => value.map((option, index) => <Chip label={option} {...props({ index })} />)}
						renderInput={params => <TextField label={t('Locations.mail.writeEmails')} {...params} />}
						onChange={(_, value) => {
							setEmail(value);
							if (emailError) {
								setEmailError(false);
							}
						}}
					/>
				</Box>
			) : (
				<Grid container sx={{ paddingBottom: '10px' }}>
					<Grid item sm={6} sx={{ paddingRight: '5px' }}>
						<Autocomplete
							key="businessArea"
							onChange={(_, option: { value: string; label: string }) => {
								if (option) {
									setBusinessArea(option.value);
								} else {
									setBusinessArea('');
								}
							}}
							options={Object.values(BusinessAreas).map(area => ({
								value: area,
								label: t(`businessAreas.${area}`),
							}))}
							renderInput={params => <TextField label={t('Placeholder.Input.businessArea.placeholder')} {...params} />}
						/>
					</Grid>
					<Grid item sm={6} sx={{ paddingLeft: '5px' }}>
						<Autocomplete
							key="role"
							onChange={(_, option: { value: string; label: string }) => {
								if (option) {
									setRole(option.value);
								} else {
									setRole('');
								}
							}}
							options={Object.values(RoleTypes).map(role => ({
								value: role,
								label: t(`RoleTypes.${role}`),
							}))}
							renderInput={params => <TextField label={t('Placeholder.Input.role.label')} {...params} />}
						/>
					</Grid>
				</Grid>
			)}

			<Grid container sx={{ paddingBottom: '10px', alignItems: 'center', textAlign: 'center' }}>
				<Grid item sm={1}>
					<Checkbox sx={{ color: 'primary.main' }} checked={addButton} onClick={() => setAddButton(!addButton)} />
				</Grid>
				<Grid item sm={2} display="flex" justifyContent="flex-start">
					<Typography>{t('Placeholder.Input.button.optionLabel')}</Typography>
				</Grid>

				<Grid item sm={9}>
					<Collapse in={addButton} easing="easing">
						<Grid container sx={{ paddingBottom: '10px' }}>
							<Grid item sm={6} sx={{ paddingRight: '5px' }}>
								<TextField
									style={{
										width: '100%',
										border: '1px',
										borderRadius: '8px',
										borderColor: 'primary.main',
										paddingBottom: '10px',
									}}
									label={t('Placeholder.Input.button.label')}
									value={buttonText}
									onChange={event => {
										setButtonText(event.target.value);
										if (buttonTextError) {
											setButtonTextError(false);
										}
									}}
									error={buttonTextError}
								/>
							</Grid>
							<Grid item sm={6}>
								<TextField
									style={{
										width: '100%',
										border: '1px',
										borderRadius: '8px',
										borderColor: 'primary.main',
									}}
									label={t('Placeholder.Input.button.link')}
									value={buttonUrl}
									onChange={event => {
										setButtonUrl(event.target.value);
										if (buttonUrlError) {
											setButtonUrlError(false);
										}
									}}
									error={buttonUrlError}
								/>
							</Grid>
						</Grid>
					</Collapse>
				</Grid>
			</Grid>
			<Box sx={{ display: 'flex', justifyContent: 'right' }}>
				<Button variant={'purple-outlined'} onClick={sendMail} disabled={submitPending}>
					{t('SendMail')}
				</Button>
			</Box>
		</TabPanel>
	);
};
