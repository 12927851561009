import React from "react";
import { Box, Button } from "@mui/material";
import RightContainerCard from "view/subcomponents/card/right-container-card/right-container-card";
import { StatusBox } from "view/subcomponents/common/status-box";
import { useTranslation } from "react-i18next";
import { InternalPendingButton } from "view/subcomponents/buttons/internal-pending-button";
import api from "api/api";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { useUserInfo } from "view/subcomponents/providers/user-info-provider";
import { SnackbarTypes } from "types/snackbar";

const UserCard = ({ user, index, setChangeData }) => {
	const { updateByIndex } = useQueryContext();
	const { t } = useTranslation();
	const { paused, fullName, id } = user;
	const color = paused ? "error.main" : "success.main";
	const { id: userInfoId } = useUserInfo();
	const { enqueue } = useSnackbar();

	const onClick = async () => {
		let data = {
			paused: !paused,
			id: id,
		};

		await api.updateAdminUser(data).then((res) => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, "User.Status.Success");

				updateByIndex(index, {
					...user,
					paused: !paused,
				});
			} else {
				enqueue(SnackbarTypes.Error, "User.Status.Error");
			}
		});
	};

	return (
		<RightContainerCard
			sx={{
				paddingLeft: "32px",
				paddingRight: "32px",
				alignItems: "center",
			}}
		>
			<Box>
				<StatusBox
					sx={{
						color: color,
						borderColor: color,
						width: "125px",
					}}
				>
					{!paused ? t("Active") : t("Paused")}
				</StatusBox>
				<Box
					sx={{
						color: "primary.main",
						fontWeight: "bold",
						fontSize: "20px",
						lineHeight: "1.8",
					}}
				>
					{fullName}
				</Box>
			</Box>

			<Box
				sx={{
					flex: "1 1 0",
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
				}}
			>
				<Button
					onClick={() =>
						setChangeData({
							...user,
							index,
						})
					}
					variant={"purple"}
					sx={{
						marginRight: "16px",
					}}
				>
					{"Ändra"}
				</Button>

				<InternalPendingButton onClick={onClick} variant={paused ? "purple" : "purple-outlined"} disabled={id === userInfoId}>
					{!paused ? t("Pause") : t("Activate")}
				</InternalPendingButton>
			</Box>
		</RightContainerCard>
	);
};

export default UserCard;
