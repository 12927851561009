import { useRef, useState } from 'react';
import api from 'api/api';
import { CSVLink } from 'react-csv';
import { useSectionContext } from '../../../subcomponents/providers/section-provider';
import { useSnackbar } from '../../../subcomponents/providers/snackbar-provider';
import { ApiResponse } from 'apisauce';
import { SnackbarTypes } from '../../../../types/snackbar';
import RightContainerInfo from '../../../subcomponents/containers/right-container/right-container-info';

const defaultUserData = {
	fileContent: [],
	fileName: '',
};

const CustomerInfoTopContent = () => {
	const { filter } = useSectionContext();
	const { enqueue } = useSnackbar();
	const csvRef = useRef(null);
	const [userData, setUserData] = useState(defaultUserData);

	const onClick = async () => {
		await api
			.getUserEmails({
				role: filter.role,
			})
			.then((res: ApiResponse<any>) => {
				if (res.ok) {
					setUserData(res.data);
					csvRef.current.link.click();
				} else {
					enqueue(SnackbarTypes.Error, 'CSV.Error');
					setUserData(defaultUserData);
				}
			});
	};

	return (
		<RightContainerInfo
			onClick={onClick}
			buttonProps={{
				buttonText: 'Ladda ned csv',
				disabled: !filter.role,
			}}
		>
			<CSVLink
				data={userData?.fileContent ?? []}
				filename={userData?.fileName ?? ''}
				className="hidden"
				ref={csvRef}
				target="_blank"
			/>
		</RightContainerInfo>
	);
};

export default CustomerInfoTopContent;
