import React from "react";
import { Box } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

export const SnackbarWrapper = ({ children }) => {
	return (
		<Box
			sx={{
				top: "2%",
				right: {
					xs: "0%",
					sm: "1%",
				},
				padding: {
					xs: "0 4px 0 4px",
					sm: 0,
				},
				maxWidth: {
					xs: "100%",
					sm: "400px",
					md: "400px",
					lg: "400px",
				},
				width: "100%",
				zIndex: "6000",
				position: "absolute",
			}}
		>
			<TransitionGroup>{children}</TransitionGroup>
		</Box>
	);
};
