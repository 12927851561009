import { RouterPaths } from 'config/constants';
import { ErrandQueueStatuses } from 'config/constants/errand';

type DateTime = {
	day?: number;
	month: number;
	year: number;
};

export type DateRange = {
	from: DateTime;
	to: DateTime;
};

export type AgreementsState = {
	name: string;
	identityNumber: string;
	businessArea: string;
	dateRange: DateRange;
};

const InitialState = {
	[RouterPaths.Kyc]: {
		id: '',
		fromAdmin: true,
	},
	[RouterPaths.Clients]: {
		name: '',
		identityNumber: '',
		active: null,
		paused: null,
		businessArea: null,
		emailUser: '',
		clientNumber: '',
		contactPersonEmail: '',
	},
	[RouterPaths.Franchises]: {
		name: '',
	},
	[RouterPaths.Agreements]: {
		name: '',
		identityNumber: '',
		businessArea: null,
		dateRange: {},
	} as AgreementsState,
	[RouterPaths.Users]: {
		name: '',
		SSN: '',
	},
	[RouterPaths.Payments]: {
		name: '',
	},
	[RouterPaths.Notifications]: {
		title: '',
		message: '',
	},
	[RouterPaths.Errands]: {
		name: '',
		identityNumber: '',
		email: '',
		signee: '',
		businessArea: null,
		queueStatus: ErrandQueueStatuses.New,
	},
	[RouterPaths.Invoices]: {
		date: {
			month: new Date().getUTCMonth() + 1,
			year: new Date().getFullYear(),
		},
		name: '',
		identityNumber: '',
	},
};

export default InitialState;
