import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Switch } from "view/subcomponents/input/switch/switch";

interface Props {
	name: string;
	label: string;
	rightSide?: boolean;
	customOnChange?: any;
	sx?: any;
	disabled?: boolean;
	[x: string]: any;
}

export const KycFormSwitch = ({ name, label, rightSide, customOnChange, sx, disabled, ...rest }: Props) => {
	const { getValues } = useFormContext();

	const onChange = async (value, fieldOnChange) => {
		if (customOnChange) {
			customOnChange(value);
		}

		fieldOnChange({
			target: {
				value: value,
			},
		});
	};

	return (
		<Controller
			name={name}
			defaultValue={getValues(name) ?? false}
			render={({ field }) => {
				return (
					<Switch
						label={label}
						checked={field?.value ?? false}
						onChange={(v) => onChange(v, field.onChange)}
						rightSide={rightSide}
						sx={sx}
						disabled={disabled}
						{...rest}
					/>
				);
			}}
		/>
	);
};
