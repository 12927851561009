import React, { useState } from "react";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import api from "api/api";
import FranchisesLeftContainer from "view/components/franchises/franchises-left-container";
import FranchisesRightContainer from "view/components/franchises/franchises-right-container";

const Franchises = () => {
	const [method, setMethod] = useState(() => api.getNewFranchiseErrands);
	const [tab, setTab] = useState("2");

	const updateTab = () => {
		if (tab === "1") {
			setMethod(() => api.getNewFranchiseErrands);
			setTab("2");
		} else {
			setMethod(() => api.getFranchises);
			setTab("1");
		}
	};

	return (
		<SectionWrapper method={method}>
			<FranchisesLeftContainer />
			<FranchisesRightContainer updateTab={updateTab} tab={tab} />
		</SectionWrapper>
	);
};

export default Franchises;
