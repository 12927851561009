import RightContainerCard from "view/subcomponents/card/right-container-card/right-container-card";

import { Box } from "@mui/material";
import { Kyc } from "types/kyc";
import KycCardContent from "view/components/kyc/subcomponents/kyc-card-content";
import KycCardHeader from "view/components/kyc/subcomponents/kyc-card-header";
import KycCardFooter from "view/components/kyc/subcomponents/kyc-card-footer";

interface Props {
	kyc: Kyc;
}

const KycCard = ({ kyc }: Props) => {
	return (
		<RightContainerCard>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
				}}
			>
				<KycCardHeader kyc={kyc} />
				<KycCardContent kyc={kyc} />
				<KycCardFooter kyc={kyc} />
			</Box>
		</RightContainerCard>
	);
};

export default KycCard;
