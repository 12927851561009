export const AllowedLoginPollingCount = 30;

export enum PollStatuses {
	OUTSTANDING_TRANSACTION = "OUTSTANDING_TRANSACTION",
	NO_CLIENT = "NO_CLIENT",
	STARTED = "STARTED",
	USER_SIGN = "USER_SIGN",
	USER_REQ = "USER_REQ",
	COMPLETE = "COMPLETE",
	COMMUNICATION_ERROR = "COMMUNICATION_ERROR",
	FAILED = "FAILED",
	CANCELLED = "CANCELLED",
	INITIALIZE_FAILED = "INITIALIZE_FAILED",
	ALREADY_IN_PROGRESS = "ALREADY_IN_PROGRESS",
	INTERNAL_ERROR = "INTERNAL_ERROR",
	EXPIRED_TRANSACTION = "EXPIRED_TRANSACTION",
	NoUser = "NO_USER",
	NO_USER = "NO_USER",
	AllowedPollingCountExceeded = "ALLOWED_POLLING_COUNT_EXCEEDED",
}
