import { useEffect, useState } from "react";
import { isAuthenticated } from "utils/user";
import { useLocation } from "react-router-dom";
import { useInterval } from "view/subcomponents/hooks/use-interval";
import { RouterPaths } from "config/constants";

export const useIsAuthenticated = (interval = 10000) => {
	const [authenticated, setAuthenticated] = useState<boolean>(isAuthenticated());
	const [timer, setTimer] = useState<number>(null);
	const location = useLocation();

	useInterval(() => {
		const auth = isAuthenticated();
		setAuthenticated(auth);
	}, timer);

	useEffect(() => {
		if (location.pathname === RouterPaths.Home) {
			setTimer(null);
		} else {
			setTimer(interval);
		}
	}, [location, setTimer, interval]);

	return authenticated;
};
