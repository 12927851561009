import { useTranslation } from "react-i18next";
import React from "react";
import { useHistory } from "react-router-dom";
import { RouterPaths } from "config/constants";
import { Button } from "@mui/material";

const NavigateBackButton = () => {
	const history = useHistory();
	const { t } = useTranslation();

	const onClick = () => {
		history.push(RouterPaths.Dashboard);
	};

	return (
		<Button
			variant={"white-shadow"}
			onClick={onClick}
			sx={{
				borderRadius: "30px",
				width: "120px",
			}}
		>
			{t("Back")}
		</Button>
	);
};

export default NavigateBackButton;
