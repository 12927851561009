import { MenuList } from "@mui/material";
import React, { useState } from "react";
import { KycMenu } from "view/subcomponents/menu/kyc-menu";
import { HeaderMenuItem } from "view/components/main/header/header-menu/header-menu-item";
import { RouterPaths } from "config/constants";
import { removeCookie } from "utils/cookie";
import { useHistory } from "react-router-dom";
import api from "api/api";
import Tokens from "config/constants/tokens";

export const HeaderMenu = ({ anchorRef, setOpen, open }) => {
	const [pending, setPending] = useState(false);
	const { push } = useHistory();

	const logout = async () => {
		setPending(true);
		await api.logout(false).then(() => {
			removeCookie(Tokens.Admin);
			push(RouterPaths.Home);
		});
	};

	return (
		<KycMenu anchorRef={anchorRef} setOpen={setOpen} open={open}>
			<MenuList
				autoFocusItem={open}
				sx={{
					width: "100%",
					backgroundColor: "#fff",
					borderRadius: "22px",
					boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
				}}
			>
				<HeaderMenuItem location={RouterPaths.Clients} disabled={pending} />
				<HeaderMenuItem location={RouterPaths.Invoices} disabled={pending} />
				<HeaderMenuItem location={RouterPaths.Users} disabled={pending} />
				<HeaderMenuItem location={RouterPaths.Payments} disabled={pending} />
				<HeaderMenuItem location={RouterPaths.Notifications} disabled={pending} />
				<HeaderMenuItem location={RouterPaths.Errands} disabled={pending} />
				<HeaderMenuItem location={RouterPaths.Agreements} disabled={pending} />
				<HeaderMenuItem location={RouterPaths.Logout} customOnClick={logout} disabled={pending} />
			</MenuList>
		</KycMenu>
	);
};
