import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';

/* eslint-disable */
const ErrandAPI = {
	approveRegisterErrand: data =>
		apiInstance.post('/api/errand/approveRegisterErrand', data, headers()),
	approveFranchiseErrand: data =>
		apiInstance.post('/api/errand/approveFranchiseErrand', data, headers()),
	rejectErrand: data =>
		apiInstance.post('/api/errand/rejectErrand', data, headers()),
	rejectFranchiseErrandById: data =>
		apiInstance.post('/api/errand/rejectFranchiseErrandById', data, headers()),
	getRegisterErrand: data =>
		apiInstance.get('/api/errand/getRegisterErrand', data, headers()),
	getRegisterErrands: data =>
		apiInstance.get('/api/errand/getRegisterErrands', data, headers()),
	getUpdatePaymentModelErrand: data =>
		apiInstance.get('/api/errand/getUpdatePaymentModelErrand', data, headers()),
	removePaymentModelErrand: data =>
		apiInstance.post('/api/errand/removePaymentModelErrand', data, headers()),
	updateErrand: data =>
		apiInstance.post('/api/errand/updateErrand', data, headers()),
	getNewFranchiseErrands: data =>
		apiInstance.get('/api/errand/getNewFranchiseErrands', data, headers()),
	getFranchiseErrand: data =>
		apiInstance.get('/api/errand/getFranchiseErrand', data, headers()),
};

export default ErrandAPI;
