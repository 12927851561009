import React from 'react';
import { useTranslation } from 'react-i18next';
import { LeftMenuAutocomplete } from 'view/subcomponents/containers/left-container/menu-items/left-menu-autocomplete';
import api from '../../../../../api/api';
import useQuery from '../../../hooks/use-query';

const LeftContainerAutocompletePaymentModel = paymentModels => {
	const { t } = useTranslation();

	const { data: paymentModelsData } = useQuery(api.getPaymentModels);

	return (
		<LeftMenuAutocomplete
			field={'paymentModel'}
			options={
				paymentModelsData
					? paymentModelsData?.map(model => ({
							value: model.id,
							label: model.name,
					  }))
					: []
			}
		/>
	);
};

export default LeftContainerAutocompletePaymentModel;
