import React, { useState } from "react";
import ErrandCardPanelOne from "view/components/errands/subcomponents/errand-card-panel-one";
import ErrandCardPanelTwo from "view/components/errands/subcomponents/errand-card-panel-two";
import { QueryProvider, useQueryContext } from "view/subcomponents/providers/query-provider";
import api from "api/api";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import ErrandsCard from "view/components/errands/subcomponents/errands-card";
import { SnackbarTypes } from "types/snackbar";

const CardRegister = ({ registerErrand, index }) => {
	const [open, setOpen] = useState(false);
	const { setData, data: registerData, updateByIndex } = useQueryContext();
	const { enqueue } = useSnackbar();

	const { id } = registerErrand;

	const onSubmit = async (status, data) => {
		let response;
		if (status === "REJECTED") {
			response = await api.rejectErrand({ id });
		} else {
			response = await api.approveRegisterErrand({
				id,
				data: {
					...data,
				},
			});
		}

		if (response?.ok) {
			setData(registerData.filter((e) => e.id !== id));
			enqueue(SnackbarTypes.Success, `Errand.${status}`);
		}
	};

	const update = (errand, data) => {
		if (errand) {
			const hasBeenSigned = data?.content?.signInfo?.hasBeenSigned;

			updateByIndex(index, {
				...errand,
				hasBeenSigned: hasBeenSigned ?? errand?.hasBeenSigned,
			});
		}
	};

	return (
		<QueryProvider
			method={api.getRegisterErrand}
			externalOptions={{
				inputData: { id: id },
			}}
			disabled={!open}
		>
			<ErrandsCard errand={registerErrand} open={open} setOpen={setOpen} showEdit={true} useTab={true} update={update}>
				<ErrandCardPanelOne onClick={onSubmit} />
				<ErrandCardPanelTwo errandId={id} />
			</ErrandsCard>
		</QueryProvider>
	);
};
export default CardRegister;
