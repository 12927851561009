import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Form } from 'view/subcomponents/form';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import api from 'api/api';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { errandSchema } from 'utils/validation/schema/errand-schema';
import TabPanel from 'view/subcomponents/tab-panels/tab-panel';
import { SnackbarTypes } from 'types/snackbar';

const ErrandCardPanelTwo = ({ errandId }) => {
	const [pending, setPending] = useState(false);
	const { data, refetch, isValid } = useQueryContext();
	const { getValues, setValue, ...methods } = useForm({
		defaultValues: {
			signEmail: '',
		},
		resolver: yupResolver(errandSchema),
	});
	const { enqueue } = useSnackbar();
	const signUrl =
		'https://' +
		(process.env.NODE_ENV === 'production' ? '' : 'dev.') +
		'sign.nordkyc.se' +
		'?uuid=';

	useEffect(() => {
		if (data && isValid) {
			const content = data?.content ?? {};
			const { signInfo } = content ?? {};
			setValue('signEmail', signInfo?.boardMemberEmail ?? '');
		}
	}, [isValid, data, setValue]);

	const onSubmit = async () => {
		setPending(true);
		const email = getValues('signEmail');
		await api.updateErrand({ email: email, id: errandId }).then(async res => {
			if (res.ok) {
				await refetch({
					id: errandId,
				});

				let emailData = {
					email: email,
					organisationName: data?.content?.company?.name,
					personName: data?.content?.person?.fullName,
					uuid: errandId,
				};

				await api.sendNewSignEmail(emailData).then(res => {
					if (res.ok) {
						enqueue(SnackbarTypes.Success, 'Errand.Sign.Success');
					}
				});
			}
			setPending(false);
		});
	};

	return (
		<TabPanel value={'2'}>
			<Form methods={{ ...methods, setValue, getValues }} onSubmit={onSubmit}>
				<Grid
					container
					sx={{
						alignItems: 'center',
						marginBottom: '16px',
					}}
				>
					<Grid item xs={4}>
						<KycFormInput
							name={'signEmail'}
							placeholder={'Firmatecknare e-post'}
							disabled={pending}
						/>
					</Grid>
					<Grid
						item
						xs
						sx={{
							marginLeft: '16px',
						}}
					>
						<PendingButton
							variant={'purple'}
							pending={pending}
							sx={{
								minWidth: '200px',
							}}
							type={'submit'}
						>
							Skicka signeringsmejl på nytt
						</PendingButton>
					</Grid>
				</Grid>
				<Box sx={{ color: 'primary.main' }}>
					Signeringslänk:{' '}
					<a href={signUrl + errandId} target="_blank">
						{signUrl + errandId}
					</a>
				</Box>
			</Form>
		</TabPanel>
	);
};

export default ErrandCardPanelTwo;
