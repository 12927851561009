import React from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "view/subcomponents/input/autocomplete/autocomplete";
import { LeftMenuInputBase } from "view/subcomponents/containers/left-container/menu-items/left-menu-input-base";
import { SectionFieldKey, useSectionContext } from "view/subcomponents/providers/section-provider";
import { AutocompleteType } from "types/autocomplete-type";
import { AutocompleteThemes } from "view/subcomponents/input/autocomplete/autocomplete-paper";

interface Props {
	field: SectionFieldKey;
	options: AutocompleteType[];
	disabled?: boolean;
	setValue?: any;
	padding?: {
		right: string;
		left: string;
	};
	animateHeight?: boolean;
	forcePopupIcon?: boolean;
}

export const LeftMenuAutocomplete = ({ field, options, disabled, setValue, ...rest }: Props) => {
	const { updateFilter } = useSectionContext();
	const { t } = useTranslation();

	const onChange = (data) => {
		if (!disabled) {
			if (data?.hasOwnProperty("value")) {
				updateFilter(field, data["value"]);
			} else {
				updateFilter(field, "");
			}
		}
		if (setValue) {
			setValue(data);
		}
	};

	const onReset = () => {
		if (!disabled) {
			updateFilter(field, "");
		}

		if (setValue) {
			setValue(null);
		}
	};

	return (
		<Autocomplete
			theme={AutocompleteThemes.Black}
			options={options}
			externalOnChange={onChange}
			externalOnReset={onReset}
			placeholder={t(`Placeholder.${field}`)}
			inputBase={LeftMenuInputBase}
			sx={{
				".MuiInputBase": {
					padding: 0,
				},
				".MuiAutocomplete-clearIndicator": {
					visibility: "inherit",
					".MuiSvgIcon-root": {
						color: "primary.main",
					},
				},

				".MuiAutocomplete-popupIndicator": {
					marginRight: "16px",

					color: "primary.main",
				},
				".Mui-focused": {
					".MuiAutocomplete-popupIndicator": {
						".MuiSvgIcon-root": {
							color: "#fff",
						},
					},
				},
				"&:hover": {
					".MuiSvgIcon-root": {
						color: "#fff",
					},
				},
			}}
			{...rest}
		/>
	);
};
