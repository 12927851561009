export const getHostname = () => {
	return window.location.hostname;
};

export const isLocalHost = () => {
	return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
};

export const getDomain = () => {
	// returns example.com if the domain is sub.example.com

	const hostname = window.location.hostname;

	return hostname;
};

export const getSiteURL = (subdomain) => {
	if (isLocalHost()) {
		return window.location.origin;
	} else {
		const domain = getDomain();
		const protocol = window.location.protocol;
		return `${protocol}//${subdomain}.${domain}`;
	}
};

export const getQueryParamsPagination = (location) => {
	const params = getQueryParams(location);
	// set default 'limit' parameter
	if (!Object.prototype.hasOwnProperty.call(params, "limit")) {
		params.limit = "10";
	}
	// set default 'offset' parameter
	if (!Object.prototype.hasOwnProperty.call(params, "offset")) {
		params.offset = "0";
	}
	return params;
};

export const getQueryParams = (location) => {
	const params = location.search;
	return Object.fromEntries(new URLSearchParams(params));
};

export const queryParamsJsonToString = (obj) => {
	return Object.keys(obj)
		.map((key) => key + "=" + obj[key])
		.join("&");
};

export const newUrl = (pathname, paramsJson) => {
	return `${pathname}?${queryParamsJsonToString(paramsJson)}`;
};
