import apiInstance from "api/api-instance";
import headers from "api/util/headers";

const FranchiseAPI = {
	getFranchises: (data) => apiInstance.get("/api/franchise/getFranchises", data, headers()),
	getFranchise: (data) => apiInstance.get("/api/franchise/getFranchise", data, headers()),
	createFranchise: (data) => apiInstance.post("/api/franchise/createFranchise", data, headers()),
	updateFranchise: (data) => apiInstance.patch("/api/franchise/updateFranchise", data, headers()),
	deleteFranchise: (data) => apiInstance.delete("/api/franchise/deleteFranchise", data, headers()),
};

export default FranchiseAPI;
