import { KycCloseIcon } from "view/subcomponents/icons/icons";
import React from "react";
import { Box } from "@mui/material";
import { InputTooltip } from "view/subcomponents/tooltip/input-tooltip";

interface Props {
	isResettable?: boolean;
	open?: boolean;
	icon?: any;
	value?: any;
	onReset?: any;
	onClick?: any;
	sx?: any;
}

export const InputEndAdornment = ({ isResettable, open, icon: Icon, value, onReset, onClick, sx }: Props) => {
	if (open || value === "" || value === null || !isResettable) {
		if (Icon) {
			return (
				<Icon
					sx={{
						transform: "rotate(90deg)",
						width: "16px",
						height: "16px",
						cursor: "pointer",
						...sx,
					}}
					onClick={onClick}
				/>
			);
		} else {
			return null;
		}
	}

	return (
		<InputTooltip title={"Rensa"}>
			<Box>
				<KycCloseIcon
					onClick={onReset}
					variant={"clickable"}
					onMouseDown={(event) => event.preventDefault()}
					sx={{
						transform: "rotate(90deg)",
						width: "16px",
						height: "16px",
						cursor: "pointer",
					}}
				/>
			</Box>
		</InputTooltip>
	);
};

InputEndAdornment.defaultProps = {
	isResettable: true,
};
