import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { ScrollableWrapper } from "view/subcomponents/scroll/scrollable-wrapper";
import { ScrollableContent } from "view/subcomponents/scroll/scrollable-content";
import { Box } from "@mui/material";
import React from "react";
import { Children } from "types/children";

interface Props {
	disableActions?: boolean;
	disableBackground?: boolean;
	fullHeight?: boolean;
	hasScroll?: boolean;
	sx?: any;
	children: Children;
	disableScrollButtons?: boolean;
}

const RightContainerContent = ({ disableActions, disableBackground, fullHeight, hasScroll, sx, children, disableScrollButtons }: Props) => {
	const { isValid } = useQueryContext();

	if (isValid || disableActions) {
		if (hasScroll) {
			return (
				<>
					<ScrollableWrapper sx={sx}>
						{disableScrollButtons ? children : <ScrollableContent>{children}</ScrollableContent>}
					</ScrollableWrapper>
				</>
			);
		} else {
			return (
				<Box
					sx={{
						backgroundColor: disableBackground ? "" : "#fff",
						borderRadius: "30px",
						width: "100%",
						height: fullHeight ? "100%" : "auto",
						...sx,
					}}
				>
					{children}
				</Box>
			);
		}
	}
	return null;
};

export default RightContainerContent;
