import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { Children } from "types/children";

interface Props {
	disabled?: boolean;
	onClick: (...any) => void;
	children: Children;
	[x: string]: any;
}

export const InternalPendingButton = ({ disabled, onClick, children, ...rest }: Props) => {
	const [pending, setPending] = useState(false);
	const mountedRef = useRef(true);

	useEffect(() => {
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const onPendingClick = async (...props) => {
		setPending(true);
		await onClick(...props);
		if (mountedRef.current === true) {
			setPending(false);
		}
	};

	return (
		<Button onClick={onPendingClick} {...rest} disabled={pending || disabled}>
			{pending ? <CircularProgress color={"inherit"} size={22} /> : children}
		</Button>
	);
};
