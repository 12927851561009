import { PollStatuses } from "config/constants/login";

export const isLoginSuccessful = (status) => {
	switch (status) {
		case undefined:
			return null;
		case "":
			return null;
		case null:
			return null;
		case PollStatuses.OUTSTANDING_TRANSACTION:
			return null;
		case PollStatuses.NoUser:
			return false;
		case PollStatuses.STARTED:
			return null;
		case PollStatuses.USER_SIGN:
			return null;
		case PollStatuses.USER_REQ:
			return null;
		case PollStatuses.COMPLETE:
			return true;
		case PollStatuses.COMMUNICATION_ERROR:
			return false;
		case PollStatuses.FAILED:
			return false;
		case PollStatuses.CANCELLED:
			return false;
		case PollStatuses.INITIALIZE_FAILED:
			return false;
		case PollStatuses.ALREADY_IN_PROGRESS:
			return null;
		case PollStatuses.NO_CLIENT:
			return false;
		case PollStatuses.AllowedPollingCountExceeded:
			return false;
		default:
			return false;
	}
};
