import LeftContainer from 'view/subcomponents/containers/left-container/left-container';
import { LeftMenuAutocomplete } from 'view/subcomponents/containers/left-container/menu-items/left-menu-autocomplete';

export enum Status {
	schedules = 'schedules',
	success = 'success',
	failed = 'failed',
}

export const MailLeftContainer = ({ tabValue, setStatus, statusOptions }) => {
	return (
		<LeftContainer button={true}>
			{tabValue === '2' && <LeftMenuAutocomplete setValue={setStatus} field="status" options={statusOptions} />}
		</LeftContainer>
	);
};
