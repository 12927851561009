import React from 'react';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { useForm } from 'react-hook-form';
import { ClientCard } from 'types/clients';
import { getClientStatus } from 'utils/client';
import { Form } from 'view/subcomponents/form';
import { Box, Divider, Grid } from '@mui/material';
import { KycAutocomplete } from 'view/subcomponents/input/autocomplete/kyc-autocomplete';
import RightContainerCardHeader from 'view/subcomponents/card/right-container-card/right-container-card-header';
import { InternalPendingButton } from 'view/subcomponents/buttons/internal-pending-button';
import { useTranslation } from 'react-i18next';
import { StatusBox } from 'view/subcomponents/common/status-box';
import { endOfDay, format, lastDayOfMonth } from 'date-fns';
import { DateFormats } from 'config/constants/date';
import { ClientCardDateDialog } from './client-card-date-dialog';
import ClientCardButtons from './client-card-buttons';
import api from '../../../../../api/api';
import { SnackbarTypes } from '../../../../../types/snackbar';
import { useSnackbar } from '../../../../subcomponents/providers/snackbar-provider';
import { useClients } from '../../../../subcomponents/providers/clients-provider';
import ClientCardInfo from './client-card-info';
import TabPanel from '../../../../subcomponents/tab-panels/tab-panel';

const ClientCardContent = () => {
	const { franchises } = useClients();
	const { data, updateData } = useQueryContext();
	const clientCard = data as ClientCard;
	const updateClientCard = updateData as (clientCard: ClientCard) => void;

	const { enqueue } = useSnackbar();
	const { t } = useTranslation();

	const [datePickerModalOpen, setDatePickerModalOpen] = React.useState(false);

	const { identityNumber, name, paused, active, activeUntil, id, franchiseName, franchiseId, clientAddons } =
		clientCard;

	const getDefaultValue = () => {
		if (franchiseId === '0' || franchiseId === null) {
			return null;
		} else {
			return {
				value: franchiseId,
				label: franchiseName,
			};
		}
	};

	const clientStatus = getClientStatus(paused, activeUntil);

	const methods = useForm({
		defaultValues: {
			franchise: getDefaultValue(),
		},
	});

	const onSave = async values => {
		let franchiseId = '0';
		if (values.franchise !== undefined && values.franchise !== null) {
			franchiseId = values.franchise.value;
		}

		await api.updateClientFranchise({ clientId: id, franchiseId: franchiseId }).then(res => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, 'Client.Success');
			} else {
				enqueue(SnackbarTypes.Error, 'Client.Error');
			}
		});
	};

	const onActivate = async () => {
		await onSaveClick({ active: true, activeUntil: null });
	};

	const onSaveClick = async values => {
		await api.updateClientStatus({ id, paused, active, activeUntil, ...values }).then(res => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, 'Client.Success');
				updateClientCard(res.data as ClientCard);
			} else {
				enqueue(SnackbarTypes.Error, 'Client.Error');
			}
		});
	};

	return (
		<TabPanel value={'1'}>
			<ClientCardInfo clientCard={clientCard} clientStatus={clientStatus} />
			<Grid item xs={6} pt={1}>
				<RightContainerCardHeader>Tillägg</RightContainerCardHeader>
				<Box>
					{clientAddons?.length === 0
						? 'Inga valda tillägg'
						: clientAddons?.map(({ type, active, active_until }) => (
								<Box
									key={type}
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flex: '1 1 0',
										}}
									>
										{t(`AddonTypes.${type}`)}
										{active_until && (
											<Box
												sx={{
													backgroundColor: 'rgba(244, 176, 134, 0.4)',
													borderRadius: '8px',
													padding: '0 8px',
													color: 'primary.main',
													opacity: 1,
													marginLeft: '8px',
												}}
											>
												Avslutas: {format(endOfDay(lastDayOfMonth(new Date(active_until))), DateFormats.YYYYMMDDHHMM)}
											</Box>
										)}
									</Box>
									<StatusBox
										sx={{
											color: active ? 'success.main' : 'error.main',
										}}
									>
										{active ? 'Aktiv' : 'Inaktiv'}
									</StatusBox>
								</Box>
						  ))}
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				sx={{
					marginTop: '24px',
				}}
			>
				<Divider
					sx={{
						my: '12px',
					}}
				/>
				<Form methods={methods}>
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '8px',
						}}
					>
						<Grid item xs={6}>
							<Box
								sx={{
									fontWeight: 'bold',
									paddingBottom: '8px',
									color: 'primary.main',
								}}
							>
								För att ta bort ett varumärke lämna fältet tomt och tryck på spara.
							</Box>
							<KycAutocomplete
								name="franchise"
								options={
									franchises?.map(f => ({
										label: f.name,
										value: f.franchiseId,
									})) ?? []
								}
							/>
						</Grid>
						<Grid
							item
							xs={6}
							sx={{
								paddingTop: '28px',
							}}
						>
							<InternalPendingButton variant={'purple'} onClick={() => onSave(methods.getValues())} type={'button'}>
								Spara
							</InternalPendingButton>
						</Grid>
					</Grid>
				</Form>
				<Divider
					sx={{
						my: '24px',
					}}
				/>

				<ClientCardButtons
					setDatePickerModalOpen={setDatePickerModalOpen}
					onActivate={onActivate}
					clientCard={clientCard}
				></ClientCardButtons>
			</Grid>

			<ClientCardDateDialog
				open={datePickerModalOpen}
				setOpen={setDatePickerModalOpen}
				onChange={value => onSaveClick({ activeUntil: value })}
				startValue={activeUntil}
				title={`Avaktivera ${name}`}
			>
				<p>
					Välj datum för när kunden med organisationsnummer
					{identityNumber} ska avaktiveras.
				</p>
			</ClientCardDateDialog>
		</TabPanel>
	);
};

export default ClientCardContent;
