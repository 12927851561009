import React from "react";
import { Tooltip } from "@mui/material";

export const InputTooltip = ({ title, children, ...rest }) => {
	return (
		<Tooltip
			placement={"right"}
			title={title}
			componentsProps={{
				tooltip: {
					sx: {
						backgroundColor: "#000",
					},
				},
				arrow: {
					sx: {
						color: "#000",
					},
				},
			}}
			{...rest}
		>
			{children}
		</Tooltip>
	);
};
