import { Dispatch, SetStateAction, useState } from 'react';
import LeftContainer from 'view/subcomponents/containers/left-container/left-container';
import { LeftMenuButton } from 'view/subcomponents/containers/left-container/menu-items/left-menu-button';
import { MenuOptions } from 'view/components/clientLogos/clientLogos';
import { useTranslation } from 'react-i18next';

interface ClientLogosLeftContainerPrpos {
	setVisibleClientLogos: Dispatch<SetStateAction<MenuOptions>>;
	nrActive: number;
	nrRequests: number;
}

export const ClientLogosLeftContainer = ({
	setVisibleClientLogos,
	nrActive,
	nrRequests,
}: ClientLogosLeftContainerPrpos) => {
	const [t] = useTranslation();
	const [button, setButton] = useState(MenuOptions.Errands);

	const handleOnClick = event => {
		setButton(event.target.id as MenuOptions);
		setVisibleClientLogos(event.target.id as MenuOptions);
	};

	return (
		<LeftContainer button={true}>
			<LeftMenuButton
				id={MenuOptions.Errands}
				sx={{
					background: button === MenuOptions.Errands ? '#8854d0' : 'transparent',
					color: button === MenuOptions.Errands ? '#fff' : '#8854d0',
				}}
				onClick={e => handleOnClick(e)}
				count={nrRequests}
			>
				{t('Locations.clientLogos.requests')}
			</LeftMenuButton>
			<LeftMenuButton
				id={MenuOptions.Active}
				sx={{
					background: button === MenuOptions.Active ? '#8854d0' : 'transparent',
					color: button === MenuOptions.Active ? '#fff' : '#8854d0',
				}}
				onClick={e => handleOnClick(e)}
				count={nrActive}
			>
				{t('Locations.clientLogos.active')}
			</LeftMenuButton>
		</LeftContainer>
	);
};
