import LeftContainer from "view/subcomponents/containers/left-container/left-container";
import { useSectionContext } from "view/subcomponents/providers/section-provider";
import React, { useEffect, useRef } from "react";
import { LeftMenuInput } from "view/subcomponents/containers/left-container/menu-items/left-menu-input";

interface Props {
	setDisabled: (disabled: boolean) => void;
}

const KycLeftContainer = ({ setDisabled }: Props) => {
	const hasUpdatedDisabled = useRef<boolean>(false);
	const { filter } = useSectionContext();

	useEffect(() => {
		const hasValues = Object.values(filter).some((x: string) => x?.length > 0);

		if (hasValues && !hasUpdatedDisabled.current) {
			setDisabled(false);
			hasUpdatedDisabled.current = true;
		}
	}, [filter, setDisabled]);

	return (
		<LeftContainer button={true}>
			<LeftMenuInput field={"id"} />
		</LeftContainer>
	);
};

export default KycLeftContainer;
