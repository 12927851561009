import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo, useState } from "react";

interface ScreenState {
	isSM: boolean;
	isLG: boolean;
	isMD: boolean;
	oneColumn: boolean;
}

export const useScreenType = (): ScreenState => {
	const [screenState, setScreenState] = useState<ScreenState>({
		isSM: false,
		isLG: true,
		isMD: false,
		oneColumn: false,
	});
	const {
		breakpoints: { values, between },
	} = useTheme();

	const isLG = useMediaQuery(`(min-width:${values.lg - 0.5}px)`);
	const isMD = useMediaQuery(`(min-width:${values.sm + 1}px) and (max-width:${values.lg}px)`);
	const isSM = useMediaQuery(between(0, "sm"));
	const oneColumn = useMediaQuery(between(0, 999.5));

	useMemo(() => {
		setScreenState({
			isLG: isLG,
			isMD: isMD,
			isSM: isSM,
			oneColumn: oneColumn,
		} as ScreenState);
	}, [isLG, isMD, isSM, oneColumn]);

	return screenState;
};
