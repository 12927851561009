import { Box, Dialog, DialogTitle } from "@mui/material";
import { KycCloseIcon } from "view/subcomponents/icons/icons";
import React from "react";
import { Children } from "types/children";

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	children: Children;
	title: string;
	hideTitle?: boolean;
	sx?: {
		[x: string]: any;
	};
	fullWidth?: boolean;
}

const DialogWrapper = ({ open, setOpen, children, title, hideTitle, sx, ...rest }: Props) => {
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: "16px",
					boxSizing: "border-box",
					maxWidth: "450px",
					color: "primary.main",
					...sx,
				},
			}}
			{...rest}
		>
			<Box display={"flex"} justifyContent={"flex-end"} padding={"16px 24px 0 0"}>
				<KycCloseIcon
					onClick={() => setOpen(false)}
					sx={{
						color: "primary.main",
						width: "16px",
						height: "16px",
						cursor: "pointer",
						transition: "0.5s opacity",
						":hover": {
							opacity: 0.6,
						},
					}}
				/>
			</Box>
			{!hideTitle && (
				<DialogTitle
					sx={{
						padding: 0,
						textAlign: "center",
						paddingBottom: "24px",
						fontWeight: "bold",
						fontSize: "26px",
					}}
				>
					{title}
				</DialogTitle>
			)}
			{children}
		</Dialog>
	);
};

export default DialogWrapper;
