import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { UserImageContainer } from "view/subcomponents/common/user-image-container";
import { TextContainer } from "view/subcomponents/common/text-container";
import { getFirstName } from "utils/name";
import { HeaderMenu } from "view/components/main/header/header-menu/header-menu";
import { useScreenType } from "view/subcomponents/hooks/use-screen-type";
import { KycCaretIcon } from "view/subcomponents/icons/icons";
import { getImage } from "utils/image";
import { useUserInfo } from "view/subcomponents/providers/user-info-provider";

export const DesktopHeader = () => {
	const { name } = useUserInfo();
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const { isLG, isMD } = useScreenType();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				paddingTop: "16px",
				paddingBottom: "16px",
				width: "100%",
				alignItems: "center",
			}}
		>
			<Box sx={{ display: "flex", alignItems: "flex-start", width: isMD ? "200px" : "300px" }}>
				<Box
					component={"img"}
					src={getImage("logo-white.svg")}
					alt={"Logo"}
					sx={{
						marginRight: "32px",
						height: "60px",
						width: "180px",
					}}
				/>
			</Box>

			<Box sx={{ flex: "1 1 0", display: "flex", justifyContent: "center" }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						minWidth: isLG ? "600px" : "",
					}}
				>
					<Box
						sx={{
							fontSize: "46px",
							lineWidth: "1.5",
							fontWeight: "bold",
							color: "#fff",
						}}
					/>
				</Box>
			</Box>
			<Box
				ref={anchorRef}
				sx={{
					width: isMD ? "200px" : "300px",
					marginLeft: "32px",
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					padding: "4px",
					cursor: "pointer",
					userSelect: "none",

					":hover": {
						backgroundColor: "#d8d8d8",
						borderRadius: "22px",
					},
				}}
				onClick={() => setOpen(!open)}
			>
				<UserImageContainer
					name={name}
					sx={{
						width: "50px",
						height: "50px",
						borderRadius: "18px",
						borderWidth: "2px",
					}}
				/>

				<Box
					sx={{
						paddingLeft: "16px",
						fontWeight: "bold",
						display: "flex",
						flex: "1 1 0",
						flexDirection: "column",
					}}
				>
					<TextContainer
						sx={{
							color: "#000",
							textAlign: isLG ? "" : "center",
						}}
					>
						{isLG ? name : getFirstName(name)}
					</TextContainer>
				</Box>

				<Box ml={2} mr={1} display={"flex"} alignItems={"center"}>
					<KycCaretIcon
						sx={{
							height: 16,
							width: 16,
							cursor: "pointer",
							color: "black",
							transform: "rotate(90deg)",
						}}
					/>
				</Box>
			</Box>
			<HeaderMenu open={open} setOpen={setOpen} anchorRef={anchorRef} />
		</Box>
	);
};
