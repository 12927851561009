import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import api from 'api/api';
import { removeCookie } from 'utils/cookie';
import { isEqual } from 'lodash';
import { isAuthenticated } from 'utils/user';
import { Backdrop } from '@mui/material';
import { CircularProgressWithText } from 'view/subcomponents/common/circular-progress-with-text';
import Tokens from 'config/constants/tokens';
import { RouterPaths } from 'config/constants';

interface IUserInfo {
	id: string;
	name: string;
	companyName: string;
	profilePicture: string | null;
	shouldSeeWelcomeMessage: boolean;
	userCount: number;
	isAllowedRecurring: boolean;
}

interface IUserInfoProvider extends IUserInfo {
	pending: boolean;
	logout: (token?: string) => void;
	updateUserInfoKey: (key: string, data: any) => void;
	setUserInfo: (userInfo: IUserInfo) => void;
	initialFetch: boolean;
}

const InitialUserInfo = {
	formType: null,
	role: null,
	id: '',
	name: '',
	companyName: '',
	profilePicture: null,
	shouldSeeWelcomeMessage: false,
	userCount: 0,
	isAllowedRecurring: false,
};
const Context = createContext<IUserInfoProvider>(null);

export const useUserInfo = () => useContext(Context) as IUserInfoProvider;

const UserInfoProvider = ({ children }) => {
	const { pathname } = useLocation();
	const { push } = useHistory();
	const [pending, setPending] = useState<boolean>(false);
	const [initialFetch, setInitialFetch] = useState<boolean>(false);
	const [userInfo, setUserInfo] = useState<IUserInfo>(InitialUserInfo);
	const locationRef = useRef(null);
	const userInfoRef = useRef(null);
	const mountedRef = useRef(false);

	const logout = useCallback(
		async (token = Tokens.Admin) => {
			setPending(true);
			await api.logout(false).then(() => {
				setUserInfo(InitialUserInfo);
				removeCookie(token);
				push(RouterPaths.Home);
			});
		},
		[push],
	);

	useEffect(() => {
		return () => {
			mountedRef.current = false;
		};
	}, []);

	useEffect(() => {
		if (locationRef.current !== pathname && isAuthenticated()) {
			locationRef.current = pathname;

			api.getUserInfo().then((res: any) => {
				if (res.ok) {
					if (!isEqual(userInfoRef.current, res.data)) {
						setUserInfo(res?.data ?? {});
						userInfoRef.current = res?.data ?? {};
					}
				} else {
					//logout();
				}
				setInitialFetch(true);
			});
		}
	}, [pathname, userInfo, setInitialFetch, logout]);

	const updateUserInfoKey = useCallback(
		(key, data) => {
			setUserInfo({
				...userInfo,
				[key]: data,
			});
		},
		[userInfo],
	);

	const updateUserInfo = useCallback((userInfo: IUserInfo | null) => {
		if (mountedRef.current === true) {
			if (userInfo === null) {
				setUserInfo(InitialUserInfo);
			} else {
				setUserInfo(userInfo);
			}
		}
	}, []);

	const displayBackdrop = !initialFetch && isAuthenticated();

	return (
		<Context.Provider
			value={{
				...userInfo,
				isAllowedRecurring: userInfo.isAllowedRecurring ?? false,
				pending,
				logout,
				updateUserInfoKey,
				setUserInfo: updateUserInfo,
				initialFetch,
			}}
		>
			<Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={displayBackdrop}>
				<CircularProgressWithText text={'Hämtar användare...'} color={'inherit'} />
			</Backdrop>
			{children}
		</Context.Provider>
	);
};

export default UserInfoProvider;
