import React from "react";
import { Box } from "@mui/material";

const ErrorMessagePanel = ({ sx, children, ...rest }) => {
	return (
		<Box
			sx={{
				borderRadius: "16px",
				backgroundColor: "rgba(244, 176, 134, 0.2);",
				padding: "16px 32px 16px 32px",
				fontSize: "16px",
				fontWeight: "bold",
				color: "primary.main",
				textAlign: "center",
				...sx,
			}}
			{...rest}
		>
			{children}
		</Box>
	);
};

export default ErrorMessagePanel;
