import { TextField } from "@mui/material";
import { useMemo } from "react";

interface Props {
	[x: string]: any;
}

const KycFormInputBase = ({ multiple, borderRadius, rounded, error, labelPlacement, sx, multiline, ...rest }: Props) => {
	const placements = useMemo(() => {
		if (labelPlacement === "center") {
			return {
				input: {
					textAlign: "center",
				},
				label: {
					width: "100%",
					maxWidth: "100%",
					textAlign: "center",
					transform: "translate(0, 12px) scale(1)",
				},
				shrink: {
					transform: "translate(12.5%, -9px) scale(0.75)",
				},
				legend: {
					textAlign: "center",
				},
			};
		}
	}, [labelPlacement]);

	return (
		<TextField
			size={"small"}
			fullWidth={true}
			multiline={multiline}
			error={error}
			sx={{
				margin: 0,
				height: multiline || multiple ? "auto" : "50px",
				"input, textarea": { color: "primary.main", ...placements?.input },
				input: {
					padding: "8.5px 18px",
				},

				".MuiInputBase-multiline": {
					padding: "8.5px 18px",
				},

				borderRadius: rounded ? borderRadius ?? "30px" : "4px",

				"input:-webkit-autofill": {
					WebkitTextFillColor: "#8854d0",
					WebkitBoxShadow: "0 0 0px 1000px #fff inset",
					transition: "background-color 5000s ease-in-out 0s",
				},

				".MuiInputBase-root": {
					textAlign: "center",
					height: multiline || multiple ? "auto" : "50px",
					paddingRight: 0,
				},

				".MuiInputLabel-root": {
					transform: "translate(14px, 12px) scale(1)",
					...placements?.label,
				},

				".MuiInputLabel-shrink": {
					"&.MuiInputLabel-root": {
						transform: "translate(18px, -9px) scale(0.75)",
						...placements?.shrink,
					},
				},

				"label.MuiFormLabel-filled, label.Mui-focused": {
					color: "rgba(136, 84, 208, 1)",
				},

				"label.Mui-disabled": {
					color: "rgba(136, 84, 208, 0.5) !important",
				},

				label: {
					color: "rgba(136, 84, 208, 0.5) !important",
				},

				"label[data-shrink='false']": {},

				"label[data-shrink='true']": {
					WebkitTextFillColor: error ? "rgba(247, 100, 97, 1)" : "rgba(136, 84, 208, 1)",
				},

				legend: {
					...placements?.legend,
				},

				fieldset: {
					borderWidth: "1px !important",
					borderColor: "primary.main",
					borderRadius: rounded ? borderRadius ?? "30px" : "4px",
					padding: "0 12px",
				},
				"&:hover": {
					".MuiInputBase-root": {
						fieldset: {
							borderWidth: "1px !important",
							borderColor: !error ? "primary.main" : "error.main",
						},
					},
					".Mui-disabled": {
						fieldset: {
							borderWidth: "1px",
						},
					},
				},
				".Mui-disabled": {
					fieldset: {
						borderColor: "rgba(136, 84, 208, 0.5) !important",
					},
					WebkitTextFillColor: "rgba(136, 84, 208, 0.5)",
				},
				...sx,
			}}
			{...rest}
		/>
	);
};

export default KycFormInputBase;
