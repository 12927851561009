import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextContainer } from "view/subcomponents/common/text-container";
import parse from "html-react-parser";
import { NotificationImages } from "config/constants/notifications";
import { getImage } from "utils/image";

const ChangeNotificationDialog = ({ open, setOpen, getValues, message }) => {
	const [values, setValues] = useState(null);

	useEffect(() => {
		if (open) {
			setValues(getValues());
		}
	}, [open, setValues, getValues]);

	if (!open || !values) {
		return null;
	}

	const { title, imageType } = values;

	const image =
		imageType?.value === NotificationImages.KycGirl ? getImage("kyc-girl-notification.png") : getImage("kyc-guy-notification.png");

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: "16px",
					boxSizing: "border-box",
					maxWidth: "420px",
					minWidth: "420px",
				},
			}}
		>
			<DialogTitle
				sx={{
					color: "primary.main",
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				Förhandsvisa notifikation
			</DialogTitle>
			<DialogContent>
				<Box
					sx={{
						marginTop: "16px",
						marginBottom: "16px",
						borderRadius: "16px",
						backgroundColor: "#fff",
						color: "#000",
						cursor: "pointer",
						boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",
						width: "100%",
						userSelect: "none",
						":hover": {
							boxShadow: "0 2px 11px 0 #a782da",
						},
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							borderRadius: "16px",
							height: "150px",
							width: "100%",
							position: "relative",
							zIndex: 2,
						}}
					>
						<Box
							component={"img"}
							src={image}
							sx={{
								width: "100%",
								height: "100%",
								objectFit: "cover",
								borderRadius: "18px 18px 0 0",
							}}
						/>
					</Box>
					<Box
						sx={{
							padding: "16px 32px 8px 32px",
						}}
					>
						<TextContainer
							fontSize={18}
							fontWeight={"bold"}
							sx={{
								borderBottom: "1px solid",
								borderColor: "divider",
							}}
						>
							{title}
						</TextContainer>
						<TextContainer
							fontSize={18}
							sx={{
								"& p": {
									margin: 0,
									padding: 0,
								},
							}}
						>
							{parse(message ?? "")}
						</TextContainer>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeNotificationDialog;
