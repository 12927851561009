import { useTranslation } from "react-i18next";
import { Box, Button, Fade } from "@mui/material";
import { useHistory } from "react-router-dom";

interface Props {
	count?: number;
	sx?: any;
	location: string;
}

const LeftMenuLocation = ({ count, sx, location }: Props) => {
	const { t } = useTranslation();
	const { push } = useHistory();

	const onClick = () => {
		push({
			pathname: location,
			state: {
				count: count ?? null,
			},
		});
	};

	return (
		<Button
			onClick={onClick}
			variant={"purple-outlined"}
			sx={{
				position: "relative",
				borderRadius: "32px",
				fontSize: "18px",
				...sx,
			}}
		>
			{t(`Locations.${location.replace("/", "")}.title`)}
			<Fade in={count > 0} unmountOnExit timeout={500}>
				<Box
					sx={{
						position: "absolute",
						right: "-5%",
						backgroundColor: "#a870f5",
						width: "30px",
						height: "30px",
						borderRadius: "50%",
						color: "#fff",
						border: "1px solid",
						borderColor: "primary.main",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{count}
				</Box>
			</Fade>
		</Button>
	);
};

export default LeftMenuLocation;
