import React, { useEffect, useRef, useState } from "react";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateRangeOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { InputEndAdornment } from "view/subcomponents/input/end-adornment/input-end-adornment";
import { LeftMenuInputBase } from "view/subcomponents/containers/left-container/menu-items/left-menu-input-base";
import { useSectionContext } from "view/subcomponents/providers/section-provider";

export const LeftDateMenu = ({ field }) => {
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(new Date());
	const { t } = useTranslation();
	const { updateFilter } = useSectionContext();
	const initialRef = useRef(false);

	const onChange = (value) => {
		let newValue = new Date(value);
		setValue(newValue);

		updateFilter(field, {
			month: newValue.getUTCMonth() + 1,
			year: newValue.getFullYear(),
		});

		setOpen(false);
	};

	useEffect(() => {
		if (initialRef.current === false) {
			updateFilter(field, {
				month: value.getUTCMonth() + 1,
				year: value.getFullYear(),
			});
			initialRef.current = true;
		}
	}, [field, updateFilter, value]);

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DesktopDatePicker
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
				mask={"____-__"}
				inputFormat={"YYYY-MM"}
				views={["year", "month"]}
				openTo={"month"}
				value={value}
				onChange={onChange}
				renderInput={({ inputRef, inputProps: { value } }) => {
					return (
						<LeftMenuInputBase
							value={value}
							placeholder={t(`Placeholder.${field}`)}
							endAdornment={
								<InputEndAdornment
									icon={DateRangeOutlined}
									open={true}
									onClick={() => setOpen(true)}
									sx={{
										width: "24px",
										height: "24px",
									}}
								/>
							}
							elRef={inputRef}
						/>
					);
				}}
			/>
		</LocalizationProvider>
	);
};
