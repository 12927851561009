import React, { useCallback, useMemo, useState } from "react";
import { hasError } from "utils/form";
import useInputNames from "view/subcomponents/hooks/use-input-names";
import KycFormInputBase from "view/subcomponents/input/inputs/input-bases/kyc-form-input-base";

interface Props {
	[x: string]: any;
}

export const KycInput = ({
	onBlur,
	formatValue,
	externalChange,
	inputRef,
	onChange,
	inputLabelProps,
	endAdornment,
	hidden,
	errorState,
	disableValidation,
	inputProps,
	inputName,
	customField,
	...rest
}: Props) => {
	const [active, setActive] = useState(false);
	const [touched, setTouched] = useState(false);
	const { isSubmitted, invalid, name } = errorState ?? {};
	const { label, placeholder, autoComplete } = useInputNames({
		name: name,
		customField,
		inputName,
	});

	const isInvalid = useMemo(() => {
		if (!disableValidation) {
			return hasError(invalid, isSubmitted, active, touched);
		}
		return false;
	}, [disableValidation, invalid, isSubmitted, active, touched]);

	const handleBlur = () => {
		if (onBlur) {
			onBlur();
		}

		setActive(false);
	};

	const onFocus = () => {
		setActive(true);
		setTouched(true);
	};

	const handleChange = useCallback(
		(event) => {
			if (externalChange) {
				externalChange(event);
			}

			if (formatValue) {
				event.target.value = formatValue(event.target.value);
			}

			if (onChange) {
				onChange(event);
			}
			if (inputProps?.onChange) {
				inputProps.onChange(event);
			}
		},
		[externalChange, formatValue, onChange, inputProps]
	);

	if (hidden) {
		return null;
	}

	return (
		<KycFormInputBase
			autoComplete={autoComplete}
			inputRef={inputRef}
			onBlur={handleBlur}
			onFocus={onFocus}
			error={isInvalid}
			placeholder={placeholder}
			label={label}
			InputProps={{
				endAdornment: endAdornment,
			}}
			inputProps={{
				...inputProps,
				autoComplete: autoComplete,
				onChange: handleChange,
			}}
			{...rest}
		/>
	);
};
