import * as yup from "yup";
import { isValidStrictPnr } from "utils/validation/validation";

export const createNewUserSchema = yup.object({
	firstName: yup.string().required("Required").min(1),
	lastName: yup.string().required("Required").min(1),
	email: yup.string().email().required("Required"),
	identityNumber: yup.string().test("identityNumber", "Required", isValidStrictPnr),
    role: yup
    .object({
        label: yup.string().required(),
        value: yup.string().required(),
    })
    .required(),
});
