import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';

/* eslint-disable */
const ClientLogoAPI = {
	approveClientLogoErrand: data =>
		apiInstance.post('/api/errand/clientLogo/approve', data, headers()),
	rejectClientLogoErrand: data =>
		apiInstance.post('/api/errand/clientLogo/reject', data, headers()),
	getNewClientLogoErrands: data =>
		apiInstance.get('/api/errand/clientLogo/get', data, headers()),
	getActiveClientLogos: data =>
		apiInstance.get('/api/clientLogo/getActiveClientLogo', data, headers()),
};

export default ClientLogoAPI;
