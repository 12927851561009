import { Autocomplete, Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import DialogWrapper from "view/subcomponents/dialog/dialog-wrapper";
import { useState } from "react";
import countrycodes from "config/constants/countries";

const CountriesTable = ({countries, title, onRemove, onSubmit}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string | null>('');
    const [open, setOpen] = useState(false);
    return (
        
    <TableContainer sx={{marginTop: '16px'}} component={Paper}>
        <DialogWrapper
			open={open}
			setOpen={setOpen}
			title={`Lägg till land (${title})`}
			sx={{
				maxWidth: "700px",
				padding: "32px"
			}}
		>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Autocomplete sx={{width: '50%'}}
                options={countrycodes}
                getOptionLabel={(option) => t(`countries.${option}`)}
                value={value}
                renderInput={(params) => <TextField {...params} label="Land" />}
                onChange={(event, newValue: string | null) => {setValue(newValue)}}/>
                <Button	variant='contained' color='primary' sx={{width: '25%', marginTop: '16px'}} onClick={() => onSubmit(value, t(`countries.${value}`))}>Lägg till</Button>
            </Box>

		</DialogWrapper>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography sx={{padding: '16px', fontSize:'1.4em', color: 'primary.main', fontWeight: 'bold'}}>{title}</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell>
                        <Tooltip title="Lägg till land">
                            <IconButton sx={{color: 'success.main'}} onClick={() => setOpen(true)}>
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{fontWeight: 'bold'}} >{parse(t(`Locations.countries.table.name`))}</TableCell>
                    <TableCell  sx={{fontWeight: 'bold'}}>{parse(t(`Locations.countries.table.code`))}</TableCell>
                    <TableCell sx={{fontWeight: 'bold'}} />
                </TableRow>
            </TableHead>
            <TableBody>
            {countries?.sort((a, b) => a.name.localeCompare(b.name)).map((country) => (
            <TableRow key={country.code}>
                <TableCell>{parse(t(`countries.${country.code}`))}</TableCell>
                <TableCell >{country.code}</TableCell>
                <TableCell>
                    <Tooltip title="Ta bort land">
                        <IconButton sx={{color: 'primary.main'}} onClick={() => onRemove(country)}>
                            <RemoveCircleIcon  />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>))}
            </TableBody>
        </Table>
    </TableContainer>);
}
export default CountriesTable;