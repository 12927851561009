import { Grid } from '@mui/material';
import RightContainerPending from 'view/subcomponents/containers/right-container/right-container-pending';
import RightContainerError from 'view/subcomponents/containers/right-container/right-container-error';

import RightContainerEmpty from 'view/subcomponents/containers/right-container/right-container-empty';
import RightContainerInfo from 'view/subcomponents/containers/right-container/right-container-info';
import { Children } from 'types/children';
import RightContainerContent from 'view/subcomponents/containers/right-container/right-container-content';

interface Props {
	onClick?: any;
	onSecondClick?: () => void;
	onThirdClick?: () => void;
	disableActions?: boolean;
	fullHeight?: boolean;
	disableBackground?: boolean;
	sx?: any;
	hasScroll?: boolean;
	children?: Children;
	buttonProps?: any;
	secondButtonText?: string;
	thirdButtonText?: string;
	disableScrollButtons?: boolean;
	displayTopInfo?: boolean;
	topContent?: any;
	staticInfo?: any;
}

const RightContainer = ({
	onClick,
	onSecondClick,
	onThirdClick,
	secondButtonText,
	thirdButtonText,
	disableActions,
	fullHeight,
	disableBackground,
	sx,
	hasScroll,
	children,
	buttonProps,
	disableScrollButtons,
	topContent,
	displayTopInfo,
}: Props) => {
	return (
		<Grid
			item
			xs={7}
			md={8}
			lg={9}
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{topContent && topContent}
			{displayTopInfo && (
				<RightContainerInfo
					onClick={onClick}
					onSecondClick={onSecondClick}
					onThirdClick={onThirdClick}
					buttonProps={buttonProps}
					secondButtonText={secondButtonText}
					thirdButtonText={thirdButtonText}
				/>
			)}
			{!disableActions && (
				<>
					<RightContainerPending />
					<RightContainerError />
					<RightContainerEmpty />
				</>
			)}

			<RightContainerContent
				disableActions={disableActions}
				disableBackground={disableBackground}
				fullHeight={fullHeight}
				hasScroll={hasScroll}
				sx={sx}
				disableScrollButtons={disableScrollButtons}
			>
				{children}
			</RightContainerContent>
		</Grid>
	);
};

RightContainer.defaultProps = {
	hasScroll: true,
	fullHeight: false,
	disableActions: false,
};

export default RightContainer;
