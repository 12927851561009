import React from "react";
import { useNewClientContext } from "view/subcomponents/providers/new-client-provider";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import NewClientSummaryRow from "view/components/clients/subcomponents/new-client/new-client-summary-row";

const NewClientSummary = () => {
	const { wizardData } = useNewClientContext();
	const { t } = useTranslation();

	return (
		<>
			{Object.keys(wizardData).map((key, index) => {
				const { data } = wizardData[key];

				return (
					<Box key={key}>
						<Box
							sx={{
								fontWeight: "bold",
								fontSize: "20px",
								borderBottom: "1px solid",
								marginRight: "32px",
								borderColor: "primary.main",
								marginTop: "8px",
								marginBottom: "8px",
							}}
						>
							{t(`NewClient.${key}.Title`)}
						</Box>
						<Box>
							{Object.keys(data).map((innerKey) => {
								if (innerKey === "invoice" || innerKey === "person") {
									return Object.keys(data?.[innerKey]).map((innerInnerKey) => {
										return (
											<NewClientSummaryRow
												key={innerInnerKey}
												title={t(`NewClient.${key}.${innerInnerKey}`)}
												content={
													data?.[innerKey]?.[innerInnerKey]?.label ?? data?.[innerKey]?.[innerInnerKey] ?? "-"
												}
											/>
										);
									});
								}

								return (
									<NewClientSummaryRow
										key={innerKey}
										title={t(`NewClient.${key}.${innerKey}`)}
										content={data?.[innerKey]?.label ?? data?.[innerKey]}
									/>
								);
							})}
						</Box>
					</Box>
				);
			})}
		</>
	);
};

export default NewClientSummary;
