import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Form } from 'view/subcomponents/form';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';
import { KycAutocomplete } from 'view/subcomponents/input/autocomplete/kyc-autocomplete';
import { ServiceTypes } from 'config/constants';
import { useTranslation } from 'react-i18next';
import api from 'api/api';
import PendingButton from 'view/subcomponents/buttons/pending-button';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';

const InfoTopContent = () => {
	const [pending, setPending] = useState(false);
	const { addItem } = useQueryContext();
	const { t } = useTranslation();

	const onSubmit = async ({ message, type }) => {
		setPending(true);
		const result = await api.addSystemMessage({ message: message, type: type.value }).then(res => {
			if (res.ok) {
				addItem(res.data, null, true);
			}
			setPending(false);
		});
	};

	const methods = useForm({});

	return (
		<Box
			sx={{
				backgroundColor: '#fff',
				borderRadius: '24px',
				width: '100%',
				position: 'relative',
				padding: '32px',
				boxShadow: '0 4px 18px 0 rgba(32, 73, 136, 0.2)',
				marginBottom: '24px',
			}}
		>
			<Box
				sx={{
					color: 'primary.main',
					fontWeight: 'bold',
					fontSize: '20px',
					paddingBottom: '24px',
				}}
			>
				Skapa nytt servicemeddelande
			</Box>
			<Form onSubmit={onSubmit} methods={methods}>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<KycFormInput name={'message'} />
					</Grid>
					<Grid item xs={6}>
						<KycAutocomplete
							name={'type'}
							options={Object.values(ServiceTypes).map(type => ({
								value: type,
								label: t(`ServiceTypes.${type}`),
							}))}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							paddingTop: '24px',
						}}
					>
						<PendingButton pending={pending} variant={'purple'} type={'submit'}>
							Skapa meddelande
						</PendingButton>
					</Grid>
				</Grid>
			</Form>
		</Box>
	);
};

export default InfoTopContent;
