import { useCallback, useMemo, useState } from "react";

interface IApiMethod {
	method: any;
}

function useApiMethod({ method }: IApiMethod) {
	const [pending, setPending] = useState<boolean>(false);

	const apiMethod = useCallback(
		async (data: any) => {
			setPending(true);
			return await method(data).then((res) => {
				setPending(false);
				return res;
			});
		},
		[method, setPending]
	);

	return useMemo(() => {
		return {
			pending,
			method: apiMethod,
		};
	}, [pending, apiMethod]);
}

export default useApiMethod;
