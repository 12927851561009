import { useQuery } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';
import { queryKeys } from 'store/query-keys';

export interface MailData {
	subject: string;
	title: string;
	image: string;
	infoText: string;
	url: string | null;
	urlText: string | null;
}

export interface AdminMailInfo {
	id: string;
	hasSend: boolean;
	data: MailData;
	to: string[];
	created_at: string;
	updated_at: string;
}

export const useGetMail = status => {
	const result = useQuery({
		queryKey: [queryKeys.mail, status],
		queryFn: async () => {
			const getMail = (): Promise<ApiResponse<AdminMailInfo[]>> =>
				apiInstance.get<AdminMailInfo[]>(apiRoutes.mail.getMailFromAdmin(status), {}, headers());
			const result = await getMail();
			
			return result.data;
		},	
		refetchOnWindowFocus: false,
	});
	return result;
};
