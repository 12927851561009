import { Kyc } from 'types/kyc';
import { Box, Divider, Grid } from '@mui/material';
import RightContainerCardHeader from 'view/subcomponents/card/right-container-card/right-container-card-header';
import RightContainerCardItem from 'view/subcomponents/card/right-container-card/right-container-card-item';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGetCustomerInformationWithKycId } from 'store/kyc/use-get-customer-information-with-kyc-id';
import { KycParticipantSignStatus } from 'store/kyc/types';

interface Props {
	kyc: Kyc;
}

const KycCardContent = ({ kyc }: Props) => {
	const { t } = useTranslation();
	const signUrl = 'https://' + (process.env.NODE_ENV === 'production' ? '' : 'dev.') + 'sign.nordkyc.se' + '?uuid=';
	const getCutromerInformation = useGetCustomerInformationWithKycId(kyc.id);
	const customerInformationList = getCutromerInformation?.data;

	const statusTranslated = status => {
		switch (status) {
			case KycParticipantSignStatus.IMPORTED:
			case KycParticipantSignStatus.SIGNED:
			case KycParticipantSignStatus.FORM_FINISHED:
				return t('Finished');
			case KycParticipantSignStatus.PENDING:
				return t('Send');
			case KycParticipantSignStatus.ABORTED:
				return t('Aborted');
		}
	};

	return (
		<Grid
			container
			sx={{
				'.MuiGrid-grid-xs-6': {
					paddingTop: '16px',
				},
			}}
		>
			<Grid
				item
				xs={12}
				sx={{
					paddingTop: '16px',
					paddingBottom: '16px',
				}}
			>
				<Divider />
			</Grid>
			<Grid item xs={6}>
				<RightContainerCardHeader>Kundinformation</RightContainerCardHeader>
				<RightContainerCardItem title={'Namn'}>{kyc?.clientName}</RightContainerCardItem>
				<RightContainerCardItem title={'Modul'}>{kyc?.type}</RightContainerCardItem>
			</Grid>
			<Grid item xs={6}>
				<RightContainerCardHeader>Signeringsinformation</RightContainerCardHeader>
				{kyc?.signees?.length === 0 && <Box>Ingen signeringsinformation</Box>}
				{kyc?.signees?.map((signee, i) => {
					const role = signee?.path?.[0];
					return (
						<Box
							key={i}
							sx={{
								paddingBottom: i !== kyc?.signees?.length - 1 ? '16px' : '0px',
							}}
						>
							<Box
								sx={{
									fontWeight: 'bold',
									fontSize: '18px',
								}}
							>
								{t(`newKyc.Headers.${kyc.type}.${role}`)}
							</Box>
							<Box>
								<b>Namn:</b> {signee.name}
							</Box>
							<Box>
								<b>Personnummer:</b> {signee.identityNumber}
							</Box>
							<Box>
								<b>Signerad:</b>{' '}
								<Box
									component={'span'}
									sx={{
										color: signee.status ? 'success.main' : 'error.main',
									}}
								>
									{signee.status ? 'Ja' : 'Nej'}
								</Box>
								{!signee.status && (
									<Box>
										<b>Signeringslänk:</b>{' '}
										{
											<a href={signUrl + signee.uuid} target="_blank">
												{signUrl + signee.uuid}
											</a>
										}
									</Box>
								)}
							</Box>
							<Box>
								<b>Email:</b> {signee.email}
							</Box>
						</Box>
					);
				})}
			</Grid>
			<Grid item xs={6}>
				<RightContainerCardHeader>Kundkännedomsinformation</RightContainerCardHeader>
				<RightContainerCardItem title={'Skapad'}>{moment(kyc.created).format('YYYY-MM-DD')}</RightContainerCardItem>
				<RightContainerCardItem title={'Status'}>{t(`kycStatusesSingular.${kyc?.status}`)}</RightContainerCardItem>
				<RightContainerCardItem title={'Skapad av'}>{kyc.creator}</RightContainerCardItem>
				<RightContainerCardItem title={'Ansvarig'}>{kyc.responsible}</RightContainerCardItem>
				<RightContainerCardItem title={'KYC-ID'}>{kyc.id}</RightContainerCardItem>
			</Grid>
			<Grid item xs={6}>
				<RightContainerCardHeader>Kundinformation</RightContainerCardHeader>
				{customerInformationList && customerInformationList.length > 0 ? (
					customerInformationList.map((ci, key) => {
						return (
							<Box key={key}>
								<RightContainerCardItem title={'Datum'}>
									{moment(ci.updated_at).format('YYYY-MM-DD')}
								</RightContainerCardItem>
								<RightContainerCardItem title={'Status'}>{statusTranslated(ci.status)}</RightContainerCardItem>
								<RightContainerCardItem title={'Email'}>{ci.email}</RightContainerCardItem>
								{ci.url && (
									<RightContainerCardItem title={'Url'}>
										<a href={ci.url} target="_blank">
											{ci.url}
										</a>
									</RightContainerCardItem>
								)}
								<Divider style={{ width: '60%' }} />
							</Box>
						);
					})
				) : (
					<Box>Ingen kundinformation</Box>
				)}
			</Grid>
		</Grid>
	);
};

export default KycCardContent;
