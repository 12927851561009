import { isValidOrg, isValidStrictPnr } from "validation/validation";
import * as personnummer from "personnummer.js";

export const formatPostalCode = (code) => {
	return code;
};

export const formatIdentityNumber = (identityNumber) => {
	if (identityNumber) {
		if (isValidStrictPnr(identityNumber)) {
			identityNumber = identityNumber.replace("-", "");
			personnummer.normalise(identityNumber);
			return personnummer.normalise(identityNumber);
		} else if (isValidOrg(identityNumber)) {
			return identityNumber.replace("-", "");
		} else {
			return identityNumber;
		}
	}

	return identityNumber;
};
