import React, { useState } from "react";
import { Box } from "@mui/material";
import { CircularProgressWithText } from "view/subcomponents/common/circular-progress-with-text";
import { useTranslation } from "react-i18next";
import NotificationCard from "view/components/notifications/subcomponents/notification-card";
import { ScrollButtons } from "view/subcomponents/scroll/scroll-buttons";
import { ScrollableContent } from "view/subcomponents/scroll/scrollable-content";
import { ScrollableWrapper } from "view/subcomponents/scroll/scrollable-wrapper";
import ChangeNotificationDialog from "view/subcomponents/dialog/change-notification-dialog";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import RightContainerEmpty from "view/subcomponents/containers/right-container/right-container-empty";

const NotificationsList = () => {
	const { data, pending, isValid, updateByIndex } = useQueryContext();
	const { t } = useTranslation();
	const [changeData, setChangeData] = useState(null);

	if (data?.length === 0 && !pending) {
		return (
			<RightContainerEmpty
				sx={{
					marginTop: "32px",
				}}
			/>
		);
	}

	return (
		<Box
			sx={{
				paddingTop: "32px",
				flex: "1 1 0",
			}}
		>
			{pending || !isValid ? (
				<Box
					sx={{
						backgroundColor: "#fff",
						paddingTop: "16px",
						paddingBottom: "16px",
						borderRadius: "30px",
					}}
				>
					<CircularProgressWithText text={t("Fetching.notifications")} />
				</Box>
			) : (
				<ScrollableWrapper>
					<ScrollButtons items={data}>
						<ScrollableContent>
							{data?.map((notification, index) => (
								<NotificationCard
									key={index}
									notification={notification}
									setChangeData={setChangeData}
									index={index}
									updateByIndex={updateByIndex}
								/>
							))}
						</ScrollableContent>
					</ScrollButtons>
				</ScrollableWrapper>
			)}

			<ChangeNotificationDialog changeData={changeData} setChangeData={setChangeData} updateByIndex={updateByIndex} />
		</Box>
	);
};

export default NotificationsList;
