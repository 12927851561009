import { isNullOrUndefined } from "utils/common";

export const getInitials = (name, includeDot = false) => {
	if (isNullOrUndefined(name)) {
		return "Test";
	}

	if (name.includes(" ")) {
		let split = name.split(" ");
		const splittedName = split[0][0] + (includeDot ? "." : "") + split[split.length - 1][0];
		return splittedName.toUpperCase();
	} else {
		return name;
	}
};

export const getFirstName = (name) => {
	let split = name.split(" ");
	return split[0];
};
