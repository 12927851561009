import React, { useState } from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import UsersNewUser from "view/components/users/subcomponents/users-new-user";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import UserCard from "view/components/users/subcomponents/user-card";
import ChangeUserDialog from "view/subcomponents/dialog/change-user-dialog";

const UsersRightContainer = () => {
	const [changeData, setChangeData] = useState(null);
	const { data, updateByIndex } = useQueryContext();

	return (
		<RightContainer
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
			topContent={<h2>Hantera användare</h2>}
		>
			<UsersNewUser />
			{data?.map((user, index) => (
				<UserCard key={index} user={user} index={index} setChangeData={setChangeData} />
			))}
			<ChangeUserDialog updateByIndex={updateByIndex} changeData={changeData} setChangeData={setChangeData} />
		</RightContainer>
	);
};

export default UsersRightContainer;
