import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import React, { useEffect, useState } from "react";
import { Form } from "view/subcomponents/form";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import api from "api/api";
import PendingButton from "view/subcomponents/buttons/pending-button";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateFranchiseSchema } from "utils/validation/schema/update-franchise-schema";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { SnackbarTypes } from "types/snackbar";

const ChangeFranchiseDialog = ({ changeData, setChangeData }) => {
	const [pending, setPending] = useState(false);
	const { enqueue } = useSnackbar();
	const { updateByIndex } = useQueryContext();

	const { setValue, ...methods } = useForm({
		defaultValues: {
			name: "",
			franchiseId: "",
		},
		resolver: yupResolver(updateFranchiseSchema),
	});

	useEffect(() => {
		if (changeData != null) {
			setValue("name", changeData?.name ?? "");
			setValue("franchiseId", changeData?.franchiseId ?? "");
		}
	}, [setValue, changeData]);

	const onClose = () => {
		setChangeData(null);
		setPending(false);
	};

	const onSubmit = async (data) => {
		setPending(true);
		await api.updateFranchise({ ...data }).then((res) => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, "Franchise.Update.Success");

				updateByIndex(changeData?.index, {
					...changeData,
					name: data.name,
				});
			} else {
				enqueue(SnackbarTypes.Error, "Franchise.Update.Error");
			}
			setPending(false);
		});
	};

	return (
		<Dialog
			open={changeData !== null}
			onClose={onClose}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: "16px",
					padding: "8px",
					boxSizing: "border-box",
					maxWidth: "650px",
				},
			}}
		>
			<DialogTitle
				sx={{
					color: "primary.main",
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				Uppdatera franchise
			</DialogTitle>
			<DialogContent>
				<Form
					methods={{ ...methods, setValue }}
					onSubmit={onSubmit}
					sx={{
						marginTop: "16px",
						marginBottom: "16px",
						".MuiBox-root": {
							marginBottom: "16px",
						},
					}}
				>
					<KycFormInput name={"name"} />
					<Box
						sx={{
							paddingTop: "8px",
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<PendingButton pending={pending} variant={"purple"} type={"submit"}>
							Spara
						</PendingButton>
					</Box>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeFranchiseDialog;
