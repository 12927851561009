import { useQuery } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';
import { queryKeys } from 'store/query-keys';

interface ClientLogo {
	logo: string;
	width: number;
	height: number;
	id: string;
	clientId: string;
	companyName: string;
	companyOrg: string;
	fullName: string;
	email: string;
	name: string;
}

export const useGetClientLogoFromClientId = clientId => {
	const result = useQuery({
		queryKey: [queryKeys.getActiveClientLogo, clientId],
		queryFn: async () => {
			const getClientLogo = (): Promise<ApiResponse<ClientLogo, ClientLogo>> =>
				apiInstance.get<ClientLogo>(apiRoutes.clientLogo.getClientLogo(clientId), {}, headers());
			const result = await getClientLogo();
			return result;
		},
		refetchOnWindowFocus: false,
	});

	return result;
};
