import { Box } from "@mui/material";
import React from "react";
import { Reference } from "types/reference";
import { Children } from "types/children";

interface Props {
	elRef?: Reference;
	sx?: any;
	children: Children;
	fontSize?: string | number;
	[x: string]: any; //rest
}

export const TextContainer = ({ elRef, sx, children, fontSize, ...rest }: Props) => {
	return (
		<Box
			sx={{
				whiteSpace: "normal",
				marginBottom: 0,
				marginTop: 0,
				textOverflow: "ellipsis",
				wordWrap: "anywhere",
				fontSize: fontSize,
				...sx,
			}}
			ref={elRef}
			{...rest}
		>
			{children}
		</Box>
	);
};
