import apisauce from "apisauce";

const apiInstance = apisauce.create({
	baseURL: window.config.site.backend_url,
	timeout: 30000,
	headers: {
		"Content-type": "application/json",
		Accept: "application/json",
		"Accept-Language": "sv",
	},
});

export default apiInstance;
