import React from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import InfoTopContent from "view/components/info/subcomponents/info-top-content";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import InfoCard from "./subcomponents/info-card";
import api from "api/api";

const InfoRightContainer = () => {
	const { data, updateByIndex } = useQueryContext();

	const onClick = async (item, index) => {
		await api.updateSystemMessageStatus({ id: item.id, active: !item.active }).then((res) => {
			if (res.ok) {
				updateByIndex(index, {
					...item,
					active: !item.active,
				});
			}
		});
	};

	return (
		<RightContainer displayTopInfo={false} hasScroll={false} fullHeight={true} disableBackground={true} topContent={<InfoTopContent />}>
			{data?.map((info, index) => (
				<InfoCard key={index} item={info} onClick={onClick} index={index} isFirst={index === 0} />
			))}
		</RightContainer>
	);
};

export default InfoRightContainer;
