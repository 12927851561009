import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetClientLogoFromClientId } from 'store/client-logo/use-get-client-logo';
import { Children } from 'types/children';
import RightContainerCardItem from 'view/subcomponents/card/right-container-card/right-container-card-item';

interface ClientLogoCardExpandedProps {
	clientId: string;
	children?: Children;
}

export const ClientLogoCardExpanded = ({ clientId, children }: ClientLogoCardExpandedProps) => {
	const getClientLogo = useGetClientLogoFromClientId(clientId);
	const { t } = useTranslation();

	if (getClientLogo.isLoading) {
		return <CircularProgress />;
	}

	if (!getClientLogo?.data?.data) {
		return <CircularProgress />;
	}

	if (getClientLogo.error || !getClientLogo.data.data) {
		return <h3>Något gick fel: Kunde inte hämta klientens logotyp</h3>;
	}

	const clientLogo = getClientLogo.data.data;

	return (
		clientLogo && (
			<Box>
				<RightContainerCardItem
					title={t('Locations.clientLogos.user')}
					sx={{
						paddingBottom: '16px',
					}}
				>
					{clientLogo.fullName}
				</RightContainerCardItem>
				<RightContainerCardItem
					title={t('Locations.clientLogos.userEmail')}
					sx={{
						paddingBottom: '16px',
					}}
				>
					{clientLogo.email}
				</RightContainerCardItem>
				<RightContainerCardItem
					title={t('Locations.clientLogos.fileName')}
					sx={{
						paddingBottom: '16px',
					}}
				>
					{clientLogo.name}
				</RightContainerCardItem>

				<Box
					component={'img'}
					src={clientLogo.logo}
					sx={{
						border: '2px solid',
						width: `${clientLogo.width}px`,
						height: `${clientLogo.height}px`,
						objectFit: 'cover',
						borderRadius: '18px',
					}}
				/>
				{children}
			</Box>
		)
	);
};
