import React, { useCallback, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { SnackbarItemType, useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { SnackbarIcon } from "view/subcomponents/snackbar/snackbar-icon";
import { useTranslation } from "react-i18next";

interface Props {
	notification: SnackbarItemType;
}

const SnackbarItem = React.forwardRef(({ notification }: Props, ref) => {
	const { dequeue } = useSnackbar();
	const { t } = useTranslation();
	const { variant, translateKey, additionalText } = notification;
	const hasStartedTimer = useRef(false);

	const onTimeout = useCallback(() => {
		dequeue(notification.key);
	}, [notification.key, dequeue]);

	useEffect(() => {
		if (!hasStartedTimer.current) {
			setTimeout(onTimeout, 5000);
			hasStartedTimer.current = true;
		}
	}, [onTimeout]);

	const getMessage = () => {
		return `${t(`Snackbar.${translateKey}`)} ${additionalText ?? ""} `;
	};

	const onClick = () => {
		dequeue(notification.key);
	};

	return (
		<Box
			ref={ref}
			sx={{
				boxShadow: (theme) => theme.custom.shadow[variant],
				color: `${variant}.main`,
				backgroundColor: (theme) => theme.custom.snackbar[variant],
				userSelect: "none",
				borderRadius: "16px",
				fontWeight: "bold",
				display: "flex",
				alignItems: "center",
				boxSizing: "border-box",
				padding: "16px",
				fontSize: "18px",
				marginBottom: "16px",
				":hover": {
					opacity: 0.8,
					cursor: "pointer",
				},
				width: "100%",
			}}
			onClick={() => onClick()}
		>
			<Box
				sx={{
					flex: "1 1 0",
					paddingRight: "16px",
					paddingLeft: "16px",
				}}
			>
				{getMessage()}
			</Box>
			<Box>
				<SnackbarIcon variant={variant} />
			</Box>
		</Box>
	);
});

export default SnackbarItem;
