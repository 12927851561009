import * as yup from 'yup';

export const agreementInformationSchema = yup.object({
	paymentPeriod: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	formType: yup
		.string()
		.required(),
	businessArea: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
	paymentMethod: yup
		.object({
			label: yup.string().required(),
			value: yup.string().required(),
		})
		.required(),
});
