import * as yup from "yup";
import { isOnlyDigits } from "utils/validation/validation";

export const agreementInvoiceSchema = yup.object({
	address: yup.string().required().min(1),
	postalCode: yup.string().test("Digits only", "The field should have digits only", isOnlyDigits),
	county: yup.string().required().min(1),
	emailCopy: yup.string().email(),
	email: yup.string().email().required(),
});
