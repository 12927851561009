import React from "react";
import { Box } from "@mui/material";
import { Children } from "types/children";

interface Props {
	children: Children;
	sx?: any;
}

const RightContainerCardHeader = ({ children, sx }: Props) => {
	return (
		<Box
			sx={{
				fontWeight: "bold",
				fontSize: "18px",
				paddingBottom: "8px",
				...sx,
			}}
		>
			{children}
		</Box>
	);
};

export default RightContainerCardHeader;
