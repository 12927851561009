import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';

export const useSendMailFromAdmin = () => {
	const queryClient = useQueryClient();
	async function sendMail(data) {
		return apiInstance.post<any>(apiRoutes.mail.sendMailFromAdmin(), data, headers());
	};
	return useMutation(sendMail);
};
