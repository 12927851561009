import { Box, Button, DialogContent, DialogTitle, InputBase } from '@mui/material';
import { useState } from 'react';
import { NullableDate } from 'types/clients';
import DialogWrapper from 'view/subcomponents/dialog/dialog-wrapper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import { InputEndAdornment } from 'view/subcomponents/input/end-adornment/input-end-adornment';
import { DateRangeOutlined } from '@mui/icons-material';
import { Children } from 'types/children';
import SaveIcon from '@mui/icons-material/Save';

interface ClientCardDateDialogProps {
	open: boolean;
	setOpen: (open: boolean) => void;
	onChange: (value: NullableDate) => void;
	startValue: string;
	children?: Children;
	title: string;
}

export const ClientCardDateDialog = ({
	open,
	setOpen,
	startValue,
	children,
	title,
	onChange,
}: ClientCardDateDialogProps) => {
	const [datePickerOpen, setDatePickerOpen] = useState(false);
	const [value, setValue] = useState(startValue ? new Date(startValue) : new Date());

	const onChangeDate = date => {
		const dat = new Date(date);
		dat.setHours(23, 59, 0, 0);
		setValue(dat);
		setDatePickerOpen(false);
	};

	return (
		<DialogWrapper open={open} fullWidth={true} setOpen={setOpen} title={title}>
			<DialogContent>{children}</DialogContent>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
						height: '100%',
						boxSizing: 'border-box',

						padding: '0',
					}}
				>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DesktopDatePicker
							open={datePickerOpen}
							onOpen={() => setDatePickerOpen(true)}
							onClose={() => setDatePickerOpen(false)}
							value={value ? value.toISOString() : null}
							views={['month', 'day']}
							openTo={'day'}
							mask="____-__-__"
							inputFormat={'YYYY-MM-DD'}
							onChange={onChangeDate}
							renderInput={({ inputRef, inputProps: { value } }) => {
								return (
									<InputBase
										ref={inputRef}
										value={value}
										type="text"
										placeholder={'Datum'}
										unselectable="on"
										endAdornment={
											<InputEndAdornment
												icon={DateRangeOutlined}
												open={true}
												onClick={() => setDatePickerOpen(true)}
												sx={{
													width: '24px',
													height: '24px',
												}}
											/>
										}
									/>
								);
							}}
						/>
					</LocalizationProvider>
				</Box>
			</DialogContent>
			<DialogContent>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'center',
						width: '100%',
						height: '100%',
						boxSizing: 'border-box',
						padding: '0',
						button: {
							marginLeft: '8px',
						},
					}}
				>
					<Button
						onClick={() => {
							if (value.toISOString() !== startValue) {
								onChange(value);
							}
							setOpen(false);
						}}
						variant="contained"
						color="primary"
						endIcon={<SaveIcon />}
						disabled={value.toISOString() === startValue}
					>
						Spara
					</Button>

					<Button
						onClick={() => {
							setOpen(false);
						}}
						variant="purple-outlined"
					>
						Stäng
					</Button>
				</Box>
			</DialogContent>
		</DialogWrapper>
	);
};
