import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import api from 'api/api';
import RightContainerCard from 'view/subcomponents/card/right-container-card/right-container-card';
import {
	QueryProvider,
	useQueryContext,
} from 'view/subcomponents/providers/query-provider';
import { formatOrgNumber } from 'utils/string';
import { TabContext, TabList } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import AgreementCardPanelOne from 'view/components/agreements/subcomponents/agreement-card-panel-one';
import AgreementCardPanelTwo from 'view/components/agreements/subcomponents/agreement-card-panel-two';

const AgreementCard = ({ agreement, index }) => {
	const [tabValue, setTabValue] = useState('1');
	const [open, setOpen] = useState(false);
	const { agreementId, name, identityNumber, clientId } = agreement;

	const { t } = useTranslation();

	const onClick = () => {
		if (open) {
			setTabValue('1');
		}
		setOpen(!open);
	};

	return (
		<QueryProvider
			method={api.getAgreement}
			externalOptions={{
				inputData: { id: agreementId },
			}}
			disabled={!open}
		>
			<RightContainerCard
				open={open}
				setOpen={onClick}
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box
					sx={{
						fontWeight: 'bold',
						fontSize: '20px',
						lineHeight: '1.8',
					}}
				>
					{name}
				</Box>
				<Box>{formatOrgNumber(identityNumber)}</Box>
				{open && (
					<Box>
						<TabContext value={tabValue}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList
									onChange={(_, value) => setTabValue(value)}
									sx={{
										'.MuiTab-root': {
											textTransform: 'none',
											fontWeight: 'bold',
											fontSize: '18px',
										},
									}}
								>
									<AgreementTab label={t('Agreement')} value={'1'} />
									<AgreementTab label={t('Rewrite')} value={'2'} />
								</TabList>
							</Box>

							<AgreementCardPanelOne />
							<AgreementCardPanelTwo
								agreementId={agreementId}
								clientId={clientId}
							/>
						</TabContext>
					</Box>
				)}
			</RightContainerCard>
		</QueryProvider>
	);
};

const AgreementTab = ({ ...rest }) => {
	const { pending } = useQueryContext();

	return <Tab {...rest} disabled={pending} />;
};

export default AgreementCard;
