export enum LoginMethods {
	BankID = 'BankID',
	FrejaeID = 'FrejaeID',
}

export enum LoginTypes {
	Admin = 2,
}

export enum PaymentPeriods {
	Monthly = 'Monthly',
	Quarter = 'Quarter',
	Year = 'Year',
}

export enum PaymentMethods {
	Invoice = 'Invoice',
}

export enum RoleTypesEmail {
	BusinessAdmin = 'BusinessAdmin',
	Approver = 'Approver',
	User = 'User',
	All = 'All',
}
