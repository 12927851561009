import { Box, Checkbox as MuiCheckbox } from "@mui/material";
import React, { useState } from "react";
import KycCheckboxLabel from "view/subcomponents/input/checkbox/kyc-checkbox-label";
import { hasError } from "utils/form";

interface Props {
	invalid: any;
	labelSx?: any;
	field: any;
	name: string;
	label: string;
	labelProps?: any;
	customOnChange: any;
	customLabel?: any;
	formState: any;
	[x: string]: any;
}

const Checkbox = ({
	formState,
	customLabel: CustomLabel,
	invalid,
	labelSx,
	field,
	name,
	label,
	labelProps,
	customOnChange,
	...rest
}: Props) => {
	const [active, setActive] = useState(false);
	const [isTouched, setIsTouched] = useState(false);
	const error = hasError(invalid, formState.isSubmitted, active, isTouched);

	const onChange = (e) => {
		field.onChange(e);

		if (customOnChange) {
			customOnChange(e);
		}
	};

	const TextComponent = CustomLabel || KycCheckboxLabel;

	return (
		<Box
			component={"label"}
			sx={{
				userSelect: "none",
				cursor: "pointer",
				display: "inline-flex",
				alignItems: "center",
				color: error ? "error.main" : "primary.main",
				marginLeft: "-11px",
				marginRight: "16px",
				verticalAlign: "middle",
				WebkitTapHighlightColor: "transparent",
				".MuiCheckbox-root": {
					color: error ? "error.main" : "primary.main",
				},
				...labelSx,
			}}
		>
			<MuiCheckbox
				checked={field.value}
				onChange={onChange}
				onFocus={() => {
					setActive(true);
					if (!isTouched) {
						setIsTouched(true);
					}
				}}
				onBlur={() => {
					setActive(false);
					field.onBlur();
				}}
				{...rest}
			/>
			<TextComponent error={error} labelProps={labelProps}>
				{label}
			</TextComponent>
		</Box>
	);
};

export default Checkbox;
