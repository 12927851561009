import { BusinessAreas } from 'config/constants/business-areas';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LeftMenuAutocomplete } from 'view/subcomponents/containers/left-container/menu-items/left-menu-autocomplete';

const LeftContainerAutocompleteBusinessarea = () => {
	const { t } = useTranslation();

	return (
		<LeftMenuAutocomplete
			field={'businessArea'}
			options={Object.values(BusinessAreas).map(area => ({
				value: area,
				label: t(`businessAreas.${area}`),
			}))}
		/>
	);
};

export default LeftContainerAutocompleteBusinessarea;
