import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import ChangePaymentForm from "view/components/payments/subcomponents/dialog/change-payment-form";

const ChangePaymentModelDialog = ({ open, onClose, getData, index }) => {
	const close = () => {
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={close}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: "16px",
					padding: "8px",
					boxSizing: "border-box",
					maxWidth: "700px",
				},
			}}
		>
			<DialogTitle
				sx={{
					color: "primary.main",
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				{index === -2 ? "Skapa ny betalningsmodell" : "Uppdatera betalningsmodell"}
			</DialogTitle>
			<DialogContent>
				<ChangePaymentForm index={index} onClose={onClose} getData={getData} />
			</DialogContent>
		</Dialog>
	);
};
export default ChangePaymentModelDialog;
