import React from "react";
import { Box, Grid } from "@mui/material";
import InfoLeftContainer from "view/components/info/info-left-container";
import InfoRightContainer from "view/components/info/info-right-container";
import api from "api/api";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";

const Info = () => {
	return (
		<SectionWrapper method={api.getSystemMessages}>
			<InfoLeftContainer />
			<InfoRightContainer />
		</SectionWrapper>
	);
};

export default Info;
