import React from "react";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import ErrandsRightContainer from "view/components/errands/errands-right-container";
import api from "api/api";
import LeftContainer from "view/subcomponents/containers/left-container/left-container";

const Errands = () => {
	return (
		<SectionWrapper method={api.getRegisterErrands}>
		<LeftContainer button={true}/>
			<ErrandsRightContainer />
		</SectionWrapper>
	);
};

export default Errands;
