import React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { Box } from "@mui/material";
import { Children } from "types/children";

interface Props {
	id?: string;
	sx?: any;
	onSubmit?: any;
	disableKeys?: boolean;
	children: Children;
	methods: UseFormReturn<any, any>;
}

export const Form = ({ id, sx, onSubmit, disableKeys, children, methods }: Props) => {
	const checkKeyDown = (e) => {
		if (disableKeys) {
			if (e.code === "Enter" || e.code === "NumpadEnter") {
				e.preventDefault();
			}
		}
	};

	return (
		<FormProvider {...methods}>
			<Box
				component={"form"}
				onSubmit={methods.handleSubmit(onSubmit)}
				onKeyDown={(e) => checkKeyDown(e)}
				sx={{
					...sx,
				}}
				id={id}
			>
				{children}
			</Box>
		</FormProvider>
	);
};
