import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClientMailLog } from 'store/mail/use-get-client-mail-log';
import { TextContainer } from 'view/subcomponents/common/text-container';

interface ClientMailCardProps {
	mail: ClientMailLog;
}
export const ClientMailCard = ({ mail }: ClientMailCardProps) => {
	const [t] = useTranslation();

	const getStatus = () => {
		if (mail.success) {
			return t('Locations.mail.statusHasSend');
		} else if (!mail.hasSend) {
			return t('Locations.mail.statusScheduled');
		} else {
			return t('Locations.mail.statusError');
		}
	};

	return (
		<Grid container>
			<Grid item xs={4}>
				<TextContainer>{mail.email}</TextContainer>
			</Grid>
			<Grid item xs={4}>
				<TextContainer>{mail.data.subject}</TextContainer>
			</Grid>
			<Grid item xs={2}>
				<TextContainer>{getStatus()}</TextContainer>
			</Grid>
			<Grid item xs={2}>
				<TextContainer>{mail.updated_at.substring(0, 10)}</TextContainer>
			</Grid>
		</Grid>
	);
};
