import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Tab } from "@mui/material";
import RightContainerCard from "view/subcomponents/card/right-container-card/right-container-card";
import { useTranslation } from "react-i18next";
import { formatOrgNumber } from "utils/string";
import moment from "moment";
import { StatusBox } from "view/subcomponents/common/status-box";
import { ErrandQueueStatuses } from "config/constants/errand";
import RightContainerCardItem from "view/subcomponents/card/right-container-card/right-container-card-item";
import { TabContext, TabList } from "@mui/lab";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import { Children } from "types/children";

interface Props {
	children: Children;
	showStatus?: boolean;
	open?: boolean;
	setOpen?: (open: boolean) => void;
	showEdit?: boolean;
	useTab?: boolean;
	update?: any;
	errand: {
		id: string;
		companyOrg: string;
		companyName: string;
		created: string;
		hasBeenSigned: boolean;
		queueStatus: string;
		errandType: string;
	};
}

const ErrandsCard = ({ children, errand, showStatus = true, open, setOpen, showEdit, useTab, update }: Props) => {
	const updatedRef = useRef(false);
	const { t } = useTranslation();
	const [tabValue, setTabValue] = useState("1");
	const { hasFetched, data } = useQueryContext();
	const { id, companyOrg, companyName, created, hasBeenSigned, queueStatus, errandType } = errand;

	useEffect(() => {
		if (update) {
			if (open && hasFetched && updatedRef.current === false) {
				update(errand, data);
				updatedRef.current = true;
			} else if (!open) {
				updatedRef.current = false;
			}
		}
	}, [errand, data, update, open, hasFetched]);

	return (
		<RightContainerCard
			open={open}
			setOpen={setOpen}
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box display={"flex"}>
				<Box display={"flex"} flexDirection={"column"}>
					<Box
						sx={{
							fontWeight: "bold",
							fontSize: "20px",
							lineHeight: "1.8",
						}}
					>
						{companyName}
					</Box>
					<Box>{formatOrgNumber(companyOrg)}</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						flex: "1 1 0",
						fontWeight: "bold",
					}}
				>
					{showStatus && queueStatus === ErrandQueueStatuses.New ? (
						<StatusBox
							sx={{
								color: hasBeenSigned ? "success.main" : "primary.orange",
							}}
						>
							{hasBeenSigned ? "Signerad" : "Ej signerad"}
						</StatusBox>
					) : (
						<StatusBox
							sx={{
								color: queueStatus === ErrandQueueStatuses.Rejected ? "red.main" : "success.main",
							}}
						>
							{t(`ErrandQueueStatuses.${errandType}.${queueStatus}`)}
						</StatusBox>
					)}
				</Box>
			</Box>
			{open && (
				<>
					<Box>
						<Grid container>
							<Grid item xs={12}>
								<RightContainerCardItem
									title={"ID"}
									sx={{
										paddingBottom: "16px",
									}}
								>
									{id}
								</RightContainerCardItem>
							</Grid>
							<Grid item xs={12}>
								<RightContainerCardItem
									title={"Skapad"}
									sx={{
										paddingBottom: "16px",
									}}
								>
									{moment(created || "NaN").format("YYYY-MM-DD")}
								</RightContainerCardItem>
							</Grid>
						</Grid>
					</Box>
					{useTab ? (
						<TabContext value={tabValue}>
							<TabList
								onChange={(_, value) => setTabValue(value)}
								sx={{
									".MuiTab-root": {
										textTransform: "none",
										fontWeight: "bold",
										fontSize: "18px",
									},
								}}
							>
								<CustomTab label={t("Errand")} value={"1"} />
								{showEdit && <CustomTab label={t("Rewrite")} value={"2"} />}
							</TabList>

							{children}
						</TabContext>
					) : (
						children
					)}
				</>
			)}
		</RightContainerCard>
	);
};

const CustomTab = ({ ...rest }) => {
	const { pending } = useQueryContext();
	return <Tab {...rest} disabled={pending} />;
};

export default ErrandsCard;
