import RightContainer from 'view/subcomponents/containers/right-container/right-container';
import { Box, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList } from '@mui/lab';
import PageTitle from 'view/subcomponents/common/page-title';
import { MailHistory } from './mail-history';
import { WriteMail } from './write-mail';
import { useGetMail } from 'store/mail/use-get-mail';
import { NoMailCard } from './no-mail-card';
import { MailCard } from './mail-card';

export const MailRightContainer = ({ tabValue, setTabValue, status }) => {
	const { t } = useTranslation();
	const getMail = useGetMail(status);
	const mailList = (tabValue === '2' && getMail.data) ?? [];

	const showMailList = mailList => {
		if (tabValue === '1') {
			return null;
		}
		return mailList.length > 0 ? (
			<Box
				sx={{
					paddingTop: '32px',
					flex: '1 1 0',
				}}
			>
				{mailList.map((mail, key) => (
					<MailCard mail={mail} key={key} />
				))}
			</Box>
		) : (
			<NoMailCard />
		);
	};

	return (
		<RightContainer
			disableActions={true}
			fullHeight={true}
			disableBackground={true}
			hasScroll={false}
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					backgroundColor: '#fff',
					padding: '32px',
					borderRadius: '30px',
				}}
			>
				<PageTitle>{'Mail'}</PageTitle>
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList
							onChange={(_, value) => setTabValue(value)}
							sx={{
								'.MuiTab-root': { textTransform: 'none', fontWeight: 'bold', fontSize: '18px' },
							}}
						>
							<Tab label={t('SendMail')} value={'1'} />
							<Tab label={t('MailHistory')} value={'2'} />
						</TabList>
					</Box>
					<WriteMail />
					<MailHistory />
				</TabContext>
			</Box>
			{showMailList(mailList)}
		</RightContainer>
	);
};
