import React, { useEffect, useRef, useState } from "react";
import { Box, Collapse } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import { Form } from "view/subcomponents/form";
import PendingButton from "view/subcomponents/buttons/pending-button";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import LoginSchema from "utils/validation/schema/login-schema";
import { useInterval } from "view/subcomponents/hooks/use-interval";
import api from "api/api";
import { AllowedLoginPollingCount, PollStatuses } from "config/constants/login";
import { useLoginStatus } from "view/subcomponents/hooks/use-login-status";
import { useHistory } from "react-router-dom";
import { setTokenResponse } from "utils/cookie";
import { RouterPaths } from "config/constants";
import Tokens from "config/constants/tokens";
import { LoginMethods, LoginTypes } from "config/constants/common";

const Home = () => {
	const [timer, setTimer] = useState(null);
	const [pending, setPending] = useState(false);
	const [timerCount, setTimerCount] = useState(0);
	const [data, setData] = useState(null);
	const { t } = useTranslation();
	const mountedRef = useRef(true);
	const { push } = useHistory();

	const { getValues, ...methods } = useForm({
		resolver: yupResolver(LoginSchema),
		defaultValues: {
			identityNumber: "",
		},
	});

	const onSubmit = async ({ identityNumber }) => {
		setPending(true);
		await api.initializeAuthentication({ pnr: identityNumber, loginMethod: LoginMethods.FrejaeID }).then((res) => {
			if (res.ok) {
				setTimer(2000);
				setData(res.data);
			}
		});
	};

	useInterval(async () => {
		if (mountedRef.current) {
			if (orderRef) {
				let loginData = {
					orderRef: orderRef,
					type: LoginTypes.Admin,
				};

				await api.poll(loginData).then((res: any) => {
					if (res.ok && mountedRef.current) {
						setTimerCount(timerCount + 1);
						if (timerCount > AllowedLoginPollingCount) {
							setData({
								progressStatusCode: PollStatuses.AllowedPollingCountExceeded,
							});
						} else {
							setData(res.data);
						}
					} else {
						setData({
							progressStatusCode: PollStatuses.FAILED,
						});
					}
				});
			}
		}
	}, timer);

	const { progressStatusCode, orderRef } = data ?? {};

	useEffect(() => {
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const { success, failure } = useLoginStatus(progressStatusCode);

	useEffect(() => {
		if (failure && mountedRef.current) {
			setTimer(null);
			setPending(false);
		}
	}, [failure, setTimer]);

	useEffect(() => {
		if (success && mountedRef.current) {
			setTimer(null);
			const { tokenResponse } = data;
			setTokenResponse(tokenResponse, Tokens.Admin);
			push(RouterPaths.Dashboard);
		}
	}, [getValues, success, setTimer, data, push]);

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				backgroundImage: "linear-gradient(to bottom, #8753cf 4%, #000000)",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					minWidth: "340px",
					backgroundColor: "#fff",
					borderRadius: "8px",
					padding: "40px",
					textAlign: "center",
				}}
			>
				<Box
					sx={{
						fontWeight: "bold",
						fontSize: "20px",
						paddingBottom: "16px",
					}}
				>
					{t("LoginWithFrejaEid")}
				</Box>
				<Form onSubmit={onSubmit} methods={{ ...methods, getValues }}>
					<Box pb={2}>
						<KycFormInput name={"identityNumber"} inputName={"identityNumber.Person"} labelPlacement={"center"} />
					</Box>
					<Box width={"100%"}>
						<Collapse in={pending} timeout={100} unmountOnExit>
							<Box
								sx={{
									paddingBottom: "8px",
									paddingTop: "8px",
								}}
							>
								{t(`LoginFlow.ProgressStatuses.FrejaeID.${progressStatusCode}`)}
							</Box>
						</Collapse>
						<PendingButton
							type={"submit"}
							pending={pending}
							variant={"purple"}
							sx={{
								width: "100%",
							}}
						>
							{t("Authenticate")}
						</PendingButton>
					</Box>
				</Form>
			</Box>
		</Box>
	);
};

export default Home;
