import React from 'react';
import SectionWrapper from 'view/subcomponents/wrappers/section-wrapper';
import ClientsLeftContainer from 'view/components/clients/clients-left-container';
import ClientsRightContainer from 'view/components/clients/clients-right-container';
import api from 'api/api';
import { ClientsProvider } from '../../subcomponents/providers/clients-provider';

const Clients = () => {
	return (
		<SectionWrapper method={api.getClients}>
			<ClientsLeftContainer />
			<ClientsProvider>
				<ClientsRightContainer />
			</ClientsProvider>
		</SectionWrapper>
	);
};

export default Clients;
