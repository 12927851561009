import { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useInterval } from 'view/subcomponents/hooks/use-interval';
import { useDebounce } from 'view/subcomponents/hooks/use-debounce';
import { ExternalOptions } from 'types/external-options';
import { isNullOrUndefined } from 'utils/common';
import { QueryMethod } from 'view/subcomponents/providers/query-provider';

export interface IQueryProvider {
	fetchCount: number;
	hasRefetched: boolean;
	addItem: (value: any, key?: string, asFirst?: boolean) => void;
	updateByIndex: (index: number, newData: any) => void;
	hasFetched: boolean;
	pending: boolean;
	error: boolean;
	data: any;
	refetch: (newData?: any) => void;
	isValid: boolean;
	reset: () => void;
	setData: (data: any) => void;
	isEmpty: boolean;
	updateData: (data: any) => void;
	removeItem: (index: number) => void;
}

const useQuery = (
	method: QueryMethod,
	externalOptions?: ExternalOptions,
	disabled?: boolean,
	emptyKey?: string,
	validation?: (any) => boolean,
): IQueryProvider => {
	const methodRef = useRef(method);
	const delay = useRef<number>(externalOptions?.delay ?? 0);
	const currentRequest = useRef<string>('');
	const [pending, setPending] = useState<boolean>(disabled ?? true);
	const [hasRefetched, setHasRefetched] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [data, setData] = useState(null);
	const [options] = useState<ExternalOptions>({
		...externalOptions,
	});

	const inputData = useRef(externalOptions?.inputData ?? {});
	const hasFetched = useRef<boolean>(false);
	const mountedRef = useRef<boolean>(true);
	const initialFetch = useRef<boolean>(false);
	const fetchCount = useRef<number>(0);

	useEffect(() => {
		if (method == null) {
			setPending(false);
		}

		if (hasFetched.current && !isEqual(methodRef.current, method)) {
			setData(null);
			methodRef.current = method;
			setPending(true);
		}
	}, [method]);

	useEffect(() => {
		mountedRef.current = true;
		return () => {
			mountedRef.current = false;
		};
	}, []);

	const getValidation = useCallback(() => {
		if (!isNullOrUndefined(validation)) {
			return validation(externalOptions?.inputData);
		}

		return true;
	}, [externalOptions?.inputData, validation]);

	const getData = useCallback(

		(isInterval = false, data = null) => {
			if (method && !disabled && (isInterval || hasFetched.current === false)) {
				const key = uuidv4();
				currentRequest.current = key;
				const validationResult = getValidation();

				if (validationResult) {
					setPending(true);
					setError(false);

					method(data ?? inputData?.current).then(res => {
						if (mountedRef.current && key === currentRequest.current) {
							if (res.ok) {
								hasFetched.current = true;
								setData(res.data);
							} else {
								setError(true);
							}
							if (mountedRef.current === true) {
								setPending(false);
							}
							initialFetch.current = true;
						}
					});
					fetchCount.current = fetchCount.current + 1;
				}
			}
		},
		[disabled, getValidation, method],
	);

	useInterval(() => getData(true), options?.interval ?? null);

	useDebounce(
		() => {
			getData(delay.current > 0, externalOptions?.inputData);
		},
		[getData, disabled, externalOptions?.inputData],
		delay.current,
	);

	const refetch = useCallback(
		async (newData?: any) => {
			await getData(true, newData);
			setHasRefetched(true);
		},
		[getData],
	);

	const reset = useCallback(() => {
		setData(null);
		setError(false);
		setPending(false);
		hasFetched.current = false;
	}, []);

	useEffect(() => {
		if (disabled) {
			reset();
		}
	}, [disabled, reset]);

	const updateByIndex = useCallback(
		(index, newData) => {
			let copy = [...data];
			copy[index] = newData;
			setData(copy);
		},
		[data, setData],
	);

	const updateData = useCallback(
		newData => {
			setData({
				...data,
				...newData,
			});
		},
		[data],
	);

	const addItem = useCallback(
		(value, key, asFirst) => {
			if (key) {
				setData({
					...data,
					[key]: [...data[key], value],
				});
			} else {
				if (asFirst) {
					setData([value, ...data]);
				} else {
					setData([...data, value]);
				}
			}
		},
		[setData, data],
	);

	const isEmpty = useCallback(() => {
		if (disabled) {
			return false;
		}

		if (emptyKey) {
			return data !== null && data?.[emptyKey].length === 0;
		}

		return data != null && data?.length === 0;
	}, [data, disabled, emptyKey]);

	const removeItem = useCallback(
		removeIndex => {
			const filtered = data?.filter(function (value, index) {
				return removeIndex !== index;
			});
			setData(filtered);
		},
		[setData, data],
	);

	return {
		fetchCount: fetchCount?.current ?? 0,
		hasRefetched,
		addItem,
		updateByIndex,
		hasFetched: hasFetched.current,
		pending,
		error,
		data,
		refetch,
		isValid: method === undefined || (!pending && !error && data != null),
		reset,
		setData,
		isEmpty: isEmpty(),
		updateData,
		removeItem,
	};
};

export default useQuery;
