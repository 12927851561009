import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

export const NoData = () => {
	const { t } = useTranslation();
	return (
		<Box display={'flex'} flexDirection={'column'} sx={{ display: 'flex', textAlign: 'center' }}>
			<Box
				sx={{
					position: 'relative',

					backgroundColor: '#fff',
					borderRadius: '24px',
					color: 'primary.main',
					padding: '24px',
					fontWeight: 'bold',
					fontSize: '20px',
				}}
			>
				{t('Locations.clientLogos.noDataInfo')}
			</Box>
		</Box>
	);
};
