import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { useTranslation } from 'react-i18next';
import TabPanel from 'view/subcomponents/tab-panels/tab-panel';
import RightContainerCardItem from 'view/subcomponents/card/right-container-card/right-container-card-item';
import moment from 'moment';
import RightContainerCardHeader from 'view/subcomponents/card/right-container-card/right-container-card-header';
import { formatCardString, formatOrgNumber } from 'utils/string';
import { getClientStatus } from 'utils/client';
import { InternalPendingButton } from 'view/subcomponents/buttons/internal-pending-button';
import api from 'api/api';
import { CircularProgressWithText } from 'view/subcomponents/common/circular-progress-with-text';
import { ApiResponse } from 'apisauce';

const AgreementCardPanelOne = () => {
	const { data, pending } = useQueryContext();
	const { t } = useTranslation();

	if (pending || data === null) {
		return (
			<Box p={4}>
				<CircularProgressWithText text={'Hämtar avtal...'} />
			</Box>
		);
	}

	const {
		agreementId,
		created_at,
		onboardingName,
		onboardingEmail,
		onboardingIdentityNumber,
		onboardingPhoneNumber,
		implementationHelp,
		vatType,
		currency,
		client,
		payment_model,
		paymentPeriod,
		paymentMethod,
		paymentInfo,
		franchiseName,
	} = data;

	const { clientNumber, businessArea, name, org, activeUntil, paused } = client ?? {};
	const { email, emailCopy, address, postalCode, county } = paymentInfo ?? {};
	const { title, status } = getClientStatus(paused, activeUntil);

	const onClick = async variant => {
		const fileUrl = await api.getAgreementPDF({ id: agreementId, variant: variant }).then((res: ApiResponse<any>) => {
			if (res.ok) {
				const file = new Blob([res.data], {
					type: 'application/pdf',
				});
				//Build a URL from the file
				return URL.createObjectURL(file);
			}

			return null;
		});

		if (fileUrl !== null) {
			const pdfWindow = window.open(); // TODO kanske lägga in en loading screen?
			if (pdfWindow) {
				pdfWindow.location.href = fileUrl;
			}
		}
	};

	return (
		<TabPanel value={'1'}>
			<Grid container item xs={12}>
				<Grid item xs={12}>
					<RightContainerCardItem title={'Avtal ID'}>{agreementId}</RightContainerCardItem>
				</Grid>
				<Grid item xs={12}>
					<RightContainerCardItem title={'Skapad'}>{moment(created_at).format('YYYY-MM-DD')}</RightContainerCardItem>
				</Grid>
				<Grid item xs={6}>
					<RightContainerCardHeader>Avtalsinformation</RightContainerCardHeader>
					<RightContainerCardItem title={'Momstyp'}>{vatType}</RightContainerCardItem>
					<RightContainerCardItem title={'Valuta'}>{currency}</RightContainerCardItem>
					<RightContainerCardItem title={'Betalningsmodell'}>{payment_model?.name ?? '-'}</RightContainerCardItem>
					<RightContainerCardItem title={'Verksamhetsområde'}>
						{t(`businessAreas.${businessArea}`)}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Fakturaintervall'}>
						{t(`paymentPeriod.${paymentPeriod}`)}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Betalningsmetod'}>
						{t(`paymentMethods.${paymentMethod}`)}
					</RightContainerCardItem>

					<RightContainerCardItem title={'Implementeringshjälp'}>
						{implementationHelp ? 'Ja' : 'Nej'}
					</RightContainerCardItem>
				</Grid>
				<Grid item xs={6}>
					<RightContainerCardHeader>Företagsinformation</RightContainerCardHeader>
					<RightContainerCardItem title={'Namn'}>{name}</RightContainerCardItem>
					<RightContainerCardItem title={'Varumärke'}>{franchiseName}</RightContainerCardItem>
					<RightContainerCardItem title={'Organisationsnummer'}>{formatOrgNumber(org)}</RightContainerCardItem>
					<RightContainerCardItem title={'Kundnummer'}>{clientNumber}</RightContainerCardItem>
					<RightContainerCardItem
						title={'Status'}
						innerSx={{
							color: status ? 'success.main' : 'error.main',
							fontWeight: '500',
						}}
					>
						{title}
					</RightContainerCardItem>
					<Divider
						sx={{
							marginTop: '8px',
							marginBottom: '8px',
						}}
					/>
				</Grid>
				<Grid item xs={6}>
					<RightContainerCardHeader>Fakturauppgifter</RightContainerCardHeader>
					<RightContainerCardItem title={'Fakturadress'}>{formatCardString(address)}</RightContainerCardItem>
					<RightContainerCardItem title={'Postnummer'}>{formatCardString(postalCode)}</RightContainerCardItem>
					<RightContainerCardItem title={'Ort'}>{formatCardString(county)}</RightContainerCardItem>
					<Divider
						sx={{
							marginTop: '8px',
							marginBottom: '8px',
							marginRight: '32px',
						}}
					/>
					<RightContainerCardItem title={'E-post för faktura'}>{formatCardString(email)}</RightContainerCardItem>
					<RightContainerCardItem title={'E-post kopia för faktura'}>
						{formatCardString(emailCopy)}
					</RightContainerCardItem>
				</Grid>
				<Grid item xs={6}>
					<RightContainerCardHeader>Kontaktperson</RightContainerCardHeader>
					<RightContainerCardItem title={'Namn'}>{formatCardString(onboardingName)}</RightContainerCardItem>
					<RightContainerCardItem title={'E-post'}>{formatCardString(onboardingEmail)}</RightContainerCardItem>
					<RightContainerCardItem title={'Identitetsnummer'}>
						{formatCardString(onboardingIdentityNumber)}
					</RightContainerCardItem>
					<RightContainerCardItem title={'Telefonnummer'}>
						{formatCardString(onboardingPhoneNumber)}
					</RightContainerCardItem>
				</Grid>

				<Grid
					item
					xs={12}
					sx={{
						marginTop: '16px',
						paddingTop: '16px',
						paddingBottom: '16px',
						marginBottom: '16px',
						borderTop: '1px solid',
						borderBottom: '1px solid',
						borderColor: 'divider',
					}}
				>
					<InternalPendingButton
						variant={'purple'}
						onClick={() => onClick('initial')}
						sx={{
							marginRight: '16px',
						}}
					>
						Ladda ned initialt avtalsbekräftelse
					</InternalPendingButton>
					<InternalPendingButton variant={'purple'} onClick={() => onClick('current')}>
						Ladda ned nuvarande avtalsbekräftelse
					</InternalPendingButton>
				</Grid>
			</Grid>
		</TabPanel>
	);
};

export default AgreementCardPanelOne;
