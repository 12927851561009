import { useEffect } from "react";
import { useIsAuthenticated } from "view/subcomponents/hooks/use-is-authenticated";
import { useHistory } from "react-router-dom";
import { RouterPaths } from "config/constants";

export const SessionManager = ({ children }) => {
	const isAuthenticated = useIsAuthenticated();
	const { push } = useHistory();

	useEffect(() => {
		if (!isAuthenticated) {
			push({
				pathname: RouterPaths.Home,
				state: {
					sessionEnded: true,
				},
			});
		}
	}, [isAuthenticated, push]);

	return children;
};
