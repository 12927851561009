import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';

const ServiceAPI = {
	getSystemMessages: data => apiInstance.get('/api/service/getSystemMessages', data, headers()),
	getUserEmails: data => apiInstance.get('/api/service/getUserEmails', data, headers()),
	createClientCSVInfo: data => apiInstance.post('/api/service/createClientCSVInfo', data, headers()),
	createClientCSVMonthInfo: data => apiInstance.post('/api/service/createClientCSVMonthInfo', data, headers()),
	addSystemMessage: data => apiInstance.post('/api/service/addSystemMessage', data, headers()),
	updateSystemMessageStatus: data => apiInstance.post('/api/service/updateSystemMessageStatus', data, headers()),
};

export default ServiceAPI;
