import DialogWrapper from 'view/subcomponents/dialog/dialog-wrapper';
import ClientUser from 'view/components/clients/subcomponents/client-users/client-user';
import { ScrollableWrapper } from 'view/subcomponents/scroll/scrollable-wrapper';
import { useClients } from '../../../../subcomponents/providers/clients-provider';

const ClientUsersDialog = () => {
	const { setUserData, userData } = useClients();
	const setOpen = () => {
		setUserData(null);
	};

	if (!userData) {
		return null;
	}

	const updateData = (index: number, temp) => {
		const { setData } = userData;

		const users = userData?.users;
		users[index] = {
			...users[index],
			...temp,
		};

		setData({
			...userData,
			users: users,
		});
	};

	return (
		<DialogWrapper
			open={true}
			setOpen={setOpen}
			title={'Hantera användare'}
			sx={{
				maxWidth: '800px',
				padding: '32px',
			}}
		>
			<ScrollableWrapper>
				{userData?.users?.map((user, i) => (
					<ClientUser key={i} user={user} index={i} updateData={updateData} />
				))}
			</ScrollableWrapper>
		</DialogWrapper>
	);
};
export default ClientUsersDialog;
