import { Box } from '@mui/material';
import { ClientLogoInfo } from 'store/client-logo/use-get-client-logos';
import { ClientLogoCard } from 'view/components/clientLogos/clientLogo-card';
import { NoData } from 'view/components/clientLogos/clientLogos-no-data';

interface ClientLogosRightLogoContainerProps {
	activeClientLogos: ClientLogoInfo[];
}

export const ClientLogosRightLogoContainer = ({ activeClientLogos }: ClientLogosRightLogoContainerProps) => {
	if (activeClientLogos.length === 0) {
		return <NoData />;
	}

	return (
		<Box
			sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{activeClientLogos.map((logo, index) => (
				<ClientLogoCard clientLogoInfo={logo} key={index} />
			))}
		</Box>
	);
};
