import apiInstance from "api/api-instance";
import headers from "api/util/headers";

const UserAPI = {
	addNewUser: (data) =>
		apiInstance.post("api/user/admin/addNewUser", data, headers()),
	getUserInfo: () =>
		apiInstance.get("/api/user/getUserInfo", null, headers()),
	getAdminUsers: (data) =>
		apiInstance.get("/api/user/getAdminUsers", data, headers()),
	addNewAdminUser: (data) =>
		apiInstance.post("/api/user/addNewAdminUser", data, headers()),
	updateAdminUser: (data) =>
		apiInstance.post("/api/user/updateAdminUser", data, headers()),
	changeStatusClientUser: (data) =>
		apiInstance.post("/api/user/changeStatusClientUser", data, headers()),
	changeArchivedStatusClientUser: (data) =>
		apiInstance.post("/api/user/changeArchivedStatusClientUser", data, headers()),

};

export default UserAPI;
