import { useEffect, useMemo, useState } from "react";

export const useOnScreen = (ref, initialValue) => {
	const [isIntersecting, setIntersecting] = useState(initialValue);

	const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), []);

	useEffect(() => {
		if (ref && ref.current) {
			observer.observe(ref.current);
		}

		// Remove the observer as soon as the component is unmounted
		return () => {
			observer.disconnect();
		};
	}, [ref, observer]);

	if (ref === undefined) {
		return true;
	}

	return isIntersecting;
};
