import React from "react";
import { Box, Grid } from "@mui/material";
import RightContainerCard from "view/subcomponents/card/right-container-card/right-container-card";
import { InternalPendingButton } from "view/subcomponents/buttons/internal-pending-button";
import { StatusBox } from "view/subcomponents/common/status-box";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface Props {
	item: any;
	onClick: (item: any, index: number) => void;
	index: number;
	isFirst: boolean;
}

const InfoCard = ({ item, onClick, index, isFirst }: Props) => {
	const { message, type, active, created_at } = item;
	const { t } = useTranslation();

	console.log(item)

	const getOptions = () => {};

	return (
		<RightContainerCard
			sx={{
				paddingLeft: "32px",
				paddingRight: "32px",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Grid container>
				<StatusBox>{t(`ServiceTypes.${type}`)}</StatusBox>
				<Grid
					item
					xs={12}
					sx={{
						fontSize: "18px",
						fontWeight: "bold",
						paddingBottom: "8px",
						display: "flex",
						flexDirection: "column",
						paddingTop: "8px",
					}}
				>
					Servicemeddelande
				</Grid>
				<Grid item xs={12} pb={1}>
					Skapad: {moment(created_at || "NaN").format("YYYY-MM-DD HH:MM")}
				</Grid>

				<Grid
					item
					xs={12}
					sx={{
						paddingBottom: "8px",
						marginBottom: "8px",
						borderBottom: "1px solid",
						borderColor: "divider",
					}}
				>
					Meddelande: {message}
				</Grid>
				{
					<Grid
						item
						xs={12}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<InternalPendingButton variant={active || active === 1 ? "purple" : "purple-outlined"}
											   onClick={() => onClick(item, index)}>
							{active || active === 1 ? "Avaktivera" : "Aktivera"}
						</InternalPendingButton>
					</Grid>
				}
			</Grid>
		</RightContainerCard>
	);
};

export default InfoCard;
