import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { Box, Tab } from '@mui/material';
import { CircularProgressWithText } from 'view/subcomponents/common/circular-progress-with-text';
import ClientCardContent from 'view/components/clients/subcomponents/client-card/client-card-content';
import { TabContext, TabList } from '@mui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientCardContentPanelTwo from './client-card-content-panel-two';
import { ClientCardMailLog } from './client-card-mail-log';

interface ClientCardContentWrapperProps {
	clientId: string;
}
const ClientCardContentWrapper = ({ clientId }: ClientCardContentWrapperProps) => {
	const { pending } = useQueryContext();
	const [tabValue, setTabValue] = useState('1');
	const { t } = useTranslation();

	if (pending) {
		return (
			<Box
				sx={{
					width: '100%',
				}}
			>
				<CircularProgressWithText text={'Hämtar klient...'} />
			</Box>
		);
	}

	return (
		<Box sx={{ width: '100%' }}>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={(_, value) => setTabValue(value)}
						sx={{
							'.MuiTab-root': { textTransform: 'none', fontWeight: 'bold', fontSize: '18px' },
						}}
					>
						<ClientTab label={t('ClientInfo')} value={'1'} />
						<ClientTab label={t('ClientStatistics')} value={'2'} />
						<ClientTab label={t('ClientMailLog')} value={'3'} />
					</TabList>
				</Box>

				<ClientCardContent />
				{clientId && <ClientCardContentPanelTwo clientId={clientId} />}
				{clientId && <ClientCardMailLog clientId={clientId} />}
			</TabContext>
		</Box>
	);
};

const ClientTab = ({ ...rest }) => {
	const { pending } = useQueryContext();

	return <Tab {...rest} disabled={pending} />;
};

export default ClientCardContentWrapper;
