import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Children } from "types/children";

interface Props {
	disabled?: boolean;
	children: Children;
	pending?: boolean;
	[x: string]: any;
}

const PendingButton = ({ disabled, children, pending, ...rest }: Props) => {
	return (
		<Button disabled={pending || disabled} {...rest}>
			{pending ? <CircularProgress color={"inherit"} size={22} /> : children}
		</Button>
	);
};

export default PendingButton;
