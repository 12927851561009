import React from "react";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import UsersLeftContainer from "view/components/users/users-left-container";
import UsersRightContainer from "view/components/users/users-right-container";
import api from "api/api";

const Users = () => {
	return (
		<SectionWrapper method={api.getAdminUsers}>
			<UsersLeftContainer />
			<UsersRightContainer />
		</SectionWrapper>
	);
};

export default Users;
