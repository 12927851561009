import { useQueryContext } from "view/subcomponents/providers/query-provider";
import FranchiseErrandCard from "view/components/franchises/subcomponents/franchise-errand-card";

const FranchiseErrands = () => {
	const { data, removeItem } = useQueryContext();

	return data?.map((errand, index) => {
		return <FranchiseErrandCard franchiseErrand={errand} key={index} index={index} removeItem={removeItem} />;
	});
};

export default FranchiseErrands;
