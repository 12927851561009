import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { AllUsersTableRow } from './all-users-table-row';
import { ClientStatistics } from '../../../../../../../types/clients';

interface AllUsersTableProps {
	clientStatistics: ClientStatistics;
}

export const AllUsersTable = ({ clientStatistics }: AllUsersTableProps) => {
	const { t } = useTranslation();

	return (
		<Box component={'table'}>
			<Box
				component={'thead'}
				sx={{
					position: 'sticky',
					top: 0,
					textAlign: 'left',
					backgroundColor: '#fff',
				}}
			>
				<tr>
					<th />
					<th>{t('Started')}</th>
					<th>{t('Finished')}</th>
					<th>{t('Approved')}</th>
					<th>{t('Rejected')}</th>
					<th>{t('NeedAdditionalInfo')}</th>
					<th>{t('LastActivity')}</th>
				</tr>
			</Box>
			<tbody>
				{clientStatistics?.userStatistics?.map((user, index) => (
					<AllUsersTableRow key={index} user={user} />
				))}
			</tbody>
		</Box>
	);
};
