import React from "react";
import { Fade, IconButton } from "@mui/material";
import { KycNavigationIcon } from "view/subcomponents/icons/icons";

interface Props {
	show: boolean;
	sx: any;
	onClick: any;
	iconProps?: any;
}

export const ScrollButton = ({ show, sx, onClick, iconProps }: Props) => {
	return (
		<Fade in={show} timeout={250} unmountOnExit>
			<IconButton
				onClick={onClick}
				sx={{
					position: "absolute",
					right: "calc(50% - 25px)",
					color: "primary.main",
					zIndex: 1000,
					textTransform: "none",
					backgroundColor: "#fff",
					borderRadius: "32px",
					fontWeight: "bold",
					height: "50px",
					width: "50px",
					fontSize: "16px",
					boxShadow: "0 4px 18px 0 rgba(32, 73, 136, 0.2)",

					":hover": {
						backgroundColor: "#d3d3d3",
					},
					...sx,
				}}
			>
				<KycNavigationIcon variant={"medium"} {...iconProps} />
			</IconButton>
		</Fade>
	);
};
