import React from "react";
import { TabPanel } from "@mui/lab";
import { MessagePanel } from "view/subcomponents/common/message-panel/message-panel";

const NotificationsHandleNotifications = () => {
	return (
		<TabPanel value={"2"}>
			<MessagePanel
				type={"info"}
				title={"information"}
				sx={{
					textAlign: "left",

					color: "primary.main",
					fontWeight: "normal",
				}}
			>
				Nedan visas de notifikationer som har blivit skapade i systemet. <br /> När en notis avaktiveras kommer den inte längre att
				för kunder. Du kan även ändra titel samt meddelande genom att trycka på knappen 'ändra'
			</MessagePanel>
		</TabPanel>
	);
};

export default NotificationsHandleNotifications;
