import React, { useState } from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import FranchisesNewFranchise from "view/components/franchises/subcomponents/franchises-new-franchise";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import FranchiseCard from "view/components/franchises/subcomponents/franchise-card";
import ChangeFranchiseDialog from "view/subcomponents/dialog/change-franchise-dialog";
import { TabContext, TabPanel } from "@mui/lab";
import FranchiseErrands from "view/components/franchises/subcomponents/franchise-errands";

const FranchisesRightContainer = ({ tab, updateTab }) => {
	const [changeData, setChangeData] = useState(null);
	const { data } = useQueryContext();

	return (
		<RightContainer
			displayTopInfo={false}
			hasScroll={true}
			fullHeight={true}
			disableBackground={true}
			sx={{
				".MuiTabPanel-root": {
					padding: 0,
				},
			}}
			topContent={<FranchisesNewFranchise tab={tab} updateTab={updateTab} />}
		>
			<TabContext value={tab}>
				<TabPanel value={"1"}>
					{data?.map((franchise, index) => (
						<FranchiseCard key={index} franchise={franchise} index={index} setChangeData={setChangeData} />
					))}
					<ChangeFranchiseDialog changeData={changeData} setChangeData={setChangeData} />
				</TabPanel>
				<TabPanel value={"2"}>
					<FranchiseErrands />
				</TabPanel>
			</TabContext>
		</RightContainer>
	);
};

export default FranchisesRightContainer;
