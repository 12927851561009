import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { TextContainer } from 'view/subcomponents/common/text-container';
import RightContainerCard from 'view/subcomponents/card/right-container-card/right-container-card';

export const NoMailCard = () => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				paddingTop: '32px',
				flex: '1 1 0',
			}}
		>
			<RightContainerCard
				sx={{
					display: 'flex',
				}}
			>
				<Box flex={'1 1 0'}>
					<TextContainer fontSize={18} fontWeight={'bold'}>
						{t('NoMailToShow')}
					</TextContainer>
				</Box>
			</RightContainerCard>
		</Box>
	);
};
