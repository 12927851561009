import { useQuery } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';
import { queryKeys } from 'store/query-keys';

export interface CustomerInformationData {
	kycParticipantId: string;
	email: string;
	status: string;
	updated_at: string;
	url?: string;
}

export const useGetCustomerInformationWithKycId = kycId => {
	const result = useQuery({
		queryKey: [queryKeys.customerInformation, kycId],
		queryFn: async () => {
			const getClientStatistics = (): Promise<ApiResponse<CustomerInformationData[], CustomerInformationData[]>> =>
				apiInstance.get<CustomerInformationData[]>(apiRoutes.customerInformation.getCustomerInformationWithKycId(kycId), {}, headers());
			const result = await getClientStatistics();
			return result.data;
		},
		refetchOnWindowFocus: false,
		staleTime: 3600000 
	});

	return result;
};