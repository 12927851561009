import { useState } from 'react';
import { Collapse, Grid } from '@mui/material';
import parse from 'html-react-parser';
import { TextContainer } from 'view/subcomponents/common/text-container';
import RightContainerCard from 'view/subcomponents/card/right-container-card/right-container-card';
import { AdminMailInfo } from 'store/mail/use-get-mail';

interface MailCardProps {
	mail: AdminMailInfo;
}

export const MailCard = ({ mail }: MailCardProps) => {
	const [open, setOpen] = useState(false);

	return (
		<RightContainerCard
			open={open}
			setOpen={setOpen}
			sx={{
				display: 'flex',
			}}
		>
			<Grid container>
				<Grid item xs={9}>
					<TextContainer fontSize={18} fontWeight={'bold'} color="rgba(0, 0, 0, 0.6)">
						{`Till: ${mail.to.map(t => ` ${t}`)}`}
					</TextContainer>
				</Grid>

				<Grid item xs={3}>
					<TextContainer fontSize={18} fontWeight={'bold'} color="rgba(0, 0, 0, 0.6)">
						{mail.updated_at.substring(0, 10)}
					</TextContainer>
				</Grid>
				<Grid item xs={12}>
					<TextContainer
						fontSize={18}
						fontWeight={'bold'}
						color="rgba(0, 0, 0, 0.6)"
						sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
					>
						{`Ämne: ${mail.data.subject}`}
					</TextContainer>
				</Grid>
				<Grid item xs={12}>
					<Collapse in={open} unmountOnExit>
						<TextContainer fontSize={18} fontWeight={'bold'} sx={{ paddingTop: '10px' }}>
							{mail.data.title}
						</TextContainer>
						<TextContainer sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
							{parse(mail.data.infoText)}
						</TextContainer>

						{mail.data.image && <TextContainer>{`Bildtyp: ${mail.data.image}`}</TextContainer>}
						{mail.data.url && <TextContainer>{`Knapp: ${mail.data.urlText} - ${mail.data.url}`}</TextContainer>}
					</Collapse>
				</Grid>
			</Grid>
		</RightContainerCard>
	);
};
