import AuthAPI from "api/http/auth";
import ClientAPI from "api/http/client";
import StatisticsAPI from "api/http/dashboard";
import ErrandAPI from "api/http/errand";
import FileAPI from "api/http/file";
import NotificationAPI from "api/http/notification";
import BillingAPI from "api/http/billing";
import SignAPI from "api/http/sign";
import UserAPI from "api/http/user";
import RegisterAPI from "api/http/register";
import PaymentAPI from "api/http/payment";
import AgreementAPI from "api/http/agreement";
import FranchiseAPI from "api/http/franchise";
import KycAPI from "api/http/kyc";
import ServiceAPI from "api/http/service";
import CustomResourcesAPI from "api/http/customResources";
import CountriesAPI from "api/http/countries";
import ClientLogoAPI from './http/clientLogo';

const api = {
    ...AuthAPI,
    ...ClientAPI,
    ...StatisticsAPI,
    ...ErrandAPI,
    ...FileAPI,
    ...NotificationAPI,
    ...BillingAPI,
    ...SignAPI,
    ...UserAPI,
    ...RegisterAPI,
    ...PaymentAPI,
    ...AgreementAPI,
    ...FranchiseAPI,
    ...KycAPI,
    ...ServiceAPI,
    ...CustomResourcesAPI,
    ...CountriesAPI,
	...ClientLogoAPI,
};

export default api;
