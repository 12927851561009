// @ts-nocheck
import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as ActionIcon } from 'assets/icons/action.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as NegativeIcon } from 'assets/icons/negative.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CaretIcon } from 'assets/icons/caret.svg';
import { ReactComponent as AttentionIcon } from 'assets/icons/attention.svg';
import { ReactComponent as NavigationIcon } from 'assets/icons/navigation.svg';
import { ReactComponent as MobileIcon } from 'assets/icons/phone.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as BookIcon } from 'assets/icons/book.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/cloud.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as StatsIcon } from 'assets/icons/stats.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';

export const KycSettingsIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<SettingsIcon />
		</SvgIcon>
	);
};

export const KycStatsIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<StatsIcon />
		</SvgIcon>
	);
};

export const KycCheckIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<CheckIcon />
		</SvgIcon>
	);
};

export const KycCloudIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<CloudIcon />
		</SvgIcon>
	);
};

export const KycBookIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<BookIcon />
		</SvgIcon>
	);
};

export const KycPersonIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<PersonIcon />
		</SvgIcon>
	);
};

export const KycQuestionIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<QuestionIcon />
		</SvgIcon>
	);
};

export const KycPlusIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<PlusIcon />
		</SvgIcon>
	);
};

export const KycChatIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<ChatIcon />
		</SvgIcon>
	);
};

export const KycMailIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<MailIcon />
		</SvgIcon>
	);
};

export const KycPhoneIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<MobileIcon />
		</SvgIcon>
	);
};

export const KycActionIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<ActionIcon />
		</SvgIcon>
	);
};

export const KycNegativeIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<NegativeIcon />
		</SvgIcon>
	);
};

export const KycAddIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<AddIcon />
		</SvgIcon>
	);
};

export const KycHomeIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<HomeIcon />
		</SvgIcon>
	);
};

export const KycSuccessIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<SuccessIcon />
		</SvgIcon>
	);
};

export const KycCloseIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<CloseIcon />
		</SvgIcon>
	);
};

export const KycSearchIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<SearchIcon />
		</SvgIcon>
	);
};

export const KycCaretIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<CaretIcon />
		</SvgIcon>
	);
};

export const KycAttentionIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<AttentionIcon />
		</SvgIcon>
	);
};

export const KycNavigationIcon = ({ ...props }) => {
	return (
		<SvgIcon {...props}>
			<NavigationIcon />
		</SvgIcon>
	);
};
