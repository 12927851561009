import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterPaths } from 'config/constants';
import { BrowserRouter, Switch } from 'react-router-dom';
import Agreements from 'view/components/agreements/agreements';
import { ClientLogos } from 'view/components/clientLogos/clientLogos';
import Clients from 'view/components/clients/clients';
import Dashboard from 'view/components/dashboard/dashboard';
import Errands from 'view/components/errands/errands';
import Franchises from 'view/components/franchises/franchises';
import Info from 'view/components/info/info';
import Invoices from 'view/components/invoices/invoices';
import Kyc from 'view/components/kyc/kyc';
import Home from 'view/components/main/home';
import Notifications from 'view/components/notifications/notifications';
import Payments from 'view/components/payments/payments';
import Users from 'view/components/users/users';
import UserInfoProvider from 'view/subcomponents/providers/user-info-provider';
import HomeRoute from 'view/subcomponents/router/home-route';
import ProtectedRoute from 'view/subcomponents/router/protected-route';
import RouterWrapper from 'view/subcomponents/wrappers/router-wrapper';
import Countries from './components/countries/countries';
import CustomerInfo from './components/customer-info/customer-info';
import Redirects from 'view/components/redirects/redirects';
import { Mail } from 'view/components/mail/mail';

export const CustomRouter = () => {
	return (
		<BrowserRouter>
			<ReactQueryDevtools initialIsOpen={false} />
			<UserInfoProvider>
				<RouterWrapper>
					<Switch>
						<HomeRoute exact path={RouterPaths.Home} component={Home} />
						<ProtectedRoute exact path={RouterPaths.Payments} component={Payments} />
						<ProtectedRoute exact path={RouterPaths.Info} component={Info} />
						<ProtectedRoute exact path={RouterPaths.CustomerInfo} component={CustomerInfo} />
						<ProtectedRoute exact path={RouterPaths.Dashboard} component={Dashboard} />
						<ProtectedRoute exact path={RouterPaths.Agreements} component={Agreements} />
						<ProtectedRoute exact path={RouterPaths.Kyc} component={Kyc} />
						<ProtectedRoute exact path={RouterPaths.Errands} component={Errands} />
						<ProtectedRoute exact path={RouterPaths.Clients} component={Clients} />
						<ProtectedRoute exact path={RouterPaths.Invoices} component={Invoices} />
						<ProtectedRoute exact path={RouterPaths.Notifications} component={Notifications} />
						<ProtectedRoute exact path={RouterPaths.Mail} component={Mail} />
						<ProtectedRoute exact path={RouterPaths.Users} component={Users} />
						<ProtectedRoute exact path={RouterPaths.Franchises} component={Franchises} />
						<ProtectedRoute exact path={RouterPaths.Redirects} component={Redirects} />
						<ProtectedRoute exact path={RouterPaths.Countries} component={Countries} />
						<ProtectedRoute exact path={RouterPaths.ClientLogos} component={ClientLogos} />
					</Switch>
				</RouterWrapper>
			</UserInfoProvider>
		</BrowserRouter>
	);
};
