import apiInstance from "api/api-instance";
import headers from "api/util/headers";

const PaymentAPI = {
	getPaymentModels: (data) => apiInstance.get("/api/payment/getPaymentModels", data, headers()),
	deletePaymentModel: (data) => apiInstance.delete("/api/payment/deletePaymentModel", data, headers()),
	updatePaymentModel: (data) => apiInstance.post("/api/payment/updatePaymentModel", data, headers()),
	createPaymentModel: (data) => apiInstance.post("/api/payment/createPaymentModel", data, headers()),
};

export default PaymentAPI;
