import { Grid, Box } from "@mui/material";
import { RouterPaths } from "config/constants";
import api from "api/api";
import useQuery from "view/subcomponents/hooks/use-query";

import { LinkButton } from "view/subcomponents/buttons/link-button";
import { KycAutocomplete } from "view/subcomponents/input/autocomplete/kyc-autocomplete";

interface Props {
	sx?: any;
}

export const ErrandCardFranchise = ({ sx }: Props) => {
	const { data } = useQuery(api.getFranchises);

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				...sx,
			}}
		>
			<Grid item xs={6}>
				<KycAutocomplete name="franchise" options={data?.map((f) => ({ label: f.name, value: f.franchiseId })) ?? []} />
			</Grid>
			<Grid item xs={6}>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<LinkButton
						to={RouterPaths.Franchises}
						variant={"white-shadow"}
						sx={{
							color: "primary.main",
						}}
						type={"button"}
					>
						Redigera varumärken
					</LinkButton>
				</Box>
			</Grid>
		</Grid>
	);
};
