import React, { memo } from "react";
import { Box } from "@mui/material";
import SwitchTrackText from "view/subcomponents/input/switch/switch-track-text";
import SwitchLabel from "view/subcomponents/input/switch/switch-label";
import SwitchThumb from "view/subcomponents/input/switch/switch-thumb";

interface Props {
	label: string;
	rightSide?: boolean;
	checked: boolean;
	onChange: (checked: boolean) => void;
	sx?: any;
	disabled?: boolean;
	icon?: any;
	dataTestId?: string;
	yesValue?: string;
	noValue?: string;
	transformation?: (value) => string;
}

export const Switch = memo(
	({ label, rightSide, checked, onChange, sx, disabled, icon, dataTestId, noValue, yesValue, transformation }: Props) => {
		const onClick = () => {
			if (!disabled) {
				onChange(!checked);
			}
		};

		return (
			<Box
				sx={{
					userSelect: "none",
					display: "flex",
					alignItems: rightSide ? "center" : "flex-start",
					flexDirection: rightSide ? "row" : "column-reverse",
					cursor: disabled ? "no-drop" : "pointer",
				}}
			>
				<Box
					data-test-id={dataTestId}
					onClick={onClick}
					sx={{
						width: "120px",
						minWidth: "120px",
						height: "45px",
						borderRadius: "20px",
						backgroundColor: checked ? "#8854d0" : "#000",
						display: "flex",
						alignItems: "center",
						padding: "4px",
						position: "relative",
						cursor: disabled ? "no-drop" : "pointer",
						transition: "all 0.1s ease-in-out",
						...sx,
					}}
				>
					<SwitchThumb value={checked} />
					<SwitchTrackText value={checked} noValue={noValue} yesValue={yesValue} transformation={transformation} />
				</Box>
				<SwitchLabel rightSide={rightSide} onClick={onClick}>
					{label}
				</SwitchLabel>
				{icon && icon}
			</Box>
		);
	}
);
