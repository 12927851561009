import { TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { MessagePanel } from 'view/subcomponents/common/message-panel/message-panel';

export const MailHistory = () => {
	const [t] = useTranslation();
	return (
		<TabPanel value={'2'}>
			<MessagePanel
				type={'info'}
				title={'information'}
				sx={{
					textAlign: 'left',

					color: 'primary.main',
					fontWeight: 'normal',
				}}
			>
				{t('Locations.mail.historyDescription')}
			</MessagePanel>
		</TabPanel>
	);
};
