import React from "react";
import { Box } from "@mui/material";
import { TextContainer } from "view/subcomponents/common/text-container";
import { Children } from "types/children";

interface Props {
	title: string;
	children: Children;
	sx?: any;
	innerSx?: any;
}

const RightContainerCardItem = ({ title, children, sx, innerSx }: Props) => {
	return (
		<Box
			sx={{
				...sx,
				display: "flex",
			}}
		>
			<Box
				sx={{
					paddingRight: "4px",
					fontWeight: "500",
				}}
			>
				{title && `${title}:`}
			</Box>
			<TextContainer
				sx={{
					paddingRight: "24px",
					...innerSx,
				}}
			>
				{children}
			</TextContainer>
		</Box>
	);
};

export default RightContainerCardItem;
