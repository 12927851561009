import React from "react";
import { Box, Button } from "@mui/material";
import RightContainerCard from "view/subcomponents/card/right-container-card/right-container-card";
import { TextContainer } from "view/subcomponents/common/text-container";
import parse from "html-react-parser";
import { InternalPendingButton } from "view/subcomponents/buttons/internal-pending-button";
import api from "api/api";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { SnackbarTypes } from "types/snackbar";

const NotificationCard = ({ notification, index, setChangeData, updateByIndex }) => {
	const { title, status, id } = notification;
	const { enqueue } = useSnackbar();

	const disableNotification = async () => {
		let newStatus = status === "Active" ? "Inactive" : "Active";

		await api
			.updateNotificationStatus({
				id: id,
				status: newStatus,
			})
			.then((res) => {
				if (res.ok) {
					enqueue(SnackbarTypes.Success, "Notification.Disable.Success");

					updateByIndex(index, {
						...notification,
						index: index,
						status: newStatus,
					});
				}
			});
	};

	return (
		<RightContainerCard
			sx={{
				display: "flex",
			}}
		>
			<Box flex={"1 1 0"}>
				<TextContainer
					fontSize={18}
					fontWeight={"bold"}
					sx={{
						borderBottom: "1px solid",
						borderColor: "divider",
					}}
				>
					{title}
				</TextContainer>
				<TextContainer
					sx={{
						"& p": {
							margin: 0,
							padding: 0,
						},
					}}
				>
					{parse(notification.message)}
				</TextContainer>
			</Box>
			<Box
				sx={{
					paddingLeft: "16px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					borderLeft: "1px solid",
					borderColor: "divider",
				}}
			>
				<Button
					variant={"purple"}
					sx={{
						marginBottom: "16px",
					}}
					onClick={() => setChangeData(notification)}
				>
					Ändra
				</Button>
				<InternalPendingButton
					onClick={disableNotification}
					variant={"purple"}
					sx={{
						backgroundColor: status === "Active" ? "error.main" : "primary.green",
					}}
				>
					{status === "Active" ? "Avaktivera" : "Aktivera"}
				</InternalPendingButton>
			</Box>
		</RightContainerCard>
	);
};

export default NotificationCard;
