import React from "react";
import { ClickAwayListener, Grow, Popper } from "@mui/material";

interface Props {
	anchorRef: any;
	setOpen: (open: boolean) => void;
	open: boolean;
	placement?: any;
	children: any;
}

export const KycMenu = ({ anchorRef, setOpen, open, placement, children }: Props) => {
	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open, anchorRef]);

	return (
		<div style={{ zIndex: 9999 }}>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				placement={placement ?? "bottom"}
				style={{
					zIndex: 9000,
				}}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<div>
							<ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
						</div>
					</Grow>
				)}
			</Popper>
		</div>
	);
};
