import { Box, CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetClientLogos } from 'store/client-logo/use-get-client-logos';
import { useGetNewClientLogoErrands } from 'store/client-logo/use-get-new-client-logo-errands';
import { ClientLogosLeftContainer } from 'view/components/clientLogos/clientLogos-left-container';
import { ClientLogosRightErrandContainer } from 'view/components/clientLogos/clientLogos-right-errand-container';
import { ClientLogosRightLogoContainer } from 'view/components/clientLogos/clientLogos-right-logo-container';

export enum MenuOptions {
	Errands = 'Förfrågningar',
	Active = 'Aktiva',
}

export const ClientLogos = () => {
	const [option, setOption] = useState(MenuOptions.Errands);
	const { t } = useTranslation();

	const getClientLogos = useGetClientLogos();
	const getNewClientLogoErrands = useGetNewClientLogoErrands();

	if (getClientLogos.isLoading || getNewClientLogoErrands.isLoading) {
		return <CircularProgress />;
	}

	if (
		getClientLogos.error ||
		getNewClientLogoErrands.error ||
		!getClientLogos.data.ok ||
		!getNewClientLogoErrands.data.ok
	) {
		return (
			<Box display={'flex'} flexDirection={'column'} sx={{ display: 'flex', textAlign: 'center' }}>
				<Box
					sx={{
						position: 'relative',

						backgroundColor: '#fff',
						borderRadius: '24px',
						color: 'primary.error.main',
						padding: '24px',
						fontWeight: 'bold',
						fontSize: '20px',
					}}
				>
					{t('Locations.clientLogos.error')}
				</Box>
			</Box>
		);
	}

	const activeClientLogos = getClientLogos.data.data;
	const clientLogoErrands = getNewClientLogoErrands.data.data;

	return (
		<Box sx={{ display: 'flex', height: '100%', width: '100%', paddingBottom: '16px' }}>
			<Grid
				container
				sx={{
					width: '100%',
				}}
			>
				<ClientLogosLeftContainer
					setVisibleClientLogos={setOption}
					nrActive={activeClientLogos.length}
					nrRequests={clientLogoErrands.length}
				/>
				{option === MenuOptions.Active && <ClientLogosRightLogoContainer activeClientLogos={activeClientLogos} />}
				{option === MenuOptions.Errands && <ClientLogosRightErrandContainer clientLogoErrands={clientLogoErrands} />}
			</Grid>
		</Box>
	);
};
