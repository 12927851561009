import { createContext, memo, useContext } from "react";
import useQuery, { IQueryProvider } from "view/subcomponents/hooks/use-query";
import { Children } from "types/children";
import { ExternalOptions } from "types/external-options";

const Context = createContext<IQueryProvider>(null);

export const useQueryContext = () => useContext(Context) as IQueryProvider;
export type QueryMethod = (a: any) => any;

interface Props {
	emptyKey?: string;
	disabled?: boolean;
	method: QueryMethod;
	externalOptions?: ExternalOptions;
	children: Children;
	validation?: (any) => boolean;
}

export const QueryProvider = memo(({ emptyKey, disabled, method, externalOptions, children, validation }: Props) => {
	const methods = useQuery(method, externalOptions, disabled, emptyKey, validation);

	return (
		<Context.Provider
			value={{
				...methods,
			}}
		>
			{children}
		</Context.Provider>
	);
});
