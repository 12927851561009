import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Dialog, DialogContent, DialogTitle, InputBase } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { InternalPendingButton } from 'view/subcomponents/buttons/internal-pending-button';
import api from 'api/api';
import { ApiResponse } from 'apisauce';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { SnackbarTypes } from 'types/snackbar';

const defaultTransactionData = {
	fileContent: [],
	fileName: '',
};

export const ClientDialogGetMonthlyCSM = ({ open, onClose }) => {
	const { enqueue } = useSnackbar();
	const csvRef = useRef(null);
	const { data } = useQueryContext();
	const [transactionData, setTransactionData] = useState(defaultTransactionData);
	const [date, setDate] = useState(new Date().toISOString());
	const [datePickerOpen, setDatePickerOpen] = useState(false);

	const onChangeDate = date => {
		let firstDay = new Date(date);
		firstDay.setMonth(firstDay.getMonth() + 1);
		firstDay.setDate(0);
		setDate(firstDay.toISOString());
	};

	const onGetCSVClick = async () => {
		await api
			.createClientCSVMonthInfo({ clients: data?.map(data => data?.clientId), date })
			.then((res: ApiResponse<any>) => {
				if (res.ok) {
					setTransactionData(res.data);
					csvRef.current.link.click();
				} else {
					enqueue(SnackbarTypes.Error, 'CSV.Error');

					setTransactionData(defaultTransactionData);
				}
			});
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: '16px',
					padding: '8px',
					boxSizing: 'border-box',
					maxWidth: '700px',
				},
			}}
		>
			<DialogTitle
				sx={{
					color: 'primary.main',
					textAlign: 'center',
					fontWeight: 'bold',
				}}
			>
				Välj månad för CSM-filen
			</DialogTitle>
			<DialogContent>
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DesktopDatePicker
							open={datePickerOpen}
							onOpen={() => setDatePickerOpen(true)}
							onClose={() => setDatePickerOpen(false)}
							value={date}
							views={['year', 'month']}
							minDate={new Date('2021-12-01')}
							maxDate={new Date().toISOString()}
							openTo={'month'}
							inputFormat={'YYYY-MM'}
							onChange={onChangeDate}
							renderInput={({ inputRef, inputProps: { value } }) => {
								return (
									<InputBase
										ref={inputRef}
										sx={{
											padding: '16px',
											fontWeight: 'bold',
											borderRadius: 2,
											mt: 1,
											border: '1px solid rgba(0, 0, 0, 0.12)',
										}}
										value={value}
										type="text"
										placeholder={'Datum'}
										unselectable="on"
										endAdornment={
											<CalendarMonthIcon
												sx={{
													width: '32px',
													height: '32px',
													cursor: 'pointer',
												}}
												onClick={() => setDatePickerOpen(true)}
											/>
										}
									/>
								);
							}}
						/>
					</LocalizationProvider>
					<InternalPendingButton sx={{ marginTop: '8px' }} variant={'purple'} onClick={onGetCSVClick}>
						Hämta CSM
					</InternalPendingButton>
					<CSVLink
						data={transactionData?.fileContent ?? []}
						filename={transactionData?.fileName ?? ''}
						className="hidden"
						ref={csvRef}
						target="_blank"
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
