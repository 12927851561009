import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DashboardColumnItem from 'view/components/dashboard/subcomponents/dashboard-column-item';

const DashboardColumn = ({ title, data }) => {
	const { t } = useTranslation();

	const { kyc, errands, customers } = data;

	return (
		<Grid
			item
			xs={12}
			md={6}
			lg={3}
			sx={{
				textAlign: 'center',
				paddingBottom: '16px',
			}}
		>
			<Box
				sx={{
					fontWeight: 'bold',
					fontSize: '20px',
					paddingBottom: '8px',
				}}
			>
				{t(`Dashboard.Title.${title}`)}
			</Box>
			<Divider
				sx={{
					marginRight: '16px',
					marginLeft: '16px',
				}}
			/>
			<Box
				sx={{
					textAlign: 'left',
					marginRight: '16px',
					marginLeft: '16px',
					paddingTop: '8px',
				}}
			>
				<DashboardColumnItem title={'Kyc'} data={kyc} />
				<DashboardColumnItem title={'Errands'} data={errands} />
				<DashboardColumnItem title={'Customers'} data={customers} />
			</Box>
		</Grid>
	);
};

export default DashboardColumn;
