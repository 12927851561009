import { Box, Collapse, Paper } from '@mui/material';
import { useState } from 'react';
import { ClientLogoInfo } from 'store/client-logo/use-get-client-logos';
import { formatOrgNumber } from 'utils/string';
import { ShowMoreButton } from 'view/subcomponents/buttons/show-more-button';
import { ClientLogoCardExpanded } from 'view/components/clientLogos/ClientLogoCardExpanded';

interface ClientLogoCardProps {
	clientLogoInfo: ClientLogoInfo;
}

export const ClientLogoCard = ({ clientLogoInfo }: ClientLogoCardProps) => {
	const [open, setOpen] = useState(false);

	return (
		<Paper sx={{ padding: '16px', marginBottom: '16px', borderRadius: '24px', width: '440px' }}>
			<Box display={'flex'} flexDirection={'column'} sx={{ paddingBottom: '12px' }}>
				<Box
					sx={{
						fontWeight: 'bold',
						fontSize: '20px',
						lineHeight: '1.8',
					}}
				>
					{clientLogoInfo.companyName}
				</Box>
				<Box>{formatOrgNumber(clientLogoInfo.companyOrg)}</Box>
			</Box>

			<Collapse in={open} unmountOnExit>
				{open && <ClientLogoCardExpanded clientId={clientLogoInfo.clientId} />}
			</Collapse>
			<Box
				sx={{
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					padding: '24px',
				}}
			>
				<ShowMoreButton
					open={open}
					setOpen={() => setOpen(!open)}
					sx={{
						position: 'absolute',
						color: 'primary.main',
						bottom: '-16px',
						left: 'calc(50% - 25px)',
					}}
				/>
			</Box>
		</Paper>
	);
};
