import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { Form } from 'view/subcomponents/form';
import { KycAutocomplete } from 'view/subcomponents/input/autocomplete/kyc-autocomplete';
import { useTranslation } from 'react-i18next';
import { BusinessAreas } from 'config/constants/business-areas';
import { PaymentMethods, PaymentPeriods } from 'config/constants/common';
import api from 'api/api';
import AgreementCardFooter from 'view/components/agreements/subcomponents/agreement-card-footer';
import { agreementInformationSchema } from 'utils/validation/schema/agreement-information-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetUpdateAgreement } from 'store/agreement/use-get-agreement';
import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { SnackbarTypes } from 'types/snackbar';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'store/query-keys';
import { KycFormInput } from 'view/subcomponents/input/inputs/kyc-form-input';

interface AgreementCardInformationProps {
	agreementId: string;
}

const AgreementCardInformation = ({ agreementId }: AgreementCardInformationProps) => {
	const { t } = useTranslation();
	const { enqueue } = useSnackbar();
	const [pending, setPending] = useState(false);

	const queryClient = useQueryClient();
	const { setValue, ...methods } = useForm({
		defaultValues: {
			paymentPeriod: null,
			businessArea: null,
			paymentMethod: null,
			formType: '',
		},
		resolver: yupResolver(agreementInformationSchema),
	});
	const getAgreement = useGetUpdateAgreement(agreementId);

	const onSubmit = async values => {
		setPending(true);
		const formatted = {
			businessArea: values?.businessArea?.value,
			paymentMethod: values?.paymentMethod.value,
			paymentPeriod: values?.paymentPeriod.value,
			formType: values?.formType.value,
		};

		await api.updateAgreement({ id: agreementId, data: formatted }).then(res => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, 'Agreement.Update.Success');
				queryClient.invalidateQueries({ queryKey: [queryKeys.getAgreement] });
			} else {
				enqueue(SnackbarTypes.Error, 'Agreement.Update.Error');
			}
			setPending(false);
		});
	};

	useEffect(() => {
		if (getAgreement.query.isLoading || getAgreement.query.isError) {
			return;
		}
		if (getAgreement.query.isSuccess) {
			const { paymentPeriod, paymentMethod, client, formType } = getAgreement.agreement;
			setValue('formType', t(`formTypes.${formType}`) ?? '');
			setValue('paymentPeriod', {
				value: paymentPeriod,
				label: t(`paymentPeriods.${paymentPeriod}`),
			});
			setValue('businessArea', {
				value: client?.businessArea,

				label: t(`businessAreas.${client?.businessArea}`),
			});
			setValue('paymentMethod', {
				value: paymentMethod,
				label: t(`paymentMethods.${paymentMethod}`),
			});
		}
	}, [getAgreement, setValue, t]);

	return (
		<TabPanel value={'1'}>
			<Box
				sx={{
					fontSize: '20px',
				}}
			>
				Redigera avtalsinformation i formuläret nedan
			</Box>
			<Form methods={{ ...methods, setValue }} onSubmit={onSubmit}>
				<Grid container>
					<Grid item xs={6}>
						<KycFormInput name={'formType'} placeholder={'Formulärtyp'} disabled={true} />
					</Grid>
					<Grid item xs={6}>
						<KycAutocomplete
							name={'businessArea'}
							options={Object.values(BusinessAreas).map(value => ({
								value: value,
								label: t(`businessAreas.${value}`),
							}))}
							placeholder={'Verksamhetsområde'}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycAutocomplete
							name={'paymentPeriod'}
							options={Object.values(PaymentPeriods).map(value => ({
								value: value,
								label: t(`paymentPeriods.${value}`),
							}))}
							placeholder={'Betalningsperiod'}
						/>
					</Grid>
					<Grid item xs={6}>
						<KycAutocomplete
							name={'paymentMethod'}
							options={Object.values(PaymentMethods).map(method => ({
								value: method,
								label: t(`PaymentMethods.${method}`),
							}))}
							placeholder={'Betalningsmetod'}
						/>
					</Grid>
					<AgreementCardFooter pending={pending} />
				</Grid>
			</Form>
		</TabPanel>
	);
};

export default AgreementCardInformation;
