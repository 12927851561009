import { Box } from "@mui/material";
import React from "react";

const NewClientSummaryRow = ({ content, title }) => {
	return (
		<Box display={"flex"} width={"75%"} pt={"8px"} justifyContent={"center"} borderBottom={"1px solid"} borderColor={"divider"}>
			<Box fontWeight={"500"}>{title}</Box>
			<Box display={"flex"} flex={"1 1 0"} justifyContent={"flex-end"}>
				{typeof content === "boolean" ? <>{content ? "Ja" : "Nej"}</> : content.length === 0 ? "-" : content}
			</Box>
		</Box>
	);
};

export default NewClientSummaryRow;
