import PageTitle from 'view/subcomponents/common/page-title';
import { useState } from 'react';
import { Box, Collapse, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MessagePanel } from 'view/subcomponents/common/message-panel/message-panel';
import { InternalPendingButton } from 'view/subcomponents/buttons/internal-pending-button';
import parse from 'html-react-parser';
import { Children } from 'types/children';
import { ShowMoreButton } from 'view/subcomponents/buttons/show-more-button';

interface Props {
	onClick?: any;
	onSecondClick?: any;
	onThirdClick?: any;
	children?: Children;
	buttonProps?: any;
	secondButtonText?: string;
	thirdButtonText?: string;
}

const RightContainerInfo = ({
	onClick,
	onSecondClick,
	onThirdClick,
	children,
	buttonProps,
	secondButtonText,
	thirdButtonText,
}: Props) => {
	const [open, setOpen] = useState(true);
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const { buttonText, ...rest } = buttonProps ?? {};

	return (
		<Box
			sx={{
				backgroundColor: '#fff',
				borderRadius: '24px',
				width: '100%',
				position: 'relative',
				padding: '32px',
				boxShadow: '0 4px 18px 0 rgba(32, 73, 136, 0.2)',
				marginBottom: '16px',
			}}
		>
			<Collapse in={open} unmountOnExit>
				<Box>
					<PageTitle
						sx={{
							paddingBottom: '16px',
						}}
					>
						Information
					</PageTitle>
					<Divider
						sx={{
							marginBottom: '16px',
						}}
					/>
					<MessagePanel type={'info'}>{parse(t(`Locations.${pathname.replace('/', '')}.description`))}</MessagePanel>
					{onClick && (
						<Box display={'flex'} justifyContent={'flex-end'} paddingTop={'16px'}>
							<InternalPendingButton onClick={onClick} variant={'purple'} {...rest}>
								{buttonText ? buttonText : t(`Locations.${pathname.replace('/', '')}.button`)}
							</InternalPendingButton>
						</Box>
					)}

					{onSecondClick && (
						<Box display={'flex'} justifyContent={'flex-end'} paddingTop={'16px'}>
							<InternalPendingButton onClick={onSecondClick} variant={'purple'}>
								{secondButtonText ? secondButtonText : t(`Locations.${pathname.replace('/', '')}.button`)}
							</InternalPendingButton>
						</Box>
					)}

					{onThirdClick && (
						<Box display={'flex'} justifyContent={'flex-end'} paddingTop={'16px'}>
							<InternalPendingButton onClick={onThirdClick} variant={'purple'}>
								{thirdButtonText ? thirdButtonText : t(`Locations.${pathname.replace('/', '')}.button`)}
							</InternalPendingButton>
						</Box>
					)}
					{children}
				</Box>
			</Collapse>
			<ShowMoreButton
				open={open}
				setOpen={setOpen}
				sx={{
					position: 'absolute',
					color: 'primary.main',
					bottom: '-8px',
					left: 'calc(50% - 25px)',
				}}
			/>
		</Box>
	);
};

export default RightContainerInfo;
