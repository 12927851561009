import { use } from "i18next";
import { initReactI18next } from "react-i18next";
import svSE from "locale/translations/sv-SE.json";
import enUS from "locale/translations/en-US.json";
import { isLocalHost } from "utils/url";

export const defaultLocale = "svSE";

export const resources = {
	svSE: { translation: svSE },
	enUS: { translation: enUS },
};

const i18n = use(initReactI18next).init({
	resources,
	lng: defaultLocale,
	interpolation: {
		escapeValue: false,
	},
	parseMissingKeyHandler(key) {
		if (isLocalHost() && !key.includes("choices")) {
			// eslint-disable-next-line no-console
			console.info("Key " + key + " is missing");
		}

		if (key.includes(".")) {
			const split = key.split(".");

			return split[split.length - 1];
		} else {
			return key;
		}
	},
});

export default i18n;
