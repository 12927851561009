import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete } from 'view/subcomponents/input/autocomplete/autocomplete';
import { KycInput } from 'view/subcomponents/input/inputs/kyc-input/kyc-input';
import { AutocompleteType } from 'types/autocomplete-type';
import { AutocompleteThemes } from 'view/subcomponents/input/autocomplete/autocomplete-paper';

interface Props {
	name: string;
	options: AutocompleteType[];
	disableClearable?: boolean;
	clearOnSelect?: boolean;
	externalOnChange?: any;
	onInit?: any;
	sx?: any;
	ignoreFormType?: boolean;
	customFormType?: any;
	multiple?: boolean;
	disabled?: boolean;
	placeholder?: string;
}

export const KycAutocomplete = ({ name, ...rest }: Props) => {
	const { getValues } = useFormContext();

	return (
		<Controller
			name={name}
			defaultValue={getValues(name) ?? null}
			render={({ field, fieldState, formState }) => {
				const { onChange, value, ref } = field;
				const { invalid } = fieldState;
				const { isSubmitted } = formState;

				return (
					<Autocomplete
						theme={AutocompleteThemes.White}
						inputRef={ref}
						setValue={onChange}
						value={value}
						inputBase={KycInput}
						errorState={{
							isSubmitted: isSubmitted,
							invalid: invalid || false,
							name,
						}}
						{...rest}
					/>
				);
			}}
		/>
	);
};
