import React from "react";
import RightContainer from "view/subcomponents/containers/right-container/right-container";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import CardRegister from "view/components/errands/card-register";

const ErrandsRightContainer = () => {
	const { data: registerErrands } = useQueryContext();

	return (
		<RightContainer displayTopInfo={true}>
			{registerErrands !== null && registerErrands?.map((d, i) => <CardRegister index={i} registerErrand={d} key={`${d.id}-${i}`} />)}
		</RightContainer>
	);
};

export default ErrandsRightContainer;
