import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AgreementCardInformation from 'view/components/agreements/subcomponents/agreement-card-information';
import AgreementCardContactPerson from 'view/components/agreements/subcomponents/agreement-card-contact-person';
import AgreementCardInvoice from 'view/components/agreements/subcomponents/agreement-card-invoice';

import AgreementCardPaymentModel from './agreeement-card-payment-model';

const AgreementCardPanelTwo = ({ agreementId, clientId }) => {
	const [tabValue, setTabValue] = useState('1');

	return (
		<TabPanel
			value={'2'}
			sx={{
				padding: '24px 0 0 0',
			}}
		>
			<TabContext value={tabValue}>
				<Box
					sx={{
						flexGrow: 1,
						display: 'flex',
						'.MuiTabPanel-root': {
							padding: '0',
						},
						form: {
							paddingTop: '32px',
							paddingBottom: '32px',
							width: '85%',
						},
						'.MuiGrid-root:nth-of-type(even)': {
							paddingLeft: '8px',
							paddingBottom: '8px',
						},
						'.MuiGrid-root:nth-of-type(odd)': {
							paddingRight: '8px',
							paddingBottom: '8px',
						},
					}}
				>
					<TabList
						orientation="vertical"
						onChange={(_, value) => setTabValue(value)}
						sx={{
							'.MuiTab-root': {
								textTransform: 'none',
								borderRight: '1px solid',
								borderColor: 'divider',
								textAlign: 'left',
								fontWeight: 'bold',
							},
							borderRight: 1,
							borderColor: 'divider',
							minWidth: '150px',
							marginRight: '24px',
						}}
					>
						<Tab label={'Avtalsinformation'} value={'1'} />
						<Tab label={'Kontaktperson'} value={'2'} />
						<Tab label={'Fakturauppgifter'} value={'3'} />
						<Tab label={'Betalningsmodell'} value={'4'} />
					</TabList>

					<AgreementCardInformation agreementId={agreementId} />
					<AgreementCardContactPerson agreementId={agreementId} />
					<AgreementCardInvoice agreementId={agreementId} />
					<AgreementCardPaymentModel
						clientId={clientId}
						agreementId={agreementId}
					/>
				</Box>
			</TabContext>
		</TabPanel>
	);
};

export default AgreementCardPanelTwo;
