import React from "react";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import InvoicesLeftContainer from "view/components/invoices/invoices-left-container";
import api from "api/api";
import InvoicesRightContainer from "view/components/invoices/invoices-right-container";

const Invoices = () => {
	return (
		<SectionWrapper method={api.getInvoices}>
			<InvoicesLeftContainer />
			<InvoicesRightContainer />
		</SectionWrapper>
	);
};

export default Invoices;
