import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import {
	AutocompletePaper,
	AutocompleteThemes,
} from 'view/subcomponents/input/autocomplete/autocomplete-paper';
import AutocompletePopupIcon from 'view/subcomponents/input/autocomplete/autocomplete-popup-icon';
import AutocompleteClearIcon from 'view/subcomponents/input/autocomplete/autocomplete-clear-icon';
import AutocompleteListItem from 'view/subcomponents/input/autocomplete/autocomplete-list-item';
import { isNullOrUndefined } from 'utils/common';
import { AutocompleteType } from 'types/autocomplete-type';
import { v4 as uuidv4 } from 'uuid';

interface Props {
	disabled?: boolean;
	multiple?: boolean;
	inputBase: any;
	value?: AutocompleteType | null;
	options: AutocompleteType[];
	setValue?: (any) => any;
	inputRef?: any;
	theme: AutocompleteThemes;
	sx?: any;
	inputSx?: any;
	onInit?: any;
	externalOnChange?: any;
	dataTestId?: string;
	clearOnSelect?: boolean;
	forcePopupIcon?: boolean;
	blurOnSelect?: any;
	blurOnReset?: boolean;
	disableClearable?: boolean;
	autoCompleteProps?: any;
	externalOnReset?: any;
	iconProps?: any;
	[x: string]: any;
}

export const Autocomplete = ({
	disabled,
	clearOnSelect,
	forcePopupIcon,
	blurOnSelect,
	blurOnReset,
	sx,
	inputSx,
	setValue,
	value,
	options,
	externalOnChange,
	theme,
	inputBase: InputBase,
	dataTestId,
	multiple,
	iconProps,
	disableClearable,
	autoCompleteProps,
	onInit,
	externalOnReset,
	...rest
}: Props) => {
	const autoCompleteRef = useRef(null);
	const onInitRef = useRef(false);
	const [key, setKey] = useState<string>('-1');

	useEffect(() => {
		if (!isNullOrUndefined(onInit) && onInitRef.current === false) {
			onInit(value);
			onInitRef.current = true;
		}
	}, [onInit, value]);

	const onChange = (_, data) => {
		if (!disabled) {
			if (data === null) {
				if (setValue) {
					setValue(data);
				}

				if (externalOnReset) {
					externalOnReset();
				}
			} else {
				if (setValue) {
					setValue(data);
				}

				if (externalOnChange) {
					externalOnChange(data);
				}
			}
			if (clearOnSelect) {
				const clearButton = autoCompleteRef?.current?.getElementsByClassName(
					'MuiAutocomplete-clearIndicator',
				)[0];
				if (clearButton) {
					clearButton.click();
				} else {
					setKey(uuidv4());
				}
			}
		}
	};

	const getValue = () => {
		if (multiple && isNullOrUndefined(value)) {
			return [];
		} else {
			return value;
		}
	};

	const autocompleteValue = getValue();

	return (
		<MuiAutocomplete
			key={key}
			ref={autoCompleteRef}
			multiple={multiple}
			freeSolo
			data-test-id={dataTestId}
			fullWidth={true}
			forcePopupIcon={forcePopupIcon ?? true}
			onChange={onChange}
			value={autocompleteValue}
			disableClearable={disableClearable ?? false}
			clearIcon={<AutocompleteClearIcon />}
			renderOption={AutocompleteListItem}
			popupIcon={<AutocompletePopupIcon {...iconProps} />}
			PaperComponent={props => <AutocompletePaper {...props} theme={theme} />}
			renderInput={({ InputProps, inputProps, disabled }) => {
				const { className, ...restInputProps } = inputProps;

				return (
					<InputBase
						inputProps={{
							...restInputProps,
							'data-test-id': `${dataTestId}-input`,
						}}
						InputProps={InputProps}
						disabled={disabled}
						multiple={multiple}
						{...rest}
					/>
				);
			}}
			options={options}
			sx={{
				'.MuiAutocomplete-tag': {
					margin: '8px 2px 8px 8px',
					color: 'primary.main',
					svg: {
						color: 'primary.main',
					},
				},
				'.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
					padding: multiple ? '6px' : 0,
				},

				color: 'primary.main',
				'.MuiSvgIcon-root': {
					color: 'primary.main',
				},

				...sx,
				...inputSx,
			}}
			{...autoCompleteProps}
		/>
	);
};

Autocomplete.defaultProps = {
	blurOnSelect: true,
};
