import React from "react";
import { Box, Divider } from "@mui/material";
import RightContainerCard from "view/subcomponents/card/right-container-card/right-container-card";
import PageTitle from "view/subcomponents/common/page-title";
import NewClientBreadcrumb from "view/components/clients/subcomponents/new-client/new-client-breadcrumb";
import NewClientCompanyInfo from "view/components/clients/subcomponents/new-client/new-client-company-info";
import NewClientPersonInfo from "view/components/clients/subcomponents/new-client/new-client-person-info";
import NewClientInvoiceInfo from "view/components/clients/subcomponents/new-client/new-client-invoice-info";
import { useNewClientContext } from "view/subcomponents/providers/new-client-provider";
import NewClientSummary from "view/components/clients/subcomponents/new-client/new-client-summary";
import api from "api/api";
import { InternalPendingButton } from "view/subcomponents/buttons/internal-pending-button";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import { useQueryContext } from "view/subcomponents/providers/query-provider";
import NewClientOtherInfo from "view/components/clients/subcomponents/new-client/new-client-other-info";
import { SnackbarTypes } from "types/snackbar";
import { ApiResponse } from "apisauce";

const NewClient = ({ setPage }) => {
	const { isLastPage, setDisabled, getWizardData, page, moveBack } = useNewClientContext();
	const { enqueue } = useSnackbar();
	const { refetch } = useQueryContext();

	const onSubmit = async () => {
		if (isLastPage) {
			setDisabled(true);
			let values = getWizardData();
			const formatted = {
				...values,
				company: {
					...values.company,
					businessArea: values?.company?.businessArea?.value,
				},
				invoice: {
					...values.invoice,
					paymentMethod: values?.invoice?.paymentMethod?.value,
					paymentPeriod: values?.invoice?.paymentPeriod?.value,
				},
				person: {
					...values.person,
					fullName: `${values.person.firstName} ${values.person.lastName}`,
				},
				other: {
					implementationHelp: values?.other?.implementationHelp,
					webinar: values?.other?.webinar,
				},
			};

			await api.createNewClient(formatted).then((res: ApiResponse<any>) => {
				if (res.ok) {
					setPage(1);
					enqueue(SnackbarTypes.Success, "Client.New.SUCCESS");
					refetch();
				} else {
					setDisabled(false);
					enqueue(SnackbarTypes.Error, `Client.New.${res?.data?.code ?? "GENERIC_ERROR"}`);
				}
			});
		}
	};

	return (
		<RightContainerCard
			sx={{
				padding: "32px",
				display: "flex",
				flexDirection: "column",
				height: "100%",
			}}
		>
			<PageTitle
				sx={{
					paddingBottom: "16px",
				}}
			>
				Skapa ny klient
			</PageTitle>
			<Box
				sx={{
					justifyContent: "center",
					display: "flex",
				}}
			>
				<NewClientBreadcrumb index={1}>Företagsinformation</NewClientBreadcrumb>
				<NewClientBreadcrumb index={2}>Fakturauppgifter</NewClientBreadcrumb>
				<NewClientBreadcrumb index={3}>Personuppgifter</NewClientBreadcrumb>
				<NewClientBreadcrumb index={4}>Övrigt</NewClientBreadcrumb>
				<NewClientBreadcrumb index={5}>Sammanfattning</NewClientBreadcrumb>
			</Box>
			<Divider />
			<Box
				sx={{
					marginTop: "8px",
					overflow: "overlay",
					flex: "1 1 0",
					"::-webkit-scrollbar": {
						paddingRight: "16px",
						width: "10px !important",
						background: "rgba(136, 84, 208, 0.2); !important",
						borderRadius: "16px",
					},
					"::-webkit-scrollbar-thumb": {
						background: "#8854d0",
						borderRadius: "8px",
						height: "30px",
					},
				}}
			>
				<Steps />
			</Box>

			<Box mt={3} display={"flex"} width={"100%"}>
				{page > 1 && (
					<InternalPendingButton variant={"purple-outlined"} onClick={moveBack}>
						Tillbaka
					</InternalPendingButton>
				)}

				<Box display={"flex"} flex={"1 1 0"} justifyContent={"flex-end"}>
					<InternalPendingButton variant={"purple"} type="submit" form="new-client-form" onClick={onSubmit}>
						{!isLastPage ? "Nästa" : "Slutför"}
					</InternalPendingButton>
				</Box>
			</Box>
		</RightContainerCard>
	);
};

const Steps = () => {
	const { page } = useNewClientContext();

	switch (page) {
		case 1:
			return <NewClientCompanyInfo />;
		case 2:
			return <NewClientInvoiceInfo />;
		case 3:
			return <NewClientPersonInfo />;
		case 4:
			return <NewClientOtherInfo />;
		case 5:
			return <NewClientSummary />;
		default:
			return null;
	}
};

export default NewClient;
