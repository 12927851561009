import React from "react";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import api from "api/api";
import PaymentsLeftContainer from "view/components/payments/payments-left-container";
import PaymentsRightContainer from "view/components/payments/payments-right-container";

const Payments = () => {
	return (
		<SectionWrapper method={api.getPaymentModels}>
			<PaymentsLeftContainer />
			<PaymentsRightContainer />
		</SectionWrapper>
	);
};

export default Payments;
