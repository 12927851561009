import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useForm, useFormState } from "react-hook-form";
import { KycFormInput } from "view/subcomponents/input/inputs/kyc-form-input";
import React, { useEffect, useState } from "react";
import { Form } from "view/subcomponents/form";
import { useSnackbar } from "view/subcomponents/providers/snackbar-provider";
import api from "api/api";
import PendingButton from "view/subcomponents/buttons/pending-button";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserSchema } from "utils/validation/schema/update-user-schema";
import { SnackbarTypes } from "types/snackbar";

const ChangeUserDialog = ({ changeData, setChangeData, updateByIndex }) => {
	const [pending, setPending] = useState(false);
	const { enqueue } = useSnackbar();

	const { setValue, ...methods } = useForm({
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			identityNumber: "",
		},
		resolver: yupResolver(updateUserSchema),
	});

	useEffect(() => {
		if (changeData != null) {
			setValue("firstName", changeData?.firstName ?? "");
			setValue("lastName", changeData?.lastName ?? "");
			setValue("email", changeData?.email ?? "");
			setValue("identityNumber", changeData?.identityNumber ?? "");
		}
	}, [setValue, changeData]);

	const onClose = () => {
		setChangeData(null);
		setPending(false);
	};

	const onSubmit = async (data) => {
		setPending(true);
		await api.updateAdminUser({ id: changeData.id, ...data }).then((res) => {
			if (res.ok) {
				enqueue(SnackbarTypes.Success, "User.Change.Success");

				updateByIndex(changeData?.index, {
					...changeData,
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					fullName: `${data.firstName} ${data.lastName}`,
					identityNumber: data.identityNumber,
				});
			} else {
				enqueue(SnackbarTypes.Error, "User.Change.Error");
			}
			setPending(false);
		});
	};

	const { isDirty } = useFormState({
		control: methods.control,
	});

	return (
		<Dialog
			open={changeData !== null}
			onClose={onClose}
			fullWidth={true}
			PaperProps={{
				sx: {
					borderRadius: "16px",
					padding: "8px",
					boxSizing: "border-box",
					maxWidth: "650px",
				},
			}}
		>
			<DialogTitle
				sx={{
					color: "primary.main",
					textAlign: "center",
					fontWeight: "bold",
				}}
			>
				Uppdatera administratör
			</DialogTitle>
			<DialogContent>
				<Form
					methods={{ ...methods, setValue }}
					onSubmit={onSubmit}
					sx={{
						marginTop: "16px",
						display: "flex",
						flexDirection: "column",
						gap: "16px",
					}}
				>
					<KycFormInput name={"firstName"} placeholder={"Förnamn"} disabled={pending} />
					<KycFormInput name={"lastName"} placeholder={"Efternamn"} disabled={pending} />
					<KycFormInput name={"email"} placeholder={"E-post"} disabled={pending} />
					<KycFormInput name={"identityNumber"} placeholder={"Identitetsnummer"} disabled={pending} />
					<Box
						sx={{
							paddingTop: "8px",
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<PendingButton pending={pending} variant={"purple"} type={"submit"} disabled={!isDirty}>
							Spara
						</PendingButton>
					</Box>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeUserDialog;
