export const isNullObject = (object) => {
	for (let key in object) {
		if (object.hasOwnProperty(key)) {
			if (object[key] !== null) {
				return false;
			}
		}
	}
	return true;
};

export const isNullOrUndefined = (value) => {
	return value === null || value === undefined;
};
