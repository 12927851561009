import { useQuery } from '@tanstack/react-query';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { ApiResponse } from 'apisauce';
import { apiRoutes } from 'store/api-routes';
import { queryKeys } from 'store/query-keys';

export interface ClientLogoErrand {
	content: ErrandContent;
	clientId: string;
}

interface ErrandContent {
	id: string;
	clientId: string;
	companyName: string;
	companyOrg: string;
	email: string;
	fullName: string;
	name: string;
	logo: string;
	height: number;
	width: number;
	status: string;
	created_at: string;
	updated_at: string;
}

export const useGetNewClientLogoErrands = () => {
	const result = useQuery({
		queryKey: [queryKeys.getNewClientLogoErrands],
		queryFn: async () => {
			const getClientLogos = (): Promise<ApiResponse<ClientLogoErrand[], ClientLogoErrand[]>> =>
				apiInstance.get<ClientLogoErrand[]>(apiRoutes.clientLogo.getNewClientLogoErrands(), undefined, headers());
			const result = await getClientLogos();
			return result;
		},
		refetchOnWindowFocus: false,
	});

	return result;
};
