import Cookies from "js-cookie";

export const setCookie = (key, value, secondsUntilExpire) => {
	const options = {
		path: "/",
		expires: new Date(Date.now() + 1000 * (secondsUntilExpire * 60)),
		secure: true,
		sameSite: "none",
	};
	Cookies.set(key, value, options);
};

export const setTokenResponse = (tokenResponse, cookieName) => {
	let token = tokenResponse.token;
	let expires_in = tokenResponse.expires_in;
	setCookie(cookieName, token, expires_in);
};

export const hasCookie = (key) => {
	const cookie = Cookies.get(key);
	return !(cookie === undefined);
};

export const getCookie = (key) => {
	return Cookies.get(key);
};

export const removeCookie = (key) => {
	const options = {
		path: "/",
		secure: true,
		sameSite: "none",
	};
	Cookies.remove(key, options);
};
