import React, { useState } from "react";
import SectionWrapper from "view/subcomponents/wrappers/section-wrapper";
import NotificationsLeftContainer from "view/components/notifications/notifications-left-container";
import NotificationsRightContainer from "view/components/notifications/notifications-right-container";
import api from "api/api";

const Notifications = () => {
	const [tabValue, setTabValue] = useState("1");

	return (
		<SectionWrapper method={api.getAdminNotifications}>
			<NotificationsLeftContainer tabValue={tabValue} />
			<NotificationsRightContainer tabValue={tabValue} setTabValue={setTabValue} />
		</SectionWrapper>
	);
};

export default Notifications;
