import { KycNegativeIcon, KycSuccessIcon } from "view/subcomponents/icons/icons";
import React from "react";
import { SnackbarType, SnackbarTypes } from "types/snackbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
	variant: SnackbarType;
}

export const SnackbarIcon = ({ variant }: Props) => {
	switch (variant) {
		case SnackbarTypes.Error:
			return (
				<KycNegativeIcon
					sx={{
						width: "30px",
						height: "30px",
						color: `${variant}.main`,
					}}
				/>
			);
		case SnackbarTypes.Success:
			return (
				<KycSuccessIcon
					sx={{
						width: "30px",
						height: "30px",
						color: `${variant}.main`,
					}}
				/>
			);
		case SnackbarTypes.Info:
			return (
				<InfoOutlinedIcon
					sx={{
						width: "30px",
						height: "30px",
						color: `${variant}.main`,
					}}
				/>
			);
		default:
			return (
				<KycSuccessIcon
					sx={{
						width: "30px",
						height: "30px",
					}}
				/>
			);
	}
};
