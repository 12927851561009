import React from 'react';
import { Controller } from 'react-hook-form';
import { KycInput } from 'view/subcomponents/input/inputs/kyc-input/kyc-input';

interface Props {
	name: string;
	hidden?: boolean;
	placeholder?: string;
	[x: string]: any;
	defaultValue?: string;
}

export const KycFormInput = ({ name, hidden, ...rest }: Props) => {
	return (
		<Controller
			defaultValue={''}
			name={name}
			render={({ field, fieldState, formState }) => {
				const { invalid } = fieldState;
				const { onChange, ref, value, onBlur } = field;
				const { isSubmitted } = formState;

				return (
					<KycInput
						onBlur={onBlur}
						onChange={onChange}
						hidden={hidden}
						inputRef={ref}
						errorState={{
							isSubmitted: isSubmitted,
							invalid: invalid || false,
							name,
						}}
						inputProps={{
							value: value,
						}}
						data-is-valid={invalid ? 'false' : 'true'}
						{...rest}
					/>
				);
			}}
		/>
	);
};
