import RightContainerCard from 'view/subcomponents/card/right-container-card/right-container-card';
import { Box, Button, Collapse, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InternalPendingButton } from 'view/subcomponents/buttons/internal-pending-button';

const PaymentsCard = ({ onChange, payment, index, onDelete }) => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();
	const {
		id,
		basePrice,
		includedUsers,
		kycPerClient,
		kycPerUser,
		kycPrice,
		kycPriceCompany,
		kycPricePerson,
		name,
		userPrice,
		addons,
	} = payment;

	return (
		<RightContainerCard
			open={open}
			setOpen={setOpen}
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Box
					display={'flex'}
					alignItems={'center'}
					sx={{
						'*': {
							paddingRight: '8px',
						},
					}}
				>
					<Box
						sx={{
							fontWeight: 'bold',
							fontSize: '24px',
							width: '125px',
						}}
					>
						{name}
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flex: '1 1 0',
						justifyContent: 'flex-end',
					}}
				>
					<InternalPendingButton
						onClick={() => onDelete(id, index)}
						variant={'purple-outlined'}
						sx={{
							marginRight: '16px',
						}}
					>
						Arkivera
					</InternalPendingButton>
					<Button variant={'purple'} onClick={onChange}>
						Ändra
					</Button>
				</Box>
			</Box>
			<Collapse in={open} unmountOnExit>
				<Grid
					container
					sx={{
						marginTop: '8px',
						marginBottom: '8px',
						borderTop: '1px solid',
						borderColor: 'divider',
						paddingBottom: '32px',
					}}
				>
					<Grid
						item
						xs={12}
						sx={{
							fontWeight: 'bold',
							fontSize: '18px',
						}}
					>
						Information
					</Grid>
					<Grid
						item
						xs={6}
						container
						sx={{
							paddingRight: '32px',
						}}
					>
						<Item description={'Baspris'} bolded={`${basePrice} kr`} />
						<Item description={'Pris kundkännedom'} bolded={`${kycPrice} kr`} />
						<Item
							description={'Pris kundkännedom JP'}
							bolded={`${kycPriceCompany} kr`}
						/>
						<Item
							description={'Pris kundkännedom FP'}
							bolded={`${kycPricePerson} kr`}
						/>
						<Item
							description={'Pris per aktiv användare'}
							bolded={`${userPrice} kr`}
						/>

						<Item
							description={'Inkluderade tillägg'}
							bolded={
								<Box>{addons?.map(addon => t(`AddonTypes.${addon}`))}</Box>
							}
						/>
					</Grid>
					<Grid
						item
						xs={6}
						container
						sx={{
							paddingLeft: '32px',
						}}
					>
						<Item
							description={'Inkluderade användare'}
							bolded={includedUsers}
						/>
						<Item
							description={'Antal KYC per företag'}
							bolded={
								!isNaN(kycPerClient)
									? kycPerClient
									: t(`KycPerUser.${kycPerClient}`)
							}
						/>
						<Item
							description={'Antal KYC per användare'}
							bolded={
								!isNaN(kycPerUser) ? kycPerUser : t(`KycPerUser.${kycPerUser}`)
							}
						/>
					</Grid>
				</Grid>
			</Collapse>
		</RightContainerCard>
	);
};

const Item = ({ description, bolded }) => {
	return (
		<Grid item xs={12} container>
			<Box>{description}</Box>
			<Box
				fontWeight={'bold'}
				paddingLeft={'6px'}
				flex={'1 1 0'}
				display={'flex'}
				justifyContent={'flex-end'}
			>
				{bolded}
			</Box>
		</Grid>
	);
};

export default PaymentsCard;
