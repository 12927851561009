export interface ClientStatus {
	title: string;
	status: boolean;
}

export const getClientStatus = (paused: boolean, activeUntil: string = '') => {
	if (!paused) {
		if (activeUntil && activeUntil.length > 0) {
			const today = new Date(Date.now());
			if (new Date(activeUntil) > today) {
				return {
					title: 'Aktiv till ' + activeUntil,
					status: true,
				};
			} else {
				return {
					title: 'Inaktiv',
					status: false,
				};
			}
		}
		return {
			title: 'Aktiv',
			status: true,
		};
	} else {
		return {
			title: 'Pausad',
			status: false,
		};
	}
};
