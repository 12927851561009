import { Checkbox, Grid } from "@mui/material";
import RightContainerCard from "view/subcomponents/card/right-container-card/right-container-card";
import React from "react";

const InvoicesCard = ({ index, invoice, onClientsChange }) => {
	const { name, users, kyces, identityNumber, clientId } = invoice;

	const onChange = (e) => {
		onClientsChange(clientId, e.target.checked);
	};

	return (
		<RightContainerCard
			sx={{
				paddingLeft: "32px",
				paddingRight: "32px",
				display: "flex",
				alignItems: "center",
			}}
		>
			<Grid
				item
				xs={3}
				sx={{
					fontWeight: "bold",
					fontSize: "18px",
				}}
			>
				{name}
			</Grid>
			<Grid item xs={2}>
				{identityNumber}
			</Grid>
			<Grid item xs={3}>
				{kyces}
			</Grid>
			<Grid item xs={3}>
				{users}
			</Grid>
			<Grid item xs={1}>
				<Checkbox onChange={onChange} />
			</Grid>
		</RightContainerCard>
	);
};

export default InvoicesCard;
